import { get } from 'lodash';
import { greekPrefix, adjustByGreekPrefix } from 'core/util/greekPrefixing';
import { timezone, DEFAULT_DATETIME_FORMAT, DEFAULT_DATETIME_FORMAT_WITH_SECONDS } from 'core/util/dateUtils';
import $dictionary from 'app/stores/$dictionary';
import { getToFixed, zeroToText, escapeHtml } from 'app/util/utils';

const ARROW = '\u2192';

function getTimestamp(point, options = {}) {
  const { offset = 0, showSeconds = false } = options;
  const momentFn = point.series.xAxis.options.momentFn || timezone.momentFn;
  const time = point.x - offset;
  return momentFn(time).format(showSeconds ? DEFAULT_DATETIME_FORMAT_WITH_SECONDS : DEFAULT_DATETIME_FORMAT);
}

function getPointData({ point, getColorFromIndex }) {
  const percentage = point.series.options.stacking === 'percent';

  const prefix = point.series.yAxis.prefix || greekPrefix([percentage ? point.y : point.series.yAxis.tickInterval]);
  point.series.yAxis.prefix = prefix;

  let suffix = (point.series.yAxis.options.title.text || '').replace(/(\u2014|\u2011|\u2192)/g, '').trimEnd();
  if (suffix.includes(' % of ')) {
    [suffix] = suffix.split(' % of ');
  }

  const pointValue = zeroToText(adjustByGreekPrefix(point.y, prefix), {
    fix: getToFixed(point.series.yAxis.options.unit)
  });

  const nameFormatter = get(point, 'series.tooltipOptions.seriesNameFormatter', (name) => name.replace(/----/g, ARROW));

  return {
    color: getColorFromIndex(point.series.colorIndex),
    name: nameFormatter(point.series.name, point),
    value: `${escapeHtml(pointValue)} ${suffix.includes('sampling rate') ? ': 1 ' : ''}${prefix}${suffix}`,
    percent: percentage ? `${point.percentage.toFixed(1)}%` : null
  };
}

function renderName(name, { isOverlay = false, length = 0 } = {}) {
  const nameParts = name.split(` ${ARROW} `).slice(0, length > 0 ? length : undefined);
  const namePartWidth = Math.round(500 / nameParts.length);
  const colSpan = isOverlay ? length || 1 : 1;
  const style = `max-width: ${namePartWidth}px; font-weight: ${isOverlay ? 'bold' : 'normal'}`;
  return nameParts.map((part) => `<td class="series-name" style="${style}" colspan="${colSpan}">${part}</td>`).join('');
}

export function sharedTooltipPositioner(labelWidth, labelHeight, point) {
  const chartPos = this.chart.pointer.getChartPosition();
  const mouseX = chartPos.left + this.chart.xAxis[0].pos + point.plotX;
  const mouseY = chartPos.top + this.chart.yAxis[0].pos + point.plotY;

  const windowMargin = 50;

  let x = mouseX;
  let y = mouseY;

  if (mouseX > window.innerWidth - labelWidth - windowMargin) {
    // only flip if the tooltip completely fit on the other side of the mouse
    if (mouseX - labelWidth - 30 > windowMargin) {
      x = mouseX - labelWidth - 30;
    } else {
      x = Math.max(windowMargin, window.innerWidth - labelWidth - windowMargin);
    }
  }

  if (mouseY > window.innerHeight - labelHeight - windowMargin) {
    // only flip if the tooltip completely fit on the other side of the mouse
    if (mouseY - labelHeight - 30 > windowMargin) {
      y = mouseY - labelHeight - 30;
    } else {
      y = Math.max(windowMargin, window.innerHeight - labelHeight - windowMargin);
    }
  }

  return { x, y };
}

export function getSharedTooltip({
  points,
  seriesType = 'line',
  tooltip = '',
  getColorFromIndex,
  showSecondaryPointColor = false,
  showSeconds = false
}) {
  if (!points.length) {
    return tooltip;
  }

  const dimensions = points[0].series.options.model?.collection?.bucket?.firstQuery.get('metric') || [];
  let head = '';

  if (!tooltip) {
    const timestamp = getTimestamp(points[0], { showSeconds });
    tooltip = `<span class="bp4-tag bp4-minimal bp4-round bp4-monospace-text timestamp">${timestamp}</span>`;

    if (dimensions.length > 0 && dimensions[0] !== 'Traffic') {
      head = `
        <thead>
          <tr>
            <th />
            ${dimensions.map((dimension) => `<th>${$dictionary.get(`chartTypesValidations.${dimension}`, dimension)}</th>`).join('')}
          </tr>
        </thead>`;
    }
  }

  const primarySeriesPoints = points.filter(
    (point) => point.series.type === seriesType && point.series.chart.yAxis[0] === point.series.yAxis
  );

  const secondarySeriesPoints = points.filter(
    (point) => point.series.type === seriesType && point.series.chart.yAxis[1] === point.series.yAxis
  );

  const seriesPoints = primarySeriesPoints;

  if (secondarySeriesPoints.length) {
    secondarySeriesPoints.forEach((point) => {
      const matchingPrimarySeries = primarySeriesPoints.find(
        (primaryPoint) => primaryPoint.series.name === point.series.name
      );
      if (matchingPrimarySeries) {
        matchingPrimarySeries.secondary = point;
      } else {
        seriesPoints.push(point);
      }
    });
  }

  const rows = seriesPoints.reduce((acc, point) => {
    const { color, name, value: pointValue, percent } = getPointData({ point, getColorFromIndex });
    const isOverlay = point.series.options.model?.isOverlay;
    let rowMarkup = `
      <tr>
        <td class="series-marker" style="color:${color};">\u25CF</td>
        ${renderName(name, { isOverlay, length: dimensions.length })}
        ${percent ? `<td class="series-value">${percent}</td>` : ''}
        <td class="series-value">${pointValue}</td>`;

    if (point.secondary) {
      const {
        color: secondaryColor,
        name: secondaryName,
        value: secondaryPointValue,
        percent: secondaryPercent
      } = getPointData({
        point: point.secondary,
        getColorFromIndex
      });

      let secondarySeparator =
        seriesPoints.length === 1 ? `</tr><tr><td colspan="${dimensions.length + 1}" />` : '<td>,</td>';

      if (showSecondaryPointColor) {
        secondarySeparator = '';
        rowMarkup += `
          </tr>
          <tr>
            <td class="series-marker" style="color:${secondaryColor};">\u25CF</td>
            ${renderName(secondaryName, { isOverlay, length: dimensions.length })}`;
      }

      rowMarkup += `
        ${secondarySeparator}
        ${secondaryPercent ? `<td class="series-value">${secondaryPercent}</td>` : ''}
        <td class="series-value">${secondaryPointValue}</td>`;
    }

    rowMarkup += '</tr>';

    return `${acc}${rowMarkup}`;
  }, '');

  if (tooltip.includes('</table>')) {
    // append to previous table
    return `${tooltip.replace('</table>', '')}${rows}</table>`;
  }

  return `${tooltip}<table class="tooltip-table">${head}${rows}</table>`;
}

export function getPeriodOverPeriodTooltip({ points, offset, getColorFromIndex, showSeconds }) {
  if (points.length === 0) {
    return '';
  }

  const dimensions = points[0].series.options.model?.collection?.bucket?.firstQuery.get('metric') || [];
  let head = '';

  if (dimensions.length > 0 && dimensions[0] !== 'Traffic') {
    head = `
        <thead>
          <tr>
            <th />
            ${dimensions.map((dimension) => `<th>${$dictionary.get(`chartTypesValidations.${dimension}`, dimension)}</th>`).join('')}
          </tr>
        </thead>`;
  }

  const legendSpace = '\u00A0\u00A0\u00A0';
  const solidLegend = '\u2014';
  const dashLegend = '\u2011\u2011\u2011';

  const currentLabel = `<div class="period-over-period-label">Current${legendSpace}${solidLegend}</div>`;
  const previousLabel = `<div class="period-over-period-label previous">Previous${legendSpace}${dashLegend}</div>`;

  const currentPoints = points.filter((point) => !point.series.options.isPreviousPeriod);
  const previousPoints = points.filter((point) => point.series.options.isPreviousPeriod);

  const currentPoint = currentPoints[0];
  const previousPoint = previousPoints[0];

  let tooltip = '';

  // slightly different layout when in comparing mode
  if (
    currentPoints.length === 1 &&
    previousPoints.length === 1 &&
    currentPoint.series.name === previousPoint.series.name
  ) {
    const isOverlay = currentPoint.series.options.model?.isOverlay;
    const { color, name, value: currentValue } = getPointData({ point: currentPoint, getColorFromIndex });
    const { value: previousValue } = getPointData({ point: previousPoint, getColorFromIndex });

    const currentTimestamp = getTimestamp(currentPoint, { showSeconds });
    const previousTimestamp = getTimestamp(previousPoint, { offset, showSeconds });

    tooltip += `<table class="tooltip-table comparison">
      ${!isOverlay ? head : ''}
      <tr>
        <td class="series-marker" style="color:${color};">\u25CF</td>
        ${renderName(name, { isOverlay, length: dimensions.length })}
      </tr>
    </table>`;

    tooltip += currentLabel;
    tooltip += `<div class="series-row comparison">
      <div class="bp4-tag bp4-minimal bp4-round bp4-monospace-text timestamp">${currentTimestamp}</div>
      <div class="series-value">${currentValue}</div>
    </div>`;

    tooltip += previousLabel;
    tooltip += `<div class="series-row comparison">
      <div class="bp4-tag bp4-minimal bp4-round bp4-monospace-text timestamp">${previousTimestamp}</div>
      <div class="series-value">${previousValue}</div>
    </div>`;

    return tooltip;
  }

  if (currentPoints.length > 0) {
    tooltip += currentLabel;
    const timestamp = getTimestamp(currentPoint, { showSeconds });
    tooltip += `<span class="bp4-tag bp4-minimal bp4-round bp4-monospace-text timestamp">${timestamp}</span>`;

    let showHead = false;
    const rows = currentPoints
      .map((point) => {
        const { color, name, value } = getPointData({ point, getColorFromIndex });
        const isOverlay = point.series.options.model?.isOverlay;
        showHead ||= !isOverlay;
        return `<tr>
          <td class="series-marker" style="color:${color};">\u25CF</td>
          ${renderName(name, { isOverlay, length: dimensions.length })}
          <td class="series-value">${value}</td>
        </tr>`;
      })
      .join('');

    tooltip += `<table class="tooltip-table">${showHead ? head : ''}${rows}</table>`;
  }

  if (previousPoints.length > 0) {
    tooltip += previousLabel;

    const timestamp = getTimestamp(previousPoint, { offset, showSeconds });
    tooltip += `<span class="bp4-tag bp4-minimal bp4-round bp4-monospace-text timestamp">${timestamp}</span>`;

    let showHead = false;
    const rows = previousPoints
      .map((point) => {
        const { color, name, value } = getPointData({ point, getColorFromIndex });
        const isOverlay = point.series.options.model?.isOverlay;
        showHead ||= !isOverlay;
        return `<tr>
          <td class="series-marker" style="color:${color};">\u25CF</td>
          ${renderName(name, { isOverlay, length: dimensions.length })}
          <td class="series-value">${value}</td>
        </tr>`;
      })
      .join('');

    tooltip += `<table class="tooltip-table">${showHead ? head : ''}${rows}</table>`;
  }

  return tooltip;
}
