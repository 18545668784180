import React from 'react';
import { Tag } from 'core/components';
import { MdTrendingUp, MdTrendingDown, MdTrendingFlat } from 'react-icons/md';

const PercentChange = ({
  current,
  previous,
  increaseIntent = 'success',
  decreaseIntent = 'danger',
  notAvailable = 'Not Available',
  noChange = 'No Change',
  ...tagProps
}) => {
  const pctChange = Math.abs(Math.round(((previous - current) / Math.max(previous, 1)) * 100));

  if (pctChange > 0) {
    if (current > 0 && previous === 0) {
      return (
        <Tag icon={MdTrendingFlat} {...tagProps}>
          {notAvailable}
        </Tag>
      );
    }
    return (
      <Tag
        intent={current > previous ? increaseIntent : decreaseIntent}
        icon={current > previous ? MdTrendingUp : MdTrendingDown}
        {...tagProps}
      >
        {pctChange <= 999 ? pctChange : '> 999'}%
      </Tag>
    );
  }

  return (
    <Tag icon={MdTrendingFlat} iconProps={noChange ? undefined : { mr: 0 }} {...tagProps}>
      {noChange}
    </Tag>
  );
};

export default PercentChange;
