import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import styled from 'styled-components';

import { borders } from 'styled-system';
import Box from './Box';
import Icon, { ICON_OVERRIDE_KEYS } from './Icon';
import Heading from './Heading';

const StyledCallout = styled(Box)`
  border: 1px solid ${({ theme, intent }) => theme.colors[intent]};
  background: ${({ bg, theme, intent }) => bg || theme.colors.calloutOutlineBackgrounds[intent]};
  /* blueprint sets this to 1.5 by default */
  line-height: inherit;
  ${borders}
`;

const CalloutOutline = ({ title, textAlign, intent, icon, elevation, className, children, ...rest }) => {
  let pl;
  if (title) {
    pl = '29px';
  }

  // HACK: partially override blueprint styling to fix positioning when icon is NOT a blueprint icon
  const iconStyling = {};
  if (typeof icon === 'function' || ICON_OVERRIDE_KEYS.has(icon)) {
    iconStyling.ml = '-5px';
    iconStyling.mt = '-7px';
  }

  return (
    <StyledCallout
      intent={intent}
      className={classNames(Classes.CALLOUT, className, {
        [Classes.CALLOUT_ICON]: icon,
        [`bp4-elevation-${elevation}`]: elevation && elevation > 0
      })}
      style={{ textAlign }}
      pl={pl}
      {...rest}
    >
      {icon && <Icon icon={icon} {...iconStyling} />}
      {title && <Heading level={5}>{title}</Heading>}
      {children}
    </StyledCallout>
  );
};

CalloutOutline.defaultProps = {
  p: 1
};

export default CalloutOutline;
