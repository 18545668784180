/* eslint-disable react/destructuring-assignment */
import React from 'react';

import Button from 'core/components/Button';
import Checkbox from 'core/components/Checkbox';
import RemoveButton from 'core/form/components/RemoveButton';
import Popover from 'core/components/Popover';
import { Position } from '@blueprintjs/core';
import Menu from 'core/components/Menu';
import MenuItem from 'core/components/MenuItem';

/*
 * Reusable buttons that take action on a table row (model).
 * One-off buttons can be injected with raw JSX and do not belong here.
 */
export default {
  EDIT: (model) => (
    <Button
      key="edit"
      icon="edit"
      tracker="edit-btn"
      onClick={(e) => {
        e.stopPropagation();
        model.select();
      }}
      text="Edit"
      title="Edit"
      minimal
      small
    />
  ),
  DUPLICATE: (model) => (
    <Button
      key="duplicate"
      icon="duplicate"
      tracker="duplicate-btn"
      onClick={(e) => {
        e.stopPropagation();
        model.duplicate();
      }}
      text="Copy"
      title="Copy"
      minimal
      small
    />
  ),
  REMOVE: (model) => <RemoveButton key="remove" model={model} showIcon hideText small />,
  SELECT: (model) => (
    <Checkbox
      key="select"
      aria-label="Select row"
      checked={model.isSelected}
      disabled={!model.isSelectable}
      onKeyDown={(event) => {
        if (event.key === ' ' || event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          model.select({ multi: true, shiftKey: event.shiftKey });
        }
      }}
      onClick={(e) => {
        e.stopPropagation();
        model.select({ multi: true, shiftKey: e.shiftKey });
      }}
      mb={0}
    />
  ),
  KEBAB: (kebabActions) => (model) => (
    <Popover
      key="kebab"
      position={Position.BOTTOM}
      content={
        <Menu p={1} role="menu">
          {typeof kebabActions === 'function'
            ? // call kebabActions as a function, pass it the model and expect an array of menu items in return
              kebabActions(model)
            : // otherwise turn an array of kebabAction objects into menu items
              kebabActions.map((action) => {
                if (typeof action.hidden === 'function' ? action.hidden(model) : action.hidden) {
                  return null;
                }
                return action.renderer ? (
                  action.renderer(model)
                ) : (
                  <MenuItem
                    role="menuitem"
                    tracker={`kebab-action-${action.id}`}
                    key={action.id}
                    icon={action.icon}
                    disabled={typeof action.disabled === 'function' ? action.disabled(model) : action.disabled}
                    shouldDismissPopover={action.shouldDismissPopover || true}
                    text={typeof action.label === 'function' ? action.label(model) : action.label}
                    onClick={() => action.handler(model)}
                  />
                );
              })}
        </Menu>
      }
    >
      <Button
        tracker="kebab-btn"
        icon="more"
        color="muted"
        minimal
        small
        // cheaper alternative to a whole new svg icon
        style={{ transform: 'rotate(90deg)' }}
      />
    </Popover>
  )
};
