import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AutoSizer } from 'react-virtualized';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

import { InputGroup } from 'core/form';
import { Box, Button, Card, Menu, MenuItem, Spinner } from 'core/components';

import Map from 'app/components/map';

function getAddressComponent(address_components, field, options) {
  const { useShortName = false } = options || {};
  const { short_name, long_name } = address_components.find((component) => component.types.includes(field)) || {};

  return useShortName ? short_name : long_name;
}

@inject('$dictionary')
@observer
class AddressInput extends Component {
  static defaultProps = {
    showMap: true,
    geocodeSelection: true
  };

  state = {
    selectedAddress: undefined
  };

  autoCompleteRef = React.createRef();

  onMapLoad = (map, mapCmp) => {
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.map = map;
    this.mapCmp = mapCmp;
  };

  getGeocodeValues = (geocodeResult) => {
    const { geometry, formatted_address, address_components } = geocodeResult;

    return {
      address: formatted_address,
      lat: geometry.location.lat(),
      lon: geometry.location.lng(),
      country: getAddressComponent(address_components, 'country', { useShortName: true }),
      region: getAddressComponent(address_components, 'administrative_area_level_1'),
      city:
        getAddressComponent(address_components, 'locality') || getAddressComponent(address_components, 'sublocality'),
      postal: getAddressComponent(address_components, 'postal_code')
    };
  };

  onAddressSelect = (suggestion) => {
    const { geocodeSelection, onAddressSelect, onGeocodeComplete, field } = this.props;

    if (onAddressSelect) {
      onAddressSelect(suggestion);
    }

    if (geocodeSelection) {
      geocodeByPlaceId(suggestion.place_id).then((results) => {
        field.setValue(results[0].formatted_address);
        if (onGeocodeComplete) {
          onGeocodeComplete(this.getGeocodeValues(results[0]));
        }
        this.setState({ selectedAddress: results[0] }, this.addMarkers);
      });
    } else {
      field.setValue(suggestion.formatted_address);
    }
  };

  addMarkers = () => {
    const { showMap } = this.props;
    if (showMap) {
      this.mapCmp.removeMarkers();
      this.mapCmp.addMarkers();
    }
  };

  getMarkers = () => {
    const { selectedAddress } = this.state;
    const { handleMarkers } = this.props;

    if (handleMarkers) {
      return handleMarkers(selectedAddress);
    }

    if (selectedAddress) {
      return [
        {
          id: selectedAddress.place_id,
          lat: selectedAddress.geometry.location.lat(),
          lon: selectedAddress.geometry.location.lng(),
          title: selectedAddress.formatted_address,
          modelId: selectedAddress.place_id,
          color: 'green-small',
          element: 'dot'
        }
      ];
    }

    return undefined;
  };

  renderSuggestions = (active, suggestions, onSelectSuggestion) => {
    if (suggestions.length === 0) {
      return null;
    }

    return (
      <Card style={{ position: 'absolute', width: '100%', zIndex: 100 }} mt="3px">
        <Menu>
          {suggestions.map((suggestion) => (
            <MenuItem
              key={suggestion.description}
              onClick={(e) => onSelectSuggestion(suggestion, e)}
              active={active}
              text={suggestion.description}
            />
          ))}
        </Menu>
      </Card>
    );
  };

  clearAddressSelection = () => {
    const { onGeocodeComplete, field } = this.props;
    this.autoCompleteRef.current.clearValue();
    field.setValue('');
    // sets parent selected address to null & removes markers
    if (onGeocodeComplete) {
      onGeocodeComplete(undefined);
    }
    this.setState({ selectedAddress: undefined });
  };

  render() {
    const { $dictionary, field, showMap, inputProps, labelId } = this.props;

    const ariaPropsInput = {};
    if (labelId) {
      ariaPropsInput.labelId = labelId;
    }

    return (
      <Box position="relative">
        <GooglePlacesAutocomplete
          apiKey={$dictionary.get('googleMapsPublicApiKey')}
          initialValue={field.getValue()}
          ref={this.autoCompleteRef}
          onSelect={this.onAddressSelect}
          renderInput={(props) => (
            <InputGroup
              {...props}
              fill
              {...ariaPropsInput}
              {...inputProps}
              rightElement={
                field.getValue() ? (
                  <Button small minimal icon="cross" iconSize={16} onClick={this.clearAddressSelection} />
                ) : null
              }
            />
          )}
          loader={
            <Card style={{ position: 'absolute', width: '100%', zIndex: 100 }} p={2} mt="3px">
              <Spinner size={16} />
            </Card>
          }
          renderSuggestions={this.renderSuggestions}
        />
        {showMap && (
          <Card mt={1} width="100%" height={200} p={0} mb={2} overflow="hidden">
            <AutoSizer disableHeight>
              {({ width }) => {
                if (width === 0) {
                  return null;
                }

                const height = 200;
                return (
                  <Box width="100%" height={height}>
                    <Map
                      containerWrapperProps={{ width, height }}
                      markerPopupOnHover
                      onLoad={this.onMapLoad}
                      markers={this.getMarkers()}
                      maxZoom={16}
                      scrollZoom={false}
                      autoBounds
                      useBasicTiles
                    />
                  </Box>
                );
              }}
            </AutoSizer>
          </Card>
        )}
      </Box>
    );
  }
}

export default AddressInput;
