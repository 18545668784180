import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { formConsumer, Field, CheckboxGroup, InputGroup, Switch, Select } from 'core/form';
import { Flex, Text, Link, CalloutOutline, Heading } from 'core/components';
import {
  CredentialOptionRenderer,
  CredentialValueRenderer,
  KentikAgentOptionRenderer
} from 'app/views/setup/tasks/nms/optionRenderers';
import SetupCredentials from 'app/views/setup/tasks/nms/SetupCredentials';
import { collectionMethodRenderer } from './IpSnmpSettings';

@formConsumer
@inject('$auth', '$devices', '$dictionary', '$credentials', '$kentikAgents')
@observer
class StSettings extends Component {
  state = {
    isAddingCredentialDialogOpen: false,
    credentialModel: null
  };

  constructor(props) {
    super(props);
    const { form, $auth } = this.props;

    // Hide the kentik_agent option if user doesn't have recon.enabled permission
    if (!$auth.hasPermission('recon.enabled', { overrideForSudo: false })) {
      const collectionField = form.getField('streaming_telemetry_collection_method');
      collectionField.options = collectionField.options.filter((option) => option.value !== 'kentik_agent');
      form.validate();
    }
  }

  componentDidMount() {
    const { form } = this.props;

    // Streaming Telemetry collection via kProxy
    if (form.getValue('device_gnmi_v1_conf')) {
      form.setValue('streaming_telemetry_collection_method', ['kproxy']);
    }
  }

  handleCredentialChange = (field, value) => {
    const { $credentials } = this.props;
    // open the new credential dialog
    if (value === '$NEW') {
      field.setValue('');
      this.setState({
        isAddingCredentialDialogOpen: true,
        credentialModel: $credentials.groupedCollection.forge({ type: 'streaming_telemetry' })
      });
    }
  };

  handleCloseCredentialsDialog = (formValues) => {
    const { form } = this.props;

    if (formValues) {
      const { credentialName } = formValues;
      form.setValue('streaming_telemetry_collection_options.credential_name', credentialName);
    }

    this.setState({ isAddingCredentialDialogOpen: false, credentialModel: null });
  };

  render() {
    const { form, $dictionary, $devices, $credentials, $kentikAgents, role } = this.props;
    const { isAddingCredentialDialogOpen, credentialModel } = this.state;
    const { companySettings } = $devices;
    const { sending_ips } = form.getValues();
    const kbLinkST = $dictionary.getHelpUrl('admin', 'configureST');

    // const useSSL = form.getValue('streaming_telemetry_collection_options.use_ssl');
    // const useCertificateAuth = form.getValue('streaming_telemetry_collection_options.enable_certificate_auth');
    const useKProxyCollection = form.getValue('streaming_telemetry_collection_method').includes('kproxy');
    const useKentikAgentCollection = form.getValue('streaming_telemetry_collection_method').includes('kentik_agent');

    const credentialTypes = ['streaming_telemetry'];
    const credentialOptions = $credentials.getCredentialOptionsByTypes(credentialTypes);

    const ariaProps = {};
    if (role) {
      ariaProps.role = role;
    }

    return (
      <Flex flexDirection="column" overflow="auto" gap={2} p={2} {...ariaProps}>
        <Heading level={5} pb="6px" mb={0} borderBottom="thin">
          Collection method
        </Heading>

        <Field m={0} name="streaming_telemetry_collection_method" showLabel={false}>
          <CheckboxGroup
            containerProps={{ flexDirection: 'column', gap: '8px' }}
            itemRenderer={collectionMethodRenderer}
          />
        </Field>

        {useKentikAgentCollection && (
          <Flex flexDirection="column" gap={2}>
            <Heading level={5} pb="6px" mb={0} borderBottom="thin">
              Dial-In from Universal Agent Options
            </Heading>

            <Flex gap={2}>
              <Field
                m={0}
                flex={1}
                onChange={this.handleCredentialChange}
                name="streaming_telemetry_collection_options.credential_name"
                options={[
                  { value: '$NEW', label: 'New Credential', icon: 'plus', intent: 'primary' },
                  ...credentialOptions
                ]}
              >
                <Select fill optionRenderer={CredentialOptionRenderer} valueRenderer={CredentialValueRenderer} />
              </Field>

              <Flex flexDirection="column" gap={1} flex={1}>
                <Field
                  m={0}
                  flex={1}
                  name="kentik_agent_snmp_options.agent_id"
                  options={$kentikAgents.authorizedKentikAgentOptions}
                  disabled
                >
                  <Select fill optionRenderer={KentikAgentOptionRenderer} />
                </Field>
                <Text muted>Streaming Telemetry collection is handled by the agent configured on the SNMP tab.</Text>
              </Flex>
            </Flex>

            <Flex gap={2}>
              <Field m={0} name="streaming_telemetry_collection_options.timeout">
                <InputGroup width={150} />
              </Field>

              <Field m={0} name="streaming_telemetry_collection_options.port">
                <InputGroup width={150} />
              </Field>
            </Flex>

            <Field m={0} name="streaming_telemetry_collection_options.use_ssl" showLabel={false}>
              <Switch switchLabel="Use secure connection (SSL)" />
            </Field>
          </Flex>
        )}

        {useKProxyCollection && (
          <CalloutOutline intent="warning" mb={2} p={2}>
            <Heading level={5} pb="6px">
              Dial-Out to kProxy Configuration
            </Heading>
            <Flex flexDirection="column" gap="12px">
              <Flex>
                <Text as="div" pr={1}>
                  Send streaming telemetry from IP address:
                </Text>
                {sending_ips && sending_ips?.length > 0 ? (
                  <Text as="div" fontWeight="bold" monospace>
                    {sending_ips[0]}
                  </Text>
                ) : (
                  <Text as="div" muted>
                    {'<< add Sending IP on Flow tab >>'}
                  </Text>
                )}
              </Flex>
              <Text as="div">Send streaming telemetry to Kentik at the following destination:</Text>
              <Flex gap={1} ml={2}>
                <Text as="div" width={130}>
                  Kentik Ingest IP:
                </Text>
                <Text as="div" fontWeight="bold" monospace>
                  {companySettings.flow_ips.join(', ')}
                </Text>
              </Flex>
              <Flex gap={1} ml={2}>
                <Text as="div" width={130}>
                  Kentik ST port:
                </Text>
                <Text as="div" fontWeight="bold" monospace>
                  {20023}
                </Text>
              </Flex>

              <Link to={kbLinkST} blank>
                Need help configuring Streaming Telemetry?
              </Link>
            </Flex>
          </CalloutOutline>
        )}

        {credentialModel && (
          <SetupCredentials
            isOpen={isAddingCredentialDialogOpen}
            model={credentialModel}
            onClose={this.handleCloseCredentialsDialog}
            types={credentialTypes}
            title="Add Streaming Telemetry Credential"
            // since we are adding a Streaming Telemetry credential here, type selection is not necessary
            hideTypeSelector
          />
        )}
      </Flex>
    );
  }
}

export default StSettings;
