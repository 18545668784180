import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import storeLoader from 'app/stores/storeLoader';
import { formConsumer, Field, InputGroup, TextArea, Select, Switch } from 'core/form';
import { Text, Flex, Box } from 'core/components';
import DeviceSubtypeIcon from 'app/components/device/DeviceSubtypeIcon';
import LabelSelector from 'app/components/labels/LabelSelector';
import SiteSelect from 'app/components/site/SiteSelect';
import { computed } from 'mobx';

const deviceSubtypeOptionRenderer = (props) => {
  const {
    className,
    disabled,
    key,
    label,
    selectItem,
    style,
    separator = false,
    value,
    optionFormatter,
    selected,
    handleOptionRef,
    focused
  } = props;

  const ariaProps = {};
  if (selected) {
    ariaProps['aria-selected'] = selected;
  }

  if (separator) {
    return <hr key="separator" style={{ margin: '4px 8px' }} />;
  }

  const onClick = !disabled ? () => selectItem(value) : undefined;

  if (focused && handleOptionRef) {
    return (
      <div
        key={key || value}
        className={className}
        onClick={onClick}
        style={style}
        {...ariaProps}
        tabIndex="0"
        ref={handleOptionRef}
      >
        <DeviceSubtypeIcon type={value} style={{ marginRight: 6 }} />
        {optionFormatter ? optionFormatter(props) : label}
      </div>
    );
  }
  return (
    <div key={key || value} className={className} onClick={onClick} style={style}>
      <DeviceSubtypeIcon type={value} style={{ marginRight: 6 }} />
      {optionFormatter ? optionFormatter(props) : label}
    </div>
  );
};

const deviceSubtypeValueRenderer = (option, placeholder) => {
  if (!option) {
    return <Text muted>{placeholder || 'Select a value...'}</Text>;
  }

  return (
    <Text as="div" ellipsis className={option.className}>
      <DeviceSubtypeIcon type={option.value} style={{ marginRight: 6, marginLeft: 'auto' }} />
      {option.label}
    </Text>
  );
};

@storeLoader('$kproxyAgents', '$sites')
@inject('$devices', '$dictionary')
@formConsumer
@observer
export default class GeneralSettings extends Component {
  state = {
    showAddSite: false
  };

  handleOnAddToggle = (showAddSite) => {
    this.setState({ showAddSite });
  };

  handleOnSiteChange = () => {
    const { form } = this.props;
    form.getField('role').setValue('');
  };

  getSubtypeOptions() {
    const { $dictionary } = this.props;
    const { device_subtypes } = $dictionary.dictionary;
    return Object.values(device_subtypes)
      .reduce((acc, deviceSubtype) => {
        // don't allow users to manually select internal type
        const internalTypes = ['kagent', 'kmetrics', 'kproxy'];
        if (!internalTypes.includes(deviceSubtype.device_subtype) && !deviceSubtype.metadata.cloud) {
          acc.push({
            value: deviceSubtype.device_subtype,
            label: deviceSubtype.display_name,
            iconName: deviceSubtype.icon
          });
        }
        return acc;
      }, [])
      .sort((a, b) => {
        if (b.value === 'router') {
          return 4;
        }
        if (b.value === 'kprobe') {
          return 3;
        }
        if (a.value === 'kprobe' || a.value === 'router') {
          return -1;
        }

        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
  }

  handleDeviceTypeChange = (field) => {
    const { form } = this.props;
    if (field.value === 'kprobe') {
      form.setValue('device_bgp_type', 'none');
    }
  };

  @computed
  get roleOptions() {
    const { form, $sites } = this.props;
    const siteId = form.getField('site.id').getValue();
    if (siteId) {
      const site = $sites.collection.get(siteId);
      if (site) {
        const { architecture } = site.get('metadata');
        if (architecture) {
          const roles = architecture.map((layer) => layer.subLayers.map((subLayer) => subLayer.name)).flat();
          return roles.map((role) => ({ label: role, value: role }));
        }
      }
    }
    return null;
  }

  render() {
    const { form, $devices, role } = this.props;
    const { showAddSite } = this.state;

    const device_subtype = form.getValue('device_subtype');
    const deviceType = $devices.getDeviceTypeFromSubtype(device_subtype);
    const ariaProps = {};
    if (role) {
      ariaProps.role = role;
    }

    return (
      <Flex flexDirection="column" gap={2} overflow="auto" p={2} {...ariaProps}>
        <Field name="device_name" autoFocus m={0}>
          <InputGroup fill />
        </Field>

        <Field name="device_description" m={0}>
          <TextArea rows="2" fill />
        </Field>

        <Flex gap={2}>
          <SiteSelect
            showLabel
            onChange={this.handleOnSiteChange}
            onAddToggle={this.handleOnAddToggle}
            containerProps={{ mb: 0 }}
          />
          {!showAddSite && this.roleOptions?.length && (
            <>
              <Field
                name="role"
                options={this.roleOptions}
                helpText={this.roleOptions ? null : 'There is no architecture defined for this site.'}
                disabled={!this.roleOptions}
                mb={0}
              >
                <Select />
              </Field>
            </>
          )}
        </Flex>

        <Field name="device_subtype" options={this.getSubtypeOptions()} onChange={this.handleDeviceTypeChange} m={0}>
          <Select
            optionRenderer={deviceSubtypeOptionRenderer}
            valueRenderer={deviceSubtypeValueRenderer}
            menuWidth={290}
          />
        </Field>

        <Box m={0}>
          <LabelSelector fieldName="labels" useDialog />
        </Box>

        {deviceType === 'host-nprobe-dns-www' && (
          <Field name="cdn_attr" m={0}>
            <Switch />
          </Field>
        )}
      </Flex>
    );
  }
}
