import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { PopoverInteractionKind } from '@blueprintjs/core';
import LargeLabeledValue from 'app/components/LargeLabeledValue';
import PercentChange from 'app/components/PercentChange';
import { Box, Flex, Popover, Sparkline, Text } from 'core/components';

@observer
class CurrentCostTrend extends Component {
  static defaultProps = {
    property: 'cost',
    label: 'Estimated Cost',
    labelSize: 30
  };

  render() {
    const { barData, current, previous, currency, sparklineData, label, labelSize, ...containerProps } = this.props;
    let sparklineColor = 'primary';

    if (current > previous) {
      sparklineColor = 'danger';
    }
    if (current < previous) {
      sparklineColor = 'success';
    }

    const total = barData ? barData.reduce((acc, item) => acc + item.value, 0) : 0;

    return (
      <Flex {...containerProps}>
        <Box mr={1}>
          <LargeLabeledValue
            label={label}
            currency={currency}
            value={current}
            valueSize={labelSize}
            valueWeight="normal"
            labelTextProps={{ whiteSpace: 'nowrap' }}
          />

          <PercentChange
            round
            minimal
            increaseIntent="danger"
            decreaseIntent="success"
            current={current}
            previous={previous}
            noChange=""
            notAvailable=""
          />
        </Box>

        {sparklineData && (
          <Box flex={1} minWidth={40} maxWidth={140}>
            <Sparkline data={sparklineData} height={80} color={sparklineColor} />
          </Box>
        )}

        {barData && total > 0 && (
          <Flex alignItems="center" flexDirection="column" justifyContent="center" ml={1}>
            <Flex width={100} height={20} bg="gray.1" borderRadius={2}>
              {barData.map((item, idx) => (
                <Box
                  className={idx === 0 ? 'bp4-callout bp4-intent-success' : 'bp4-callout bp4-intent-danger'}
                  borderLeft={idx === 0 ? undefined : 'thin'}
                  borderRight={idx === 0 ? 'thin' : undefined}
                  key={item.label}
                  p={0}
                  width={`${(item.value / total) * 100}%`}
                >
                  <Popover
                    interactionKind={PopoverInteractionKind.HOVER}
                    content={
                      <Flex alignItems="center" flexDirection="column" p={1}>
                        <Text fontWeight="bold" small>
                          {item.label}
                        </Text>
                        <Text small>{Math.round((item.value / total) * 100)}%</Text>
                      </Flex>
                    }
                    minimal={false}
                    position="bottom"
                  >
                    <Box height="100%" width="100%">
                      &nbsp;
                    </Box>
                  </Popover>
                </Box>
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>
    );
  }
}

export default CurrentCostTrend;
