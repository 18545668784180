import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Button from 'core/components/Button';
import ConfirmPopover from 'core/components/ConfirmPopover';

@observer
class RemoveButton extends Component {
  static defaultProps = {
    hideText: false,
    intent: 'danger',
    showIcon: false,
    minimal: true,
    text: 'Remove',
    icon: 'trash'
  };

  handleRemove = async () => {
    const { model, onRemove } = this.props;

    if (onRemove) {
      return onRemove();
    }

    return model.destroy();
  };

  render() {
    const {
      disabled,
      hideText,
      model,
      entityName,
      large,
      text,
      icon,
      intent,
      showIcon,
      buttonProps,
      popoverProps,
      minimal,
      small
    } = this.props;

    const isCreating = model && model.isRequestActive('creating');
    const isSaving = model && model.isRequestActive('updating');

    return (
      <ConfirmPopover onConfirm={this.handleRemove} confirmText={text} entityName={entityName} {...popoverProps}>
        <Button
          disabled={disabled || isSaving || isCreating}
          text={hideText ? undefined : text}
          minimal={minimal}
          intent={intent}
          icon={showIcon ? icon : null}
          large={large}
          small={small}
          title={text}
          tracker="remove-btn"
          ariaLabel={text}
          {...buttonProps}
        />
      </ConfirmPopover>
    );
  }
}

export default RemoveButton;
