import React from 'react';
import { observer } from 'mobx-react';
import Box from 'core/components/Box';
import ButtonGroup from 'core/components/ButtonGroup';
import Label from 'core/components/Label';
import FormGroup from 'core/form/components/FormGroup';

const ToggleButtonGroup = (props) => {
  const { selectedValue, className, children, label, style, disabled, small, large, fill, groupProps, ...boxProps } =
    props;

  const getButtonProps = (buttonProps) => {
    const { value, intent, className: buttonClassName } = buttonProps;

    return {
      ...buttonProps,
      active: selectedValue === value,
      className: buttonClassName,
      small,
      disabled,
      intent
    };
  };

  return (
    <FormGroup m={0} style={style} {...boxProps}>
      {label && (
        <Box mb="4px">
          <Label small={small} large={large}>
            {label}
          </Label>
        </Box>
      )}
      <ButtonGroup fill={fill} {...groupProps} role="radiogroup">
        {React.Children.map(children, (button) => React.cloneElement(button, getButtonProps(button.props)))}
      </ButtonGroup>
    </FormGroup>
  );
};

export default observer(ToggleButtonGroup);
