import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { display, fontFamily, fontSize, fontWeight, space, themeGet } from 'styled-system';
import { Button as BlueprintButton, Classes } from '@blueprintjs/core';
import { rgba } from 'polished';
import classNames from 'classnames';
import { get } from 'lodash';

import { formAriaProps, guessByChildText, guessTextOnIcon } from 'core/util/ariaUtils';
import { omitAriaProps } from 'core/form/components/util/omitFieldProps';
import Icon from './Icon';
import position from './utils/position';
import flex from './utils/flex';
import size from './utils/size';

const StyledButton = styled(BlueprintButton)`
  &.${Classes.BUTTON} {
    ${display};
    ${fontFamily};
    ${fontSize};
    ${fontWeight};
    ${space};

    ${position};
    ${flex};
    ${size};

    ${({ theme, small }) =>
      small &&
      css`
        font-size: ${theme?.fontSizes.small};

        .bp4-icon {
          &:first-child {
            margin-right: 4px;
          }

          &:first-child:last-child {
            margin: 0 -7px;
          }
        }
      `}

    > .${Classes.BUTTON_TEXT} {
      overflow: hidden;
      flex: 1 1 auto;
      text-align: left;
      color: ${({ color }) => themeGet(`colors.${color}`, color)};
    }

    &.align-center {
      > .${Classes.BUTTON_TEXT} {
        text-align: center;
      }
    }

    ${(props) =>
      props.ellipsis &&
      css`
        > .${Classes.BUTTON_TEXT} {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      `}

    &.translucent.${Classes.MINIMAL} {
      background: ${({ theme }) => rgba(theme?.colors?.gray5, 0.3)};

      &:hover {
        background: ${({ theme }) => rgba(theme?.colors?.gray3, 0.3)};
      }
    }
  }
`;

const Button = React.forwardRef((props, ref) => {
  // destructure minWidth and alignSelf, otherwise we get non-standard prop warnings from React.
  const {
    active,
    activeBg,
    alignSelf,
    ariaLabel,
    ariaExpanded,
    ariaHaspopup,
    onSpaceOrEnter,
    border,
    minHeight,
    minWidth,
    maxWidth,
    borderRadius,
    className,
    color,
    explicitTabIndex,
    icon,
    rightIcon,
    large,
    loading,
    small,
    justifyContent,
    shouldDismissPopover,
    iconSize,
    width,
    height,
    textAlign,
    textColor,
    title,
    theme,
    staticContext,
    bg,
    flexDirection,
    alignItems,
    flexWrap,
    tracker,
    ...rest
  } = props;

  let finalIconSize = 16;
  if (iconSize) {
    finalIconSize = iconSize;
  } else if (large) {
    finalIconSize = 20;
  } else if (small) {
    finalIconSize = 12;
  }

  let background = bg;
  if (active && activeBg) {
    background = activeBg;
  }
  let ariaLabelGuess = rest.ellipsis ? rest.text : title;
  ariaLabelGuess =
    ariaLabelGuess ||
    rest.text ||
    guessByChildText(rest.children) ||
    (icon && guessTextOnIcon(icon)) ||
    (rightIcon && guessTextOnIcon(rightIcon));

  const ariaLabelText = ariaLabel || ariaLabelGuess;
  const buttonClassName = classNames(className, tracker, { 'align-center': textAlign === 'center' });

  const ariaProps = formAriaProps(
    props,
    {
      ariaLabel: ariaLabelText || rest.text,
      ariaPressed: active,
      ariaSelected: active,
      name: rest.name || rest.text || rest.ariaLabel || rest['aria-label'],
      ariaHaspopup,
      ariaExpanded
    },
    {
      enableTabIndexNotDisabled: true,
      explicitTabIndex: rest?.explicitTabIndex || 0
    }
  );

  if (rest.role) {
    ariaProps.role = rest.role;
  } else {
    ariaProps.role = 'button';
  }

  if (onSpaceOrEnter) {
    ariaProps.onKeyDown = (event) => {
      if (event.key === ' ' || event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        onSpaceOrEnter(event);
      }
    };
  }

  return (
    <StyledButton
      // rest has a tabIndex=undefined so it has to go first
      {...omitAriaProps(rest)}
      {...ariaProps}
      active={active}
      large={large}
      loading={loading}
      small={small}
      className={buttonClassName}
      style={{
        alignSelf,
        alignItems,
        flexWrap,
        border,
        minHeight,
        minWidth,
        maxWidth,
        borderRadius,
        justifyContent,
        flexDirection,
        width,
        height,
        backgroundColor: get(theme?.colors, background),
        color: get(theme?.colors, textColor || color),
        ...(rest.style || {})
      }}
      title={rest.ellipsis ? rest.text : title}
      icon={icon ? <Icon icon={icon} color={color} iconSize={finalIconSize} /> : undefined}
      rightIcon={rightIcon ? <Icon icon={rightIcon} color={color} iconSize={finalIconSize} /> : undefined}
      ref={ref}
    />
  );
});

Button.defaultProps = {
  fontFamily: 'body',
  textAlign: 'center',
  ariaHaspopup: false
};

Button.displayName = 'Button';

export default withTheme(Button);
