import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

const MenuItemLight = (props) => {
  const { active, children, className, disabled, onClick, style, role } = props;
  const ariaProps = {};
  if (role) {
    ariaProps.role = role;
  }
  return (
    <li
      {...ariaProps}
      tabIndex={disabled ? -1 : 0}
      className={classNames(className, Classes.MENU_ITEM, {
        [Classes.ACTIVE]: active,
        [Classes.DISABLED]: disabled
      })}
      onClick={disabled ? undefined : onClick}
      style={style}
    >
      {children}
    </li>
  );
};

export default MenuItemLight;
