import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { getValueColumns } from 'app/util/queryResults';
import { HTMLTable, Text, Box, Icon, Flex } from 'core/components';
import {
  applicationRenderer,
  asPathRenderer,
  asnRenderer,
  kappaRenderer,
  bgpCommunityRenderer,
  cdnRenderer,
  cityRenderer,
  connectivityTypeRenderer,
  countryRenderer,
  deviceNameRenderer,
  inetFamilyRenderer,
  interfaceRenderer,
  ipRenderer,
  networkBoundaryRenderer,
  ottProviderRenderer,
  ottServiceRenderer,
  ottServiceTypeRenderer,
  packetSizeRenderer,
  portRenderer,
  protocolRenderer,
  providerRenderer,
  regionRenderer,
  routePrefixRenderer,
  serviceRenderer,
  siteNameRenderer,
  siteMarketRenderer,
  valueOrNameRenderer,
  hideRawValueRenderer,
  cloudDetailRenderer
} from 'app/components/dataviews/views/legend/legendRenderers';
import {
  applicationMetrics,
  asPathMetrics,
  asnMetrics,
  cdnMetrics,
  cityMetrics,
  countryMetrics,
  interfaceMetrics,
  ipMetrics,
  ottProviderMetrics,
  ottServiceMetrics,
  ottServiceTypeMetrics,
  regionMetrics,
  serviceMetrics,
  siteMetrics,
  siteMarketMetrics,
  findMetricOption
} from 'app/components/dataviews/views/legend/legendUtils';
import { getQueryTimeInterval } from 'core/util/dateUtils';
import { all as cloudDetailMetrics } from 'app/views/core/cloud/cloudDetailMetrics';

import Dimension from './Dimension';
import Header from './Header';
import Row from './Row';

@inject('$dictionary')
@observer
class ResultsTable extends Component {
  static defaultProps = {
    showSparklines: true,
    showDeviceForInterface: true,
    showActionsMenu: false,
    showRefresh: false,
    showLastUpdated: false,
    showCombinedTotalColumn: false,
    showLastDatapoints: false,
    placeholderRows: 8,
    striped: true,
    interactive: false,
    emptyState: null,
    bordered: false,
    condensed: false,
    stickyHeader: false,
    limit: undefined,
    dimensionOverrides: [],
    valueOverrides: [],
    mergeOverlayCells: false
  };

  static getDerivedStateFromProps(props) {
    const { queryResultsCollection, limit } = props;

    if (!queryResultsCollection) {
      return { results: [] };
    }

    if (!queryResultsCollection.sortState.field) {
      queryResultsCollection.sort(props.queryModel.get('outsort'));
    }

    return {
      results: queryResultsCollection.slice(0, limit)
    };
  }

  state = {};

  getMetricColumn(metric) {
    const { $dictionary } = this.props;
    const metricColumns = $dictionary.get('metricColumns');
    const metricOption = findMetricOption(metric);

    if (!metricOption) {
      return {
        name: metricColumns[metric] || metric,
        key: metricColumns[metric] || metric,
        label: metricColumns[metric] || metric,
        metric
      };
    }

    const { label, group, tagLabel } = metricOption;

    return {
      name: metricColumns[metric] || metric,
      key: metricColumns[metric] || metric,
      metric,
      label: <Dimension group={group} label={tagLabel || label} />
    };
  }

  getDimensionColumns(queryModel, bucket) {
    const { $dictionary } = this.props;
    const metricColumns = $dictionary.get('metricColumns');
    const metrics = bucket.firstQuery.get('metric');
    const fastData = bucket.firstQuery.get('fastData');
    const filterDimensionsEnabled = bucket.firstQuery.get('filterDimensionsEnabled');

    let dimensionColumns = [];

    if (filterDimensionsEnabled) {
      const filterDimensionName = bucket.firstQuery.get('filterDimensionName');

      dimensionColumns.push({
        name: metricColumns.Traffic,
        label: (
          <>
            <Text muted as="div" fontSize="small" fontWeight="normal">
              Filter-Based
            </Text>
            {filterDimensionName}
          </>
        ),
        renderer: valueOrNameRenderer(false)
      });
    } else {
      metrics.forEach((metric, index) => {
        let column = this.getMetricColumn(metric);

        if (queryModel.get('aggregateFiltersEnabled')) {
          // Find the base metric information from which to derive renderers
          const [filter] = queryModel.get('aggregateFilters');
          if (filter.metric) {
            [metric] = filter.metric;
            column = this.getMetricColumn(metric);
          }

          // Update the place in which to look for key if provided
          const aggregateFiltersDimensionLabel = queryModel.get('aggregateFiltersDimensionLabel');
          if (aggregateFiltersDimensionLabel) {
            column.key = aggregateFiltersDimensionLabel;
            column.name = aggregateFiltersDimensionLabel;
            column.label = aggregateFiltersDimensionLabel;
          }
        }

        if (fastData !== 'Full' && (metric === 'Port_src' || metric === 'Port_dst')) {
          const lookback_seconds = bucket.firstQuery.get('lookback_seconds');
          const starting_time = bucket.firstQuery.get('starting_time');
          const ending_time = bucket.firstQuery.get('ending_time');
          const queryInterval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });
          column.renderer = portRenderer(index === 0, false, fastData, queryInterval);
        } else if (countryMetrics.includes(metric)) {
          column.renderer = countryRenderer();
        } else if (cityMetrics.includes(metric)) {
          column.renderer = cityRenderer();
        } else if (regionMetrics.includes(metric)) {
          column.renderer = regionRenderer();
        } else if (interfaceMetrics.includes(metric)) {
          column.renderer = interfaceRenderer();
        } else if (metric === 'i_device_id' || metric === 'i_ult_exit_device_name') {
          column.renderer = deviceNameRenderer();
        } else if (siteMetrics.includes(metric)) {
          column.renderer = siteNameRenderer();
        } else if (siteMarketMetrics.includes(metric)) {
          column.renderer = siteMarketRenderer();
        } else if (metric === 'ktsubtype__kappa__STR08' || metric === 'ktsubtype__kappa__STR14') {
          column.renderer = kappaRenderer();
        } else if (metric === 'inet_family') {
          column.renderer = inetFamilyRenderer();
        } else if (metric === 'sampledpktsize') {
          column.renderer = packetSizeRenderer();
        } else if (metric === 'Proto') {
          column.renderer = protocolRenderer();
        } else if (metric === 'src_route_prefix_len' || metric === 'dst_route_prefix_len') {
          column.renderer = routePrefixRenderer();
        } else if (
          metric === 'i_src_network_bndry_name' ||
          metric === 'i_dst_network_bndry_name' ||
          metric === 'i_ult_exit_network_bndry_name'
        ) {
          column.renderer = networkBoundaryRenderer();
        } else if (metric === 'src_bgp_community' || metric === 'dst_bgp_community') {
          column.renderer = bgpCommunityRenderer();
        } else if (
          metric === 'i_src_connect_type_name' ||
          metric === 'i_dst_connect_type_name' ||
          metric === 'i_ult_exit_connect_type_name'
        ) {
          column.renderer = connectivityTypeRenderer();
        } else if (
          metric === 'i_src_provider_classification' ||
          metric === 'i_dst_provider_classification' ||
          metric === 'i_ult_provider_classification'
        ) {
          column.renderer = providerRenderer();
        } else if (asnMetrics.includes(metric)) {
          column.renderer = asnRenderer();
        } else if (asPathMetrics.includes(metric)) {
          column.renderer = asPathRenderer();
        } else if (serviceMetrics.includes(metric)) {
          column.renderer = serviceRenderer();
        } else if (ipMetrics.includes(metric)) {
          column.renderer = ipRenderer();
        } else if (cdnMetrics.includes(metric)) {
          column.renderer = cdnRenderer();
        } else if (ottProviderMetrics.includes(metric)) {
          column.renderer = ottProviderRenderer();
        } else if (ottServiceMetrics.includes(metric)) {
          column.renderer = ottServiceRenderer();
        } else if (ottServiceTypeMetrics.includes(metric)) {
          column.renderer = ottServiceTypeRenderer();
        } else if (applicationMetrics.includes(metric)) {
          column.renderer = applicationRenderer();
        } else if (cloudDetailMetrics.includes(metric)) {
          column.renderer = cloudDetailRenderer();
        } else if ($dictionary.get('hideRawValueDimensions', []).includes(metric)) {
          column.renderer = hideRawValueRenderer();
        } else if (index === 0) {
          column.renderer = valueOrNameRenderer();
        }

        dimensionColumns.push(column);
      });
    }

    if (this.shouldAddDeviceColumn(dimensionColumns)) {
      let deviceColumn = 'i_device_id';

      if (metrics.includes('bgp_ult_exit_interface')) {
        deviceColumn = 'i_ult_exit_device_name';
      }

      dimensionColumns = [
        {
          ...this.getMetricColumn(deviceColumn),
          renderer: deviceNameRenderer()
        },
        ...dimensionColumns
      ];
    }

    return dimensionColumns;
  }

  mergeColumns(columns = [], overrides = []) {
    const mergedColumns = [...columns];

    overrides.forEach((override) => {
      const idx = mergedColumns.findIndex(
        (col) => (col.key && col.key === override.key) || (col.name && col.name === override.name)
      );

      if (idx > -1) {
        mergedColumns[idx] = {
          ...mergedColumns[idx],
          ...override
        };
      } else {
        mergedColumns.push(override);
      }
    });

    return mergedColumns;
  }

  shouldAddDeviceColumn(dimensionColumns) {
    const { showDeviceForInterface } = this.props;
    return showDeviceForInterface && dimensionColumns.some((column) => interfaceMetrics.includes(column.metric));
  }

  getRowKey(row, dimensionColumns) {
    if (this.shouldAddDeviceColumn(dimensionColumns)) {
      return `${row.get('key')}-${row.get('i_device_name')}`;
    }

    return `${row.get('key')}-${row.id}`;
  }

  handleColumnClick = (event) => {
    const { queryResultsCollection, limit, onSort } = this.props;
    const { currentTarget } = event;
    const { dataset } = currentTarget || {};
    const { column } = dataset;

    queryResultsCollection.sort(column);

    if (onSort) {
      onSort(queryResultsCollection.sortState);
    }

    this.setState({
      results: queryResultsCollection.slice(0, limit)
    });
  };

  renderNoResults(emptyStateColSpan) {
    const { emptyState } = this.props;

    if (emptyState) {
      return (
        <tbody>
          <tr>
            <td colSpan={emptyStateColSpan}>{emptyState}</td>
          </tr>
        </tbody>
      );
    }
    return (
      <tbody>
        <tr>
          <Box as="td" colSpan={emptyStateColSpan} p={4}>
            <Flex alignItems="center" justifyContent="center">
              <Icon icon="folder-close" mr={1} color="muted" iconSize={16} />
              <Text color="muted" fontStyle="italic" fontWeight="bold">
                No results
              </Text>
            </Flex>
          </Box>
        </tr>
      </tbody>
    );
  }

  render() {
    const {
      dimensionOverrides,
      valueOverrides,
      queryResultsCollection,
      showCombinedTotalColumn,
      showLastDatapoints,
      showActionsMenu,
      showSparklines,
      placeholderRows,
      striped,
      interactive,
      bordered,
      condensed,
      stickyHeader,
      loading,
      queryModel,
      bucket,
      actions,
      allowSelection,
      mergeOverlayCells,
      tableClass
    } = this.props;
    const { results } = this.state;

    const placeholders = [...new Array(placeholderRows)];
    const dimensionColumns = this.mergeColumns(this.getDimensionColumns(queryModel, bucket), dimensionOverrides).filter(
      (c) => !c.hidden
    );
    const valueColumns = this.mergeColumns(
      getValueColumns({ queryModel, bucket, showCombinedTotalColumn, showLastDatapoints, queryResultsCollection }),
      valueOverrides
    );

    const emptyStateColSpan =
      (allowSelection ? 1 : 0) + dimensionColumns.length + valueColumns.length + (showActionsMenu || actions ? 1 : 0);

    return (
      <HTMLTable
        striped={striped}
        interactive={interactive}
        bordered={bordered}
        condensed={condensed}
        stickyHeader={stickyHeader}
        ellipsisCells
        tableLayout="fixed"
        className={tableClass}
        role="table"
      >
        <Header
          dimensionColumns={dimensionColumns}
          valueColumns={valueColumns}
          showSparklines={showSparklines && results.length > 0 && results.some((row) => row.hasRawData)}
          showActionsMenu={showActionsMenu}
          actions={actions}
          allowSelection={allowSelection}
          onColumnClick={this.handleColumnClick}
          sortState={queryResultsCollection.sortState}
        />
        {results.length === 0 && bucket.fullyLoaded && this.renderNoResults(emptyStateColSpan)}
        <tbody>
          {loading &&
            results.length === 0 &&
            placeholders.map((d, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={index}>
                <td colSpan={emptyStateColSpan} className="bp4-skeleton" style={{ borderRadius: 0 }}>
                  Kentik
                </td>
              </tr>
            ))}
          {results.map((row, index) => (
            <Row
              {...this.props}
              key={this.getRowKey(row, dimensionColumns)}
              index={index}
              model={row}
              results={results}
              dimensionColumns={dimensionColumns}
              valueColumns={valueColumns}
              showSparklines={showSparklines && results.length > 0 && results.some((result) => result.hasRawData)}
              shouldAddDeviceColumn={this.shouldAddDeviceColumn(dimensionColumns)}
              mergeOverlayCells={mergeOverlayCells}
            />
          ))}
        </tbody>
      </HTMLTable>
    );
  }
}

export default ResultsTable;
