import Model from 'core/model/Model';
import { isString } from 'lodash';
import { action, computed } from 'mobx';
import $auth from 'app/stores/$auth';
import moment from 'moment';
import { DEFAULT_MODEL } from 'shared/journeys/constants';
import api from 'core/util/api';
import { Flex, Icon, showSuccessToast, Text } from 'core/components';
import { Socket } from 'core/util/index';
import React from 'react';
import PromptToQueryCollection from './PromptToQueryCollection';
import PromptGroupCollection from './PromptGroupCollection';

class JourneyModel extends Model {
  socket;

  get urlRoot() {
    return '/api/ui/journeys';
  }

  get defaults() {
    return {
      title: 'New Journey',

      // Journeys are private by default, but can be promoted to Public (share_level: 'org') by the user
      share_level: 'self',
      description: '',
      promptGroupCollection: [],
      openIds: []
    };
  }

  @computed
  get edate() {
    return new Date(this.get('edate'));
  }

  @computed
  get edateDuration() {
    const now = moment();
    const duration = moment.duration(now.diff(this.edate));
    return duration.humanize();
  }

  @computed
  get canEdit() {
    return this.isActiveUserJourney;
  }

  @computed
  get canDelete() {
    return $auth.isSudoer || this.isActiveUserJourney;
  }

  @computed
  get isActiveUserJourney() {
    return this.get('user_id') === $auth.activeUser.id;
  }

  // Journeys which are not created by the active user, and have the name "New Journey" are hidden
  @computed
  get hidden() {
    const title = this.get('title');
    const userId = this.get('user_id');
    return title === 'New Journey' && userId !== $auth.activeUser.id;
  }

  addPlaceholder(prompt) {
    this.get('queries').add({
      user_id: $auth.getActiveUserProperty('id'),
      user: {
        id: $auth.getActiveUserProperty('id'),
        user_full_name: $auth.getActiveUserProperty('user_full_name'),
        user_email: $auth.getActiveUserProperty('user_email')
      },
      prompt,
      placeholder: true,
      cdate: Date.now()
    });
    this.updatePromptGroups();
  }

  setOpen() {
    const openIds = this.promptGroupCollection.models.filter((m) => m.get('isOpen')).map((m) => m.id);
    this.set({ openIds });
  }

  makeSudo() {
    const metadata = this.get('metadata');
    const sudoMeta = { compare: { models: [DEFAULT_MODEL] } };
    this.set({ metadata: { ...metadata, ...sudoMeta } });
  }

  handleCompletion(model) {
    const completion = model.get('completion');
    if (model.get('app_protocol') === 99) {
      return model.get('query.answer') || '';
    }
    if (isString(completion)) {
      return completion;
    }
    return JSON.stringify(completion);
  }

  addPrompt(prompt) {
    this.setOpen();

    const messages = this.get('queries').models.flatMap((model) => {
      const qMessages = [
        { role: 'user', content: model.get('prompt') },
        { role: 'assistant', content: this.handleCompletion(model) }
      ];
      const csvString = model.get('query_result_csv');
      if (csvString) {
        qMessages.push({
          role: 'assistant',
          content: `Query results:
          ${csvString}`
        });
      }
      return qMessages;
    });

    const { userTimezone } = $auth.activeUser;
    const localTime = moment().format();

    this.addPlaceholder(prompt);

    const payload = {
      prompt,
      userTimezone,
      user: {
        id: $auth.getActiveUserProperty('id'),
        user_full_name: $auth.getActiveUserProperty('user_full_name'),
        user_email: $auth.getActiveUserProperty('user_email')
      },
      localTime,
      messages: [...messages, { role: 'user', content: prompt }],
      metadata: { ...this.get('metadata') } // had to be like this or it gets removed (???!!!)
    };

    return this.save(payload, { toast: false, patch: true, clearSelection: false }).catch((err) => {
      console.error(err);
    });
  }

  groupModelsByKey = (models, key) => {
    if (!key) {
      return models;
    }

    return models.reduce((acc, model) => {
      const keyValue = model.get(key) || model.get('cdate');
      const group = acc.find((g) => g.key === keyValue);
      const id = model?.id;

      if (group) {
        group.models.push(model);
      } else {
        acc.push({ key: keyValue, models: [model], id });
      }

      return acc;
    }, []);
  };

  @computed
  get promptGroupCollection() {
    return this.get('promptGroupCollection');
  }

  getPromptGroups(queryCollection) {
    if (!queryCollection) {
      queryCollection = this.get('queries');
    }
    const grouped = this.groupModelsByKey(queryCollection.models, 'metadata.compare.date');
    const mostRecent = grouped?.at(-1);
    if (mostRecent) {
      // Open most recent query
      mostRecent.isOpen = true;
      // Open queries that were already expanded
      grouped.forEach((group) => {
        const any = group.models.find((m) => this.get('openIds').includes(m.id));
        if (any) {
          group.isOpen = true;
        }
      });
    }
    return new PromptGroupCollection(grouped);
  }

  updatePromptGroups() {
    this.set('promptGroupCollection', this.getPromptGroups());
  }

  get promptToQueryModelIds() {
    const modelIds = new Set();
    this.promptGroupCollection.each((group) =>
      group
        .get('models')
        .filter((m) => !m.get('is_deleted'))
        .forEach((m) => modelIds.add(m.id))
    );
    return Array.from(modelIds);
  }

  restore(options = {}) {
    const { toast = true } = options;
    this.requestStatus = 'updating';
    if (this.isDeleted) {
      return api.put(`${this.urlRoot}/restore/${this.id}`, { body: { model_ids: this.promptToQueryModelIds } }).then(
        action((success) => {
          this.collection.add(this);
          this.select();
          if (toast) {
            showSuccessToast(this.messages.restore);
          }
          return success;
        }),
        action((error) => {
          this.error = { label: 'updating' };
          this.requestStatus = null;
          throw error;
        })
      );
    }

    return false;
  }

  async destroy(options) {
    return super.destroy(options).then(() => {
      if (this.error?.label !== 'destroying') {
        showSuccessToast(this.messages.destroy, {
          action: {
            onClick: () => this.restore(),
            text: (
              <Flex alignItems="center">
                <Icon icon="reset" iconSize={12} />
                <Text ml={1}>Undo</Text>
              </Flex>
            )
          },
          timeout: 8000,
          ignoreLastMessage: true
        });
      }
    });
  }

  deserialize(data) {
    const queryArray = data?.queries || [];
    const collection = new PromptToQueryCollection(queryArray);
    return {
      ...data,
      queries: collection,
      promptGroupCollection: this.getPromptGroups(collection)
    };
  }

  get messages() {
    return {
      create: 'Journey added successfully',
      update: 'Journey updated successfully',
      destroy: 'Journey removed successfully',
      restore: 'Journey restored successfully'
    };
  }

  @computed
  get isDeleted() {
    return !this.collection.get(this.id);
  }

  onReceivePrompts = (prompts) => {
    const queries = this.get('queries');
    prompts.forEach((prompt) => {
      const existing = queries.get(prompt.id);
      if (existing && prompt.edate > existing.get('edate')) {
        existing.set(prompt);
      } else if (!existing) {
        queries.add(prompt);
      }
      this.set('promptGroupCollection', this.getPromptGroups(queries));
    });
  };

  selectAndSubscribe(options) {
    this.collection.selected?.unsubscribe();
    this.subscribe();
    return this.select(options);
  }

  subscribe() {
    this.socket = new Socket({
      outType: 'subscribeJourney',
      inType: 'journeysPrompt',
      frequency: 60,
      delaySend: true,
      listenToBroadcast: true,
      onSuccess: this.onReceivePrompts,
      onError(err) {
        console.warn('Received Journeys Subscription Socket Error', err);
      },
      onReconnect: () => {
        this.socket.setOutType('subscribeJourney');
        this.socket.send({ id: this.id });
      }
    });

    this.socket.send({ id: this.id });
  }

  unsubscribe() {
    this.socket?.setOutType('unsubscribeJourney');
    this.socket?.send({ id: this.id });
  }
}

export default JourneyModel;
