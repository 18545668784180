import React, { useCallback, useState } from 'react';
import { InputGroup } from 'core/form';
import { Button } from 'core/components';
import Icon from 'core/components/Icon';
import { MdKeyboardCapslock } from 'react-icons/md';

const PasswordInput = ({ showCapsLock, showVisibleToggle, field, jumbo, ...props }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [capsLock, setCapsLock] = useState(false);

  const onPasswordVisibleClick = useCallback(() => {
    setPasswordVisible(!passwordVisible);
  }, [passwordVisible]);

  const onKeyDown = useCallback(
    (e) => {
      if (
        !field.dirty &&
        !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) &&
        (e.key === 'Backspace' || e.key === 'Clear')
      ) {
        e.stopPropagation();
        e.preventDefault();
        field.setValue('');
      }
      if (showCapsLock) {
        setCapsLock(e.getModifierState('CapsLock'));
      }
    },
    [field, showCapsLock]
  );

  // Chrome treats disabling Caps Lock as solely a KeyUp event
  const onKeyUp = useCallback(
    (e) => {
      if (showCapsLock) {
        setCapsLock(e.getModifierState('CapsLock'));
      }
    },
    [showCapsLock]
  );

  const finalProps = {
    field,
    onKeyUp,
    onKeyDown,
    type: 'password',
    ...props
  };

  if (jumbo && !props.className) {
    finalProps.className = 'jumbo';
  }

  if (showVisibleToggle) {
    finalProps.rightElement = (
      <Button
        {...(jumbo ? { height: '40px', width: '40px' } : { small: true })}
        minimal
        icon={passwordVisible ? 'eye-open' : 'eye-off'}
        onClick={() => onPasswordVisibleClick()}
      />
    );
    if (passwordVisible) {
      finalProps.type = 'text';
    }
  }

  if (showCapsLock && capsLock) {
    finalProps.leftElement = <Icon icon={MdKeyboardCapslock} />;
  }

  return <InputGroup {...finalProps} />;
};

export default PasswordInput;
