import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  formConsumer,
  Field,
  InputGroup,
  PasswordInput,
  Switch,
  Select,
  CheckboxGroup,
  FormGroup,
  ControlGroup
} from 'core/form';
import { Box, CalloutOutline, Flex, Text, Link, Heading, Grid, Icon, Tooltip } from 'core/components';
import {
  KentikAgentOptionRenderer,
  CredentialOptionRenderer,
  CredentialValueRenderer
} from 'app/views/setup/tasks/nms/optionRenderers';
import SetupCredentials from 'app/views/setup/tasks/nms/SetupCredentials';
import { Statuses } from 'shared/agents/constants';
import MonitoringTemplateSelector from 'app/views/metrics/components/MonitoringTemplateSelect';

const credentialTypes = ['snmp_v1', 'snmp_v2c', 'snmp_v3'];

export const collectionMethodRenderer = ({ label, helpText, tooltip }) => (
  <Flex flexDirection="column" lineHeight="20px" mt="-1px">
    <Flex gap={1} alignItems="center">
      <Text ellipsis title={label} fontWeight="medium">
        {label}
      </Text>
      {tooltip && (
        <Tooltip content={tooltip} position="right">
          <Icon icon="info-sign" color="muted" />
        </Tooltip>
      )}
    </Flex>
    {helpText && <Text muted>{helpText}</Text>}
  </Flex>
);

@formConsumer
@inject('$auth', '$devices', '$dictionary', '$credentials', '$kentikAgents', '$setup')
@observer
class IpSnmpSettings extends Component {
  state = {
    isAddingCredentialDialogOpen: false,
    credentialModel: null
  };

  constructor(props) {
    super(props);
    const { form, $auth, $kentikAgents } = this.props;

    // Hide the kentik_agent option if user doesn't have recon.enabled permission
    if (!$auth.hasPermission('recon.enabled', { overrideForSudo: false })) {
      const collectionField = form.getField('snmp_collection_method');
      collectionField.options = collectionField.options.filter((option) => option.value !== 'kentik_agent');
      form.validate();
    }

    // clear fields that have invalid values due to the referenced object getting deleted

    const credential_name = form.getValue('kentik_agent_snmp_options.credential_name');
    if (credential_name && !this.credentialOptions.find(({ value }) => value === credential_name)) {
      form.setValue('kentik_agent_snmp_options.credential_name', '');
    }

    const agent_id = form.getValue('kentik_agent_snmp_options.agent_id');
    if (agent_id && !$kentikAgents.authorizedKentikAgentOptions.find(({ value }) => value === agent_id)) {
      form.setValue('kentik_agent_snmp_options.agent_id', '');
    }
  }

  componentDidUpdate() {
    this.updateRules();
  }

  get credentialOptions() {
    const { $auth, $credentials } = this.props;
    const credentialOptions = $credentials.getCredentialOptionsByTypes(credentialTypes);
    if ($auth.hasRbacPermissions(['credential_vault::create'])) {
      credentialOptions.unshift({ value: '$NEW', label: 'New Credential', icon: 'plus', intent: 'primary' });
    }
    return credentialOptions;
  }

  get isAgentNotRunning() {
    const { $kentikAgents, form } = this.props;
    const agent_id = form.getValue('kentik_agent_snmp_options.agent_id');
    return agent_id && $kentikAgents.collection.find({ agent_id })?.get('status') !== Statuses.UP;
  }

  updateRules = () => {
    const { form } = this.props;
    const renderSnmpV3 = form.getValue('device_snmp_v3_conf_enabled');
    const fieldState = form.getField('device_snmp_v3_conf.UserName');
    let rules = '';
    if (renderSnmpV3) {
      rules = 'required';
    }
    form.setRules(fieldState, rules);
  };

  /**
   * @param field
   * @param {string[]} value
   */
  handleCollectionMethodChange = (field, value) => {
    const { form, $setup } = this.props;
    if (value.includes('kentik_agent') && !form.getValue('plan.id')) {
      // set to metrics plan
      form.setValue('plan.id', $setup.plans.find(({ active, plan_type }) => active && plan_type === 'metrics')?.id);
    }
  };

  handleCredentialChange = (field, value) => {
    const { $credentials } = this.props;
    // open the new credential dialog
    if (value === '$NEW') {
      field.setValue('');
      this.setState({
        isAddingCredentialDialogOpen: true,
        credentialModel: $credentials.groupedCollection.forge()
      });
    }
  };

  handleCloseCredentialsDialog = (formValues) => {
    const { form } = this.props;

    if (formValues) {
      const { credentialName } = formValues;
      form.setValue('kentik_agent_snmp_options.credential_name', credentialName);
    }

    this.setState({ isAddingCredentialDialogOpen: false, credentialModel: null });
  };

  render() {
    const { $auth, $devices, $dictionary, $kentikAgents, form, role } = this.props;
    const { isAddingCredentialDialogOpen, credentialModel } = this.state;
    const { device_snmp_v3_conf_enabled, device_subtype, snmp_collection_method, device_snmp_v3_conf } =
      form.getValues();
    const { snmpPollingIps } = $devices.routerSettings;
    const kbLink = $dictionary.getHelpUrl('admin', 'configuringSNMP');
    const deviceType = $devices.getDeviceTypeFromSubtype(device_subtype);

    const iskProxyCollectionEnabled = snmp_collection_method?.includes('kproxy');
    const isKentikAgentCollectionEnabled = snmp_collection_method?.includes('kentik_agent');

    const ariaProps = {};
    if (role) {
      ariaProps.role = role;
    }
    return (
      <Flex flexDirection="column" gap={2} overflow="auto" p={2} {...ariaProps}>
        <Heading level={5} pb="6px" mb={0} borderBottom="thin">
          Collection method
        </Heading>

        <Field name="snmp_collection_method" onChange={this.handleCollectionMethodChange} showLabel={false} m={0}>
          <CheckboxGroup
            containerProps={{ flexDirection: 'column', gap: '8px' }}
            itemRenderer={collectionMethodRenderer}
          />
        </Field>

        {isKentikAgentCollectionEnabled && (
          <>
            <Heading level={5} pb="6px" mb={0} borderBottom="thin">
              For NMS via Universal Agent
            </Heading>

            {this.isAgentNotRunning && (
              <CalloutOutline intent="danger">
                <Flex gap={1} alignItems="center">
                  <Icon icon="error" color="danger" />
                  <Text>This agent isn’t currently running, and won’t be able to receive updates.</Text>
                </Flex>
              </CalloutOutline>
            )}

            <Flex gap={2}>
              <Flex flexDirection="column" gap={1} flex={1}>
                <Field
                  disabled={!$auth.hasRbacPermissions(['credential_vault::read'])}
                  m={0}
                  flex={1}
                  onChange={this.handleCredentialChange}
                  name="kentik_agent_snmp_options.credential_name"
                  options={this.credentialOptions}
                >
                  <Select fill optionRenderer={CredentialOptionRenderer} valueRenderer={CredentialValueRenderer} />
                </Field>
              </Flex>

              <Flex flexDirection="column" gap={1} flex={1}>
                <Field
                  m={0}
                  flex={1}
                  name="kentik_agent_snmp_options.agent_id"
                  options={$kentikAgents.authorizedKentikAgentOptions}
                  disabled={false}
                >
                  <Select fill optionRenderer={KentikAgentOptionRenderer} />
                </Field>
              </Flex>

              <Flex flex={1}>
                <Field m={0} name="monitoring_template_id">
                  <MonitoringTemplateSelector />
                </Field>
              </Flex>
            </Flex>

            <Flex gap={2}>
              <Field m={0} name="kentik_agent_snmp_options.ranger_snmp_ip" flex={1}>
                <InputGroup fill />
              </Field>

              <Field m={0} name="kentik_agent_snmp_options.port" placeholder="161">
                <InputGroup width={160} />
              </Field>

              <Field m={0} name="kentik_agent_snmp_options.timeout">
                <InputGroup width={160} />
              </Field>
            </Flex>
          </>
        )}

        {iskProxyCollectionEnabled && (
          <>
            <Heading level={5} pb="6px" mb={0} borderBottom="thin">
              For Flow Enrichment
            </Heading>

            <CalloutOutline intent="warning" p={2}>
              <Flex flexDirection="column" gap="12px">
                <Text>
                  Allow us to SNMP poll your router from these IPs using the Community supplied in the device
                  configuration.
                </Text>
                <Flex ml={2}>
                  <Box width={220}>Kentik SNMP polling IPs:</Box>
                  <Text as="div" monospace fontWeight="bold">
                    {snmpPollingIps}
                  </Text>
                </Flex>
                <Link to={kbLink} blank>
                  Need help configuring SNMP polling?
                </Link>
              </Flex>
            </CalloutOutline>

            <Field
              m={0}
              name="minimize_snmp"
              inputStyle={{ width: 160 }}
              rules={deviceType === 'router' ? 'required' : []}
            >
              <Select />
            </Field>

            <Flex gap={2}>
              <Field m={0} name="device_snmp_ip" flex={1}>
                <InputGroup fill />
              </Field>

              {!device_snmp_v3_conf_enabled && (
                <Field m={0} name="device_snmp_community" flex={1}>
                  <PasswordInput fill autoComplete="new-password" />
                </Field>
              )}
            </Flex>

            <Flex flexDirection="column" gap={2}>
              <Field m={0} name="device_snmp_v3_conf_enabled" showLabel={false} onChange={this.updateRules}>
                <Switch switchLabel="Enable SNMP v3 Authentication" />
              </Field>

              {device_snmp_v3_conf_enabled && (
                <Flex flexDirection="column" gap={2}>
                  <Field m={0} name="device_snmp_v3_conf.UserName">
                    <InputGroup width={400} />
                  </Field>

                  <Grid>
                    <FormGroup label="Authentication" fontSize="small" mb={0}>
                      <ControlGroup>
                        <Field
                          large
                          name="device_snmp_v3_conf.AuthenticationProtocol"
                          showLabel={false}
                          onChange={this.updateRules}
                        >
                          <Select menuWidth={100} />
                        </Field>
                        {!(device_snmp_v3_conf.AuthenticationProtocol === 'NoAuth') && (
                          <Field
                            large
                            name="device_snmp_v3_conf.AuthenticationPassphrase"
                            showLabel={false}
                            placeholder="Passphrase"
                            flex={1}
                          >
                            <PasswordInput leftIcon="lock" />
                          </Field>
                        )}
                      </ControlGroup>
                    </FormGroup>

                    <FormGroup label="Privacy" mb={0}>
                      <ControlGroup>
                        <Field
                          large
                          name="device_snmp_v3_conf.PrivacyProtocol"
                          showLabel={false}
                          onChange={this.updateRules}
                        >
                          <Select menuWidth={100} />
                        </Field>
                        {!(device_snmp_v3_conf.PrivacyProtocol === 'NoPriv') && (
                          <Field
                            large
                            name="device_snmp_v3_conf.PrivacyPassphrase"
                            showLabel={false}
                            placeholder="Passphrase"
                            flex={1}
                          >
                            <PasswordInput leftIcon="lock" />
                          </Field>
                        )}
                      </ControlGroup>
                    </FormGroup>
                  </Grid>
                </Flex>
              )}
            </Flex>
          </>
        )}
        {credentialModel && (
          <SetupCredentials
            isOpen={isAddingCredentialDialogOpen}
            model={credentialModel}
            onClose={this.handleCloseCredentialsDialog}
            // only SNMP credential types are valid here
            types={credentialTypes}
            title="Add SNMP Credential"
          />
        )}
      </Flex>
    );
  }
}

export default IpSnmpSettings;
