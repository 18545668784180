import React, { Component } from 'react';
import { toJS } from 'mobx';

import Box from 'core/components/Box';
import Checkbox from 'core/components/Checkbox';
import EmptyState from 'core/components/EmptyState';
import Flex from 'core/components/Flex';
import Text from 'core/components/Text';

const selectAllOrNone = (onChange, options, select) => {
  onChange(select ? options.map(({ value }) => value) : []);
};

export default class CheckboxGroup extends Component {
  handleChange = (value) => {
    const { field, onChange } = this.props;
    const newValues = toJS(field.value) || [];
    const indexOfValue = newValues.indexOf(value);

    if (indexOfValue === -1) {
      newValues.push(value);
    } else {
      newValues.splice(indexOfValue, 1);
    }

    onChange(toJS(newValues));
  };

  renderItem = ({ label, helpText, value, ...rest }) => {
    const { itemRenderer, wrapLabel } = this.props;

    if (itemRenderer) {
      return itemRenderer({ label, helpText, value, wrapLabel, ...rest });
    }

    return (
      <span>
        {label}
        {helpText && (
          <Text as="div" muted>
            {helpText}
          </Text>
        )}
      </span>
    );
  };

  render() {
    const {
      containerProps,
      selectAllContainerProps,
      field,
      options,
      disabled,
      small,
      large,
      inline,
      selectAll,
      onChange,
      labelId,
      helperTextId
    } = this.props;
    const checkboxOptions = options || field.options;
    const values = field.value || [];

    if (!checkboxOptions.length) {
      return <EmptyState description="There are no available options" />;
    }

    const ariaProps = {};
    if (labelId) {
      ariaProps['aria-labelledby'] = labelId;
    }
    if (helperTextId) {
      ariaProps['aria-describedby'] = helperTextId;
    }

    return (
      <Flex
        {...ariaProps}
        flexDirection={inline ? 'row' : 'column'}
        flexWrap="wrap"
        gap="0 16px"
        alignItems="flex-start"
        role="group"
        {...containerProps}
      >
        {checkboxOptions.length > 1 && selectAll && (
          <Box mb={2} {...selectAllContainerProps}>
            <Checkbox
              key={`${field.id || field.name}-select-all`}
              label="Select All"
              ariaLabel="Select All"
              indeterminate={values.length > 0 && values.length < checkboxOptions.length}
              checked={values.length === checkboxOptions.length}
              onChange={() => selectAllOrNone(onChange, checkboxOptions, values.length === 0)}
              small={small}
              large={large}
              mb={small ? '2px' : '6px'}
            />
          </Box>
        )}
        {checkboxOptions.map(({ helpText, label, value, style, disabled: optionDisabled, ...rest }) => (
          <Checkbox
            ariaLabel={label}
            checked={values.includes(value)}
            disabled={optionDisabled || disabled}
            key={`${field.id || field.name}-${value}-disabled-${+optionDisabled}`}
            labelElement={this.renderItem({ helpText, label, value, ...rest })}
            onChange={() => this.handleChange(value)}
            small={small}
            large={large}
            mb={small ? '2px' : '6px'}
            style={style}
          />
        ))}
      </Flex>
    );
  }
}
