import React, { Component } from 'react';
import { action, reaction } from 'mobx';
import { isEqual } from 'lodash';
import { withTheme } from 'styled-components';
import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';
import { Flex, Icon, Tooltip } from 'core/components';
import { FieldLabel } from 'core/form/components/FieldLabel';
import FormGroup from './FormGroup';
import formConsumer from '../formConsumer';

function updateFieldStateWithProps(field, props) {
  if (field) {
    const { rules, placeholder, options, label, validateOptions, disabled, readOnly } = props;

    if (rules !== undefined && field.rules !== rules) {
      field.form.setRules(field, rules);
    }

    if (placeholder !== undefined && field.placeholder !== placeholder) {
      field.placeholder = placeholder;
    }

    if (validateOptions !== undefined && field.validateOptions !== validateOptions) {
      field.validateOptions = validateOptions;
    }

    if (options !== undefined && !isEqual(field.options, options)) {
      field.options = options;
      field.form.validateWithDefer();
    }

    if (label && field.label !== label) {
      field.label = label;
    }

    if (disabled !== undefined && field.disabled !== disabled) {
      field.disabled = disabled;
    }

    if (readOnly !== undefined && field.readOnly !== readOnly) {
      field.readOnly = readOnly;
    }
  }
}

@formConsumer
@withTheme
class Field extends Component {
  state = {};

  static defaultProps = {
    showLabel: true,
    small: true
  };

  @action
  static getDerivedStateFromProps(nextProps) {
    const { form, name } = nextProps;
    const field = nextProps.field || form.getField(name);
    if (!field) {
      console.warn('Field MISSING in getDerivedStateFromProps', field);
    }
    updateFieldStateWithProps(field, nextProps);

    return { field };
  }

  componentDidMount() {
    const { field } = this.state;

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.fieldErrorsDisposer = reaction(
      () => field.errorString,
      (fieldErrors) => {
        this.setState({ fieldErrors });
      }
    );

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.valueDisposer = reaction(
      () => field.value,
      (value) => {
        this.setState({ value });
      }
    );

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.validDisposer = reaction(
      () => field.valid,
      (valid) => this.setState({ valid })
    );

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.forceUpdateDisposer = reaction(
      () => field.forceUpdate,
      (forceUpdate) => this.setState({ forceUpdate })
    );
  }

  /**
   * This is legacy behavior, only used when the `unsafe` prop is set. Field is inexpensive to render and use of
   * shouldComponentUpdate here causes subtle bugs and unintuitive behavior. The benefit of setting `unsafe` is that it
   * decouples your Field state from FormState.js
   *
   * When might `unsafe` be desirable?
   * 1. You are setting FormState after a delay - see code examples.
   * 2. You are relying on some other subtlety of `shouldComponentUpdate()`. I hope there's no other benefit but ¯\\\_(ツ)\_/¯.
   *
   * Example `unsafe` code:
   * ```
   * handleUpdate(value) {
   *   setTimeout(() => this.updateState({value}), 250);
   * };
   * ```
   *
   * How to achieve a similar effect without relying on `unsafe`:
   * ```
   * handleUpdate(value) {
   *   this.updateState({value});
   *   setTimeout(() => this.updateState({indirectlyConsumedValue}), 250);
   * };
   * ```
   */
  shouldComponentUpdate(nextProps, nextState) {
    const {
      field,
      rules,
      className,
      options,
      validateOptions,
      label,
      isEditing,
      onQuery,
      disabled,
      loading,
      isOpen,
      mb,
      showLabel,
      helpText,
      tooltip,
      placeholder,
      subLabel,
      theme,
      unsafe
    } = this.props;

    if (!unsafe) {
      return true;
    }

    const { fieldErrors, value, valid, forceUpdate } = this.state;

    const hasFieldChanged = nextProps.field !== field; // on occasion, the field itself changes too
    const hasRulesChanged = nextProps.rules !== rules;
    const hasClassNameChanged = nextProps.className !== className;
    const hasOptionsChanged = nextProps.options !== options;
    const hasValidateOptionsChanged = nextProps.validateOptions !== validateOptions;
    const hasLabelChanged = nextProps.label !== label;
    const hasEditingChanged = nextProps.isEditing !== isEditing;
    const hasErrorsChanged = nextState.fieldErrors !== fieldErrors;
    const hasValueChanged = nextState.value !== value;
    const hasValidChanged = nextState.valid !== valid;
    const hasOnQueryChanged = nextState.onQuery !== onQuery;
    const hasDisabledChanged = nextProps.disabled !== disabled;
    const hasLoadingChanged = nextProps.loading !== loading;
    const hasIsOpenChanged = nextProps.isOpen !== isOpen;
    const hasMarginBottomChanged = nextProps.mb !== mb;
    const hasShowLabelChanged = nextProps.showLabel !== showLabel;
    const hasHelpTextChanged = nextProps.helpText !== helpText;
    const hasTooltipChanged = nextProps.tooltip !== tooltip;
    const hasPlaceholderChanged = nextProps.placeholder !== placeholder;
    const hasSubLabelChanged = nextProps.subLabel !== subLabel;
    const hasThemeChanged = nextProps.theme.name !== theme.name;
    const hasForcedUpdate = nextState.forceUpdate !== forceUpdate;

    return (
      hasFieldChanged ||
      hasValueChanged ||
      hasRulesChanged ||
      hasErrorsChanged ||
      hasValidChanged ||
      hasClassNameChanged ||
      hasOptionsChanged ||
      hasValidateOptionsChanged ||
      hasLabelChanged ||
      hasEditingChanged ||
      hasOnQueryChanged ||
      hasDisabledChanged ||
      hasLoadingChanged ||
      hasIsOpenChanged ||
      hasMarginBottomChanged ||
      hasShowLabelChanged ||
      hasHelpTextChanged ||
      hasTooltipChanged ||
      hasPlaceholderChanged ||
      hasSubLabelChanged ||
      hasThemeChanged ||
      hasForcedUpdate
    );
  }

  componentWillUnmount() {
    ['fieldErrorsDisposer', 'validDisposer', 'valueDisposer', 'forceUpdateDisposer'].forEach(
      (fn) => this[fn] && this[fn]()
    );
  }

  handleChange = (e) => {
    const { form, onChange, beforeOnChange } = this.props;
    const { field } = this.state;
    let interruptChange = false;

    if (e && e.stopPropagation) {
      e.stopPropagation(); // need to prevent bubbling!
    }

    const previousValue = field.getValue();

    if (beforeOnChange) {
      // we can potentially short-circuit the change from happening, if beforeOnChange passed in tells us so
      interruptChange = beforeOnChange(field, field?.value, previousValue, form, e.target?.value ?? e);
    }

    if (!interruptChange) {
      field.onChange(e);

      if (onChange) {
        onChange(field, field.value, previousValue, form);
      }
    }
  };

  handleKeyPress = (e) => {
    const { onEnterKey } = this.props;
    const { field } = this.state;

    if (e && e.key === 'Enter' && onEnterKey) {
      onEnterKey(field);
    }
  };

  render() {
    const {
      form,
      render,
      component,
      autoFocus,
      children,
      options,
      onChange,
      intent,
      isEditing,
      toggleEditing,
      showLabel,
      labelInfo,
      labelProps,
      small,
      large,
      helpText: helpTextProp,
      tooltip,
      fieldStyle,
      onQuery,
      showRequired,
      showError: showErrorProp,
      loading,
      isOpen,
      className,
      theme,
      subLabel: subLabelProp,
      ...formGroupProps
    } = this.props;

    const { field } = this.state;
    if (!field) {
      console.error('Field MISSING in render', field);
      // we might be better off returning null istead of the component crashing when the field is missing but I am not sure of the side effects.
      // return null;
    }
    const {
      name,
      label,
      placeholder,
      value,
      disabled,
      helpText,
      errors,
      hasError,
      showPristineErrors,
      pristine,
      readOnly,
      rules,
      subLabel
    } = field;

    const showError = hasError && (showPristineErrors || !pristine);
    const smallField = form.options.large ? false : small && !large;
    const labelId = `label-${field._id}`;
    const helperTextId = `helperText-${field._id}`;

    const isRequired = rules.toString().includes('required');
    const showRequiredLabel = showRequired === undefined ? isRequired : showRequired;

    let fieldProps = {
      autoFocus,
      disabled,
      form,
      field,
      helperTextId,
      id: field._id, // note: name isn't guaranteed unique across all forms
      intent: intent || (showError ? 'danger' : undefined),
      onChange: this.handleChange,
      onKeyPress: this.handleKeyPress,
      isEditing,
      onEditComplete: toggleEditing,
      name,
      options,
      placeholder,
      value,
      small: smallField,
      large: !smallField,
      onQuery,
      loading,
      isOpen,
      readOnly,
      isRequired
    };

    if (showLabel) {
      fieldProps.labelId = labelId;
    }

    // allow more generic usage of `Field`
    if (typeof children === 'function') {
      return children({
        ...fieldProps
      });
    }

    const possibleError = (
      <span id={helperTextId} role="alert" aria-live="assertive">
        {errors.map((error) => (
          <span key={error}>{error}</span>
        ))}
      </span>
    );
    const possibleHelperText = (helpTextProp || helpText) && (
      <span id={helperTextId} aria-live="assertive">
        {helpTextProp || helpText}
      </span>
    );

    const helperText = showErrorProp !== false && showError ? possibleError : possibleHelperText;

    if (render) {
      return render({ ...fieldProps });
    }

    let InputComponent;

    if (component) {
      InputComponent = component;
    }

    const formGroupAriaProps = {};
    if (fieldProps.disabled) {
      formGroupAriaProps['aria-disabled'] = true;
    }
    formGroupAriaProps['aria-required'] = isRequired;
    formGroupAriaProps.ariaRequired = isRequired;

    fieldProps = { ...fieldProps, ...formGroupAriaProps };

    // otherwise, the old standard `children` method still works.
    return (
      <FormGroup
        {...formGroupAriaProps}
        labelFor={fieldProps.id}
        labelId={labelId}
        labelText={label}
        helperText={helperText}
        tooltip={tooltip}
        intent={showError ? 'danger' : 'none'}
        labelInfo={
          <Flex display="inline-flex" alignItems="center">
            {labelInfo === undefined ? showRequiredLabel && '*' : labelInfo}
            {tooltip && (
              <Tooltip content={tooltip}>
                <Icon iconSize={14} icon="info-sign" color={theme.colors.gray3} mt={0} ml={1} />
              </Tooltip>
            )}
          </Flex>
        }
        style={fieldStyle}
        subLabel={subLabelProp || subLabel}
        {...formGroupProps}
        className={classNames({ [Classes.SMALL]: smallField }, className)}
        label={
          showLabel && label ? (
            <FieldLabel
              labelId={labelId}
              smallField={smallField}
              showError={showError}
              inline={formGroupProps.inline}
              {...labelProps}
            >
              {label}
            </FieldLabel>
          ) : null
        }
      >
        {children && React.cloneElement(children, fieldProps)}
        {component && <InputComponent {...fieldProps} />}
      </FormGroup>
    );
  }
}

export default Field;
