import React, { Component } from 'react';

import { displayRawBaselinePercentValue } from 'app/stores/alerting/policyUtils';
import {
  COMPARISON_DIRECTIONS,
  THRESHOLD_CONDITION_TYPES,
  OPERATOR_SHORT_LABELS
} from '@kentik/ui-shared/alerting/constants';
import { Box, Callout, Flex, Icon, Heading, Grid, Text } from 'core/components';
import { inject, observer } from 'mobx-react';

const Condition = ({ threshold, model }) => {
  const { direction, operator, formattedMetric, formatted, rawComparisonValue, type, ratio } = threshold;
  const valueText = direction === COMPARISON_DIRECTIONS.TO_HISTORY ? `${formattedMetric} value` : 'Baseline value';
  const otherValueText = direction === COMPARISON_DIRECTIONS.TO_HISTORY ? 'baseline' : 'current value';
  const operatorLabel = OPERATOR_SHORT_LABELS[operator];
  const overText = threshold.over ? 'above' : 'below';

  const defaultValueRender = (
    <Box>
      <Text fontWeight="bold">{threshold.formattedOther}</Text>
      {threshold.percentage < 10000 && (
        <Text as="div" muted>
          {threshold.percentage}% {overText} {model.severity.toLowerCase()} threshold
        </Text>
      )}
    </Box>
  );

  const types = {
    [THRESHOLD_CONDITION_TYPES.STATIC]: [
      <Text key={0}>
        <strong>{valueText}</strong>{' '}
        <strong>
          {operatorLabel} {formatted}
        </strong>
      </Text>,
      defaultValueRender
    ],

    [THRESHOLD_CONDITION_TYPES.CAPACITY]: [
      <Text key={0}>
        <strong>{valueText}</strong> within <strong>{rawComparisonValue} Mbits/s</strong> of interface capacity (
        <strong>
          {operatorLabel} {formatted}
        </strong>
        )
      </Text>,
      defaultValueRender
    ],

    [THRESHOLD_CONDITION_TYPES.CAPACITY_PERCENT]: [
      <Text key={0}>
        <strong>{valueText}</strong> {operatorLabel} <strong>{rawComparisonValue}%</strong> of interface capacity (
        <strong>
          {operatorLabel} {formatted}
        </strong>
        )
      </Text>,
      defaultValueRender
    ],

    [THRESHOLD_CONDITION_TYPES.TOP_KEYS]: [
      <Text key={0}>
        <strong>{valueText}</strong> is not in the top keys but {otherValueText} value is
      </Text>
    ],

    [THRESHOLD_CONDITION_TYPES.BASELINE]: [
      <Text key={0}>
        <strong>{valueText}</strong> {operatorLabel} <strong>{rawComparisonValue} Mbit/sec</strong> above{' '}
        {otherValueText} (
        <strong>
          {operatorLabel} {formatted}
        </strong>
      </Text>,
      defaultValueRender
    ],

    [THRESHOLD_CONDITION_TYPES.BASELINE_PERCENT]: [
      <Text key={0}>
        <strong>
          {valueText} {operatorLabel} {displayRawBaselinePercentValue(rawComparisonValue, direction)}
        </strong>{' '}
        ({operatorLabel} {formatted})
      </Text>,
      <Box key={1}>
        <Text fontWeight="bold">
          {threshold.percentage}% {overText} {model.severity.toLowerCase()} threshold
        </Text>
        <Text as="div">({threshold.formattedOther})</Text>
      </Box>
    ],

    [THRESHOLD_CONDITION_TYPES.RATIO]: [
      <Text key={0}>
        Ratio of <strong>{ratio.lhMetric}</strong> to <strong>{ratio.rhMetric}</strong> exceeds{' '}
        <strong>{ratio.alarmCondition}</strong>
      </Text>,
      <Text key={1}>
        <strong>{ratio.actualValue}</strong>
      </Text>
    ]
  };

  const content = types[type];

  return (
    <>
      <Box borderBottom="thin" pb="4px">
        <Text small as="div">
          {content[0]}
        </Text>
      </Box>
      <Box borderBottom="thin" pb="4px" pl={1}>
        <Text small as="div">
          {content[1]}
        </Text>
      </Box>
    </>
  );
};

@inject('$alerting')
@observer
class AlertWhy extends Component {
  render() {
    const { $alerting, model, showHeading = true, className, ...props } = this.props;

    const thresholds = $alerting.getThresholds(model);

    return (
      <Box className={className} {...props}>
        {showHeading && (
          <Heading level={5} fontWeight={700}>
            Why Was This Triggered?
          </Heading>
        )}

        {model.policyChanged && (
          <Callout intent="warning" p="12px" my={1}>
            <Flex gap={1}>
              <Icon icon="warning-sign" color="warning" />
              <Text as="div" fontSize={13} lineHeight="auto">
                This policy has been changed. The threshold may have been modified since this alarm started.
              </Text>
            </Flex>
          </Callout>
        )}

        <Text small>The following conditions were met:</Text>

        <Grid gridTemplateColumns="1fr 1fr" gridColumnGap={0} gridRowGap="4px" small mt={1}>
          <Box borderBottom="thin" pb="4px">
            <Text fontWeight="bold" small>
              Condition
            </Text>
          </Box>
          <Box borderBottom="thin" pb="4px" pl={1}>
            <Text fontWeight="bold" small>
              Value
            </Text>
          </Box>
          {(thresholds || []).map((threshold, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Condition key={idx} threshold={threshold} model={model} />
          ))}
          {model.alarmTriggerText && (
            <Box gridColumn="1 / span 2">
              <Text small muted>
                {model.alarmTriggerText}
              </Text>
            </Box>
          )}
        </Grid>
      </Box>
    );
  }
}

export default AlertWhy;
