import React from 'react';

import { withRouter } from 'react-router-dom';
import AnchorButton from './AnchorButton';

/**
 * @param {string} [to] typically should be provided, but you are permitted to replace this with onClick
 * @param {History} [history]
 * @param {boolean} blank=false
 * @param {object} [state]
 * @param [match] not used
 * @param {History.prototype.location} [location]
 * @param {boolean} keepSearch=false
 * @param {() => void} [onClick] overrides standard href behavior. This should still perform page navigation for the HTML to be semantically correct!
 * @param {object} [rest]
 * @param {boolean} rest.small=false
 * @param {boolean} rest.minimal=false
 * @param {boolean} rest.disabled=false
 * @param {boolean} rest.fill=false
 * @param {string} [rest.text]
 * @param {string} [rest.target]
 * @param {import('react').ReactNode | string} [rest.icon]
 * @param {number} [rest.iconSize]
 * @param {string} [rest.intent]
 * @returns {JSX.Element}
 * @constructor
 */
function LinkButton({ to, history, blank, state, match, location, keepSearch, onClick, ...rest }) {
  if (!onClick) {
    onClick = (e) => {
      if (history?.location?.search && keepSearch) {
        to += history.location.search;
      }

      if (e.button === 0 && !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) && !rest.target) {
        e.preventDefault();

        if (blank) {
          const url = (to || '').startsWith('http') ? to : `${window.location.origin}${to}`;
          window.open(url, '_blank');
        } else {
          history.push(to, Object.assign(state ?? {}, { from: location?.pathname ?? '' }));
        }
      }
    };
  }

  const buttonProps = {};
  if (to && !onClick) {
    buttonProps.href = to;
  }

  return <AnchorButton {...buttonProps} onClick={onClick} {...rest} />;
}

export default withRouter(LinkButton);
