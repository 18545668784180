import React, { Component } from 'react';
import moment from 'moment';
import { inject } from 'mobx-react';
import { ConsumptionBar, Flex, Box, Icon, LinkButton, Text, Tooltip } from 'core/components';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { numberWithCommas } from 'app/util/utils';
import { adjustByGreekPrefix, greekPrefix, toDecimal } from 'core/util';

@inject('$capacity')
export default class PlanInterfaceSeverity extends Component {
  render() {
    const { $capacity, plan, small, loading, ...rest } = this.props;
    const { id, utilizationSeverity, runoutSeverity } = plan;
    const { bps, runout, util, interfaces: interfacesCount, thresholds } = plan.get();

    const totalIn = Object.values(bps.in).reduce((a, b) => a + b, 0);
    const totalOut = Object.values(bps.out).reduce((a, b) => a + b, 0);
    const directionBps = Math.max(totalIn, totalOut) === totalIn ? bps.in : bps.out;
    const totalPercentUtil =
      ((directionBps.healthy + directionBps.warning + directionBps.critical) / bps.capacity) * 100 || 0;

    const { highest } = util;
    const { earliest } = runout;
    const highestUtilization = Number.isFinite(highest) ? `${highest.toFixed(2)}%` : 'N/A';
    const earliestRunout =
      earliest && !moment(earliest).isAfter(moment().add(1, 'year'))
        ? moment(earliest).format('MM/DD/YYYY')
        : 'Over a year';
    const earliestRunoutText = earliest ? earliestRunout : 'N/A';

    const totalInterfaceCount = numberWithCommas(interfacesCount.total);
    const criticalInterfacesCount = numberWithCommas(interfacesCount.critical);
    const warningInterfacesCount = numberWithCommas(interfacesCount.warning);
    const healthyInterfacesCount = numberWithCommas(
      interfacesCount.total - (interfacesCount.critical + interfacesCount.warning)
    );
    const hasInterfaces = totalInterfaceCount !== 0;

    const runoutCriticalCount = runout.critical;
    const runoutWarningCount = runout.warning;
    const runoutStrategy = thresholds.runout.strategy === 'monthOverMonth' ? 'MoM' : 'WoW';
    const runoutTimeUnit = thresholds.runout.strategy === 'monthOverMonth' ? 'm' : 'w';

    const utilizationCriticalCount = util.critical;
    const utilizationWarningCount = util.warning;

    const capacityGreek = greekPrefix([bps.capacity]);
    const capacityFormatted = `${toDecimal(adjustByGreekPrefix(bps.capacity, capacityGreek))}`;
    const capacityGreekFormatted = `${capacityGreek}bits/s`;

    if (small) {
      return (
        <>
          <Box mx={-1} mt={-1} mb={1}>
            <ConsumptionBar
              bars={[
                {
                  key: 'critical',
                  flex: directionBps.critical,
                  minWidth: directionBps.critical > 0 ? '4px' : 0,
                  bg: 'danger'
                },
                {
                  key: 'warning',
                  flex: directionBps.warning,
                  minWidth: directionBps.warning > 0 ? '4px' : 0,
                  bg: 'warning'
                },
                { key: 'success', flex: directionBps.healthy, bg: 'success' }
              ]}
              innerWidth={`${Math.min(Math.ceil(totalPercentUtil), 100)}%`}
              mx={1}
            />
          </Box>
          <Flex alignItems={hasInterfaces ? 'baseline' : 'normal'}>
            <Flex
              justifyContent="space-between"
              flexDirection="column"
              height="90%"
              width="50%"
              borderRight={hasInterfaces ? 'thin' : undefined}
              pr={1}
            >
              <Flex alignItems="baseline" justifyContent="space-between">
                <Text as="div" muted small>
                  Interfaces
                </Text>
                <Text fontSize={18} fontWeight="heavy">
                  {totalInterfaceCount}
                </Text>
              </Flex>

              <Flex alignItems="baseline" justifyContent="space-between">
                <Text as="div" muted small>
                  Total Capacity
                </Text>
                <Flex alignItems="baseline">
                  <Text as="div" fontSize={18} fontWeight="heavy">
                    {capacityFormatted}
                  </Text>
                  <Text small muted ml="4px">
                    {capacityGreekFormatted}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" flexDirection="column" height="100%" width="50%" ml={1}>
              {hasInterfaces ? (
                <>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center" justifyContent="space-between">
                      <Icon
                        mr={1}
                        icon={runoutSeverity.icon}
                        iconSize={16}
                        color={runoutSeverity.color}
                        id={`plan-${id}-runoutSeverity`}
                      />
                      <Text as="div" muted small aria-describedby={`plan-${id}-runoutSeverity`}>
                        Runout
                      </Text>
                    </Flex>
                    <Flex>
                      <Text small muted fontWeight="bold">
                        Earliest {earliestRunoutText}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center" justifyContent="space-between">
                      <Icon
                        mr={1}
                        icon={utilizationSeverity.icon}
                        iconSize={16}
                        color={utilizationSeverity.color}
                        id={`plan-${id}-utilizationSeverity`}
                      />
                      <Text as="div" muted small aria-describedby={`plan-${id}-runoutSeverity`}>
                        Utilization
                      </Text>
                    </Flex>
                    <Flex>
                      <Text small muted fontWeight="bold">
                        Highest: {highestUtilization}
                      </Text>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <Flex justifyContent="space-between">
                  <LinkButton to={`/v4/core/capacity/${id}/edit`} small icon="plus">
                    Add
                  </LinkButton>
                </Flex>
              )}
            </Flex>
          </Flex>
        </>
      );
    }

    return (
      <Flex justifyContent="space-between" {...rest}>
        <Box flex={1} borderBottom="thin" px={1} pb={1}>
          <Flex justifyContent="space-between" flexDirection="column" height="100%">
            <Text as="div" small fontWeight="heavy" mb="4px">
              Total Capacity
            </Text>
            <Flex alignItems="flex-end" justifyContent="space-between">
              <Flex alignItems="baseline">
                <Text as="div" fontSize={24} fontWeight="heavy">
                  {capacityFormatted}
                </Text>
                <Text small muted ml="4px">
                  {capacityGreekFormatted}
                </Text>
              </Flex>
              <Text small muted ml="4px">
                {totalPercentUtil ? `${totalPercentUtil.toFixed(2)}% Utilized` : 'N/A'}
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Box flex={1} borderLeft="thin" borderBottom="thin" px={1} pb={1}>
          <Flex justifyContent="space-between">
            <Flex justifyContent="space-between" flexDirection="column" height="100%">
              <Text as="div" small fontWeight="heavy" mb="4px">
                Interfaces
              </Text>
              <Flex alignItems="center">
                <Text as="div" fontSize={24} fontWeight="heavy">
                  {totalInterfaceCount}
                </Text>
              </Flex>
            </Flex>
            <Flex flexDirection="column" alignSelf="flex-end">
              {criticalInterfacesCount !== 0 && (
                <Text small color="danger" fontWeight="heavy">
                  {criticalInterfacesCount} Critical
                </Text>
              )}
              {warningInterfacesCount !== 0 && (
                <Text small color="warning" fontWeight="heavy">
                  {warningInterfacesCount} Warning
                </Text>
              )}
              <Text small color="success" fontWeight="heavy">
                {healthyInterfacesCount} Healthy
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Box flex={1} borderLeft="thin" borderBottom="thin" px={1} pb={1}>
          <Flex justifyContent="space-between" height="100%">
            <Flex justifyContent="space-between" flexDirection="column" height="100%">
              <Text as="div" small fontWeight="heavy" mb="4px">
                Runout
                <Tooltip
                  position="top"
                  content={
                    <div>
                      <Text fontWeight="bold">{runoutStrategy}</Text>
                      <Icon icon="dot" color="warning" mr="-2px" />
                      {thresholds.runout.warning}
                      {runoutTimeUnit}
                      <Icon icon="dot" color="danger" mr="-2px" />
                      {thresholds.runout.critical}
                      {runoutTimeUnit}
                    </div>
                  }
                >
                  <Icon p="2px" icon={BsQuestionCircleFill} iconSize={12} color="muted" />
                </Tooltip>
              </Text>
              <Icon mr={1} icon={runoutSeverity.icon} iconSize={28} color={runoutSeverity.color} />
            </Flex>
            <Flex justifyContent="flex-end" flexDirection="column" height="100%">
              <Flex flexDirection="column" alignSelf="flex-end">
                {runoutCriticalCount !== 0 && (
                  <Text small color="danger" fontWeight="heavy">
                    {runoutCriticalCount} Critical
                  </Text>
                )}
                {runoutWarningCount !== 0 && (
                  <Text small color="warning" fontWeight="heavy">
                    {runoutWarningCount} Warning
                  </Text>
                )}
                {runoutCriticalCount === 0 && runoutWarningCount === 0 && (
                  <Text small color="success" fontWeight="heavy">
                    All Healthy
                  </Text>
                )}
              </Flex>
              <Text small muted>
                Earliest {earliestRunoutText}
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Box flex={1} borderLeft="thin" borderBottom="thin" px={1} pb={1}>
          <Flex justifyContent="space-between" height="100%">
            <Flex justifyContent="space-between" flexDirection="column" height="100%">
              <Text as="div" small fontWeight="heavy" mb="4px">
                Utilization
                <Tooltip
                  position="top"
                  content={
                    <div>
                      <Text fontWeight="bold">{thresholds.utilization.aggregate}</Text>
                      <Icon icon="dot" color="warning" mr="-2px" />
                      {thresholds.utilization.warning}%
                      <Icon icon="dot" color="danger" mr="-2px" />
                      {thresholds.utilization.critical}%
                    </div>
                  }
                >
                  <Icon p="2px" icon={BsQuestionCircleFill} iconSize={12} color="muted" />
                </Tooltip>
              </Text>
              <Icon mr={1} icon={utilizationSeverity.icon} iconSize={28} color={utilizationSeverity.color} />
            </Flex>
            <Flex justifyContent="flex-end" flexDirection="column" height="100%">
              <Flex flexDirection="column" alignSelf="flex-end">
                {utilizationCriticalCount !== 0 && (
                  <Text small color="danger" fontWeight="heavy">
                    {utilizationCriticalCount} Critical
                  </Text>
                )}
                {utilizationWarningCount !== 0 && (
                  <Text small color="warning" fontWeight="heavy">
                    {utilizationWarningCount} Warning
                  </Text>
                )}
                {utilizationCriticalCount === 0 && utilizationWarningCount === 0 && (
                  <Text small color="success" fontWeight="heavy">
                    All Healthy
                  </Text>
                )}
              </Flex>
              <Text small muted>
                Highest: {highestUtilization}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    );
  }
}
