import React, { useEffect } from 'react';
import styled from 'styled-components';
import { space, width, display, maxHeight } from 'styled-system';
import { TextArea as BlueprintTextArea } from '@blueprintjs/core';
import { sum } from 'lodash';
import { omitFieldAndAriaProps } from './util/omitFieldProps';
import shiftInputCursor from './util/shiftInputCursor';

const StyledTextArea = styled(BlueprintTextArea)`
  ${display};
  ${space};
  ${width};
  ${maxHeight};

  &.jumbo {
    font-size: 16px;
    padding-left: 10px;
  }
`;

const TextArea = (props) => {
  // autoGrow instead of blueprintjs TextArea growVertically to handle border width for scrollHeight
  const { labelId, autoComplete, fill, autoFocus, resize, value, onChange, autoGrow, style, inputRef, isRequired } =
    props;
  const elementRef = React.createRef();
  const ariaProps = isRequired ? { 'aria-required': true } : {};
  if (labelId) {
    ariaProps['aria-labelledby'] = labelId;
  }

  const adjustHeight = React.useCallback(
    (element) => {
      if (!autoGrow) {
        return;
      }

      const borderWidth = sum(
        ['border-top-width', 'border-bottom-width']
          .map((property) => window.getComputedStyle(element).getPropertyValue(property))
          .map((borderPx) => parseFloat(borderPx, 10))
      );

      // have to reset the height to 0 before setting the scrollHeight, otherwise it won't shrink
      element.style.height = 0;
      element.style.height = `${element.scrollHeight + borderWidth}px`;
    },
    [autoGrow]
  );

  // componentDidMount() equivalent, supports passing in inputRef from a parent component
  useEffect(() => {
    const ref = inputRef || elementRef;

    adjustHeight(ref.current);
  }, [adjustHeight, inputRef, elementRef, value]);

  const handleChange = (event) => {
    adjustHeight(event.target);
    if (onChange) {
      return onChange(event);
    }
    return null;
  };

  return (
    <StyledTextArea
      {...ariaProps}
      {...omitFieldAndAriaProps(props)}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      fill={fill}
      onFocus={autoFocus ? shiftInputCursor : undefined}
      onChange={handleChange}
      inputRef={inputRef || elementRef}
      style={{ resize, ...(style || {}) }}
    />
  );
};

TextArea.displayName = 'TextArea';

TextArea.defaultProps = {
  autoComplete: 'off',
  resize: 'none'
};

export default TextArea;
