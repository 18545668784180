import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { Flex, FlexColumn, Box, Button, Text, Tooltip, MenuItem, Spinner } from 'core/components';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$alerting.silenceCollection')
@inject('$auth', '$dictionary', '$metrics')
@observer
export default class SilenceAlertButton extends Component {
  static defaultProps = {
    comment: '',
    disabled: false
  };

  state = {
    requestPending: false
  };

  handleRemoveSilence = (model) => {
    this.setState({ requestPending: true });
    model.destroy().then(() => {
      this.setState({ requestPending: false });
    });
  };

  handleCreateSilence = (model) => {
    const { $alerting } = this.props;
    this.setState({ requestPending: true });
    const [silence] = $alerting.buildSilencesFromAlerts(model, {
      endTime: moment().add(7, 'days')
    });

    silence.create().then(() => {
      this.setState({ requestPending: false });
    });
  };

  get silenceModel() {
    const { alertModel } = this.props;
    return alertModel.silenceModel;
  }

  get isSilenced() {
    return Boolean(this.silenceModel);
  }

  renderTooltipText = () => {
    if (this.silenceModel) {
      const { startTime, startDetails, expirationDetails } = this.silenceModel;

      return startDetails.started ? (
        <>
          <Text>Alert is already silenced:</Text>
          <Text>
            <b>Started:</b> {startTime} ({startDetails.diff} ago)
          </Text>
          <Text>
            <b>Ends:</b> {expirationDetails.expirationDay} (in {expirationDetails.duration})
          </Text>
        </>
      ) : (
        <>
          <Text>Alert has a pending silence:</Text>
          <Text>
            <b>Starts:</b> {startTime} (in {startDetails.diff})
          </Text>
          <Text>
            <b>Ends:</b> {expirationDetails.expirationDay} (in {expirationDetails.duration})
          </Text>
        </>
      );
    }

    return <Text>Silence notifications from this alert for 7 days</Text>;
  };

  render() {
    const { $auth, alertModel, asMenuItem, buttonProps, disabled } = this.props;
    const { requestPending } = this.state;
    const dimensionToKeyPart = alertModel.get('dimensionToKeyPart');

    if (!dimensionToKeyPart || !alertModel.canSilence) {
      return null;
    }

    const isAllowedToCreateSilence = $auth.hasPermission('alerts.silentMode.create');
    const iconName = this.isSilenced ? 'notifications' : 'notifications-snooze';
    const buttonText = this.isSilenced ? 'Unsilence Notifications' : 'Silence Notifications';
    const modelToUse = this.isSilenced ? this.silenceModel : alertModel;
    const modelHandler = this.isSilenced ? this.handleRemoveSilence : this.handleCreateSilence;

    const ButtonComp = (
      <Button
        mr={0}
        width="100%"
        icon={iconName}
        onClick={() => modelHandler(modelToUse)}
        disabled={requestPending || !isAllowedToCreateSilence || disabled}
        {...buttonProps}
        text={
          <Flex gap={1}>
            {buttonText}
            {requestPending && <Spinner size={16} />}
          </Flex>
        }
      />
    );

    const MenuItemComp = (
      <MenuItem
        icon={iconName}
        disabled={requestPending || !isAllowedToCreateSilence || disabled}
        text={
          <Flex gap={1}>
            {buttonText}
            {requestPending && <Spinner size={16} />}
          </Flex>
        }
        onClick={() => modelHandler(modelToUse)}
      />
    );

    const ClickElement = asMenuItem ? MenuItemComp : ButtonComp;

    return requestPending ? (
      ClickElement
    ) : (
      <Box>
        <Tooltip fill content={<FlexColumn>{this.renderTooltipText()}</FlexColumn>}>
          {ClickElement}
        </Tooltip>
      </Box>
    );
  }
}
