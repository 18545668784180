import React from 'react';
import { FiMoreVertical, HiFilter } from 'react-icons/fi';

// Can return undefined, string or . .
export function guessByPossibleElement(element) {
  if (element === undefined || !React.isValidElement(element) || typeof element === 'string') {
    return element;
  }

  const { children: childrenList } = element.props;

  if (typeof childrenList === 'string') {
    return childrenList;
  }

  const possibleLabels = [];

  if (Array.isArray(childrenList)) {
    childrenList.forEach((subElement) => {
      if (typeof subElement === 'string') {
        possibleLabels.push(subElement);
      } else if (subElement && subElement.props && subElement.props.children) {
        const childText = guessByPossibleElement(subElement.props.children); // this.
        if (childText) {
          possibleLabels.push(childText);
        }
      }
    });
  }

  return possibleLabels.join(' ');
}

export function filterForAriaProps(props = {}) {
  const ariaProps = {};
  Object.keys(props).forEach((key) => {
    if (key.startsWith('aria-')) {
      ariaProps[key] = props[key];
    }
  });
  return ariaProps;
}

export function guessByChildText(children) {
  if (!children) {
    return null;
  }
  if (typeof children === 'string') {
    return children;
  }
  if (typeof children?.props?.children === 'string') {
    return children?.props?.children;
  }
  // We might recurse, but would possibly want limits
  // if (typeof children?.props?.children === 'object') {
  //   return guessByChildText(children?.props?.children);
  // }
  if (children?.length > 0) {
    if (children.length === 1) {
      return guessByChildText(children[0]);
    }
    if (children.length > 1) {
      return children.map((child) => guessByChildText(child)).join(' ');
    }
  }

  if (children?.props?.children?.length > 0) {
    const childrenList = children.props.children;
    if (childrenList.length > 0) {
      if (childrenList.length === 1) {
        return guessByChildText(childrenList[0]);
      }
      if (childrenList.length > 1) {
        return childrenList.map((child) => guessByChildText(child)).join(' ');
      }
    }
  }
  return null;
}

// Could make a version that checks for exact elements like Label
export function guessTextFromPossibleElement(reactElement) {
  // shoudl only send elements here but just in case . .
  if (typeof reactElement === 'string') {
    return reactElement;
  }

  if (!React.isValidElement(reactElement)) {
    return null;
  }

  const { props } = reactElement;

  // Check if name is a string
  if (typeof props?.name === 'string') {
    return props.name;
  }

  // Fallback to guessing text from children
  return guessByChildText(props.children);
}

export function guessTextFromLabel(label) {
  // shoudl only send elements here but just in case . .
  if (typeof label === 'string') {
    return label;
  }

  if (!React.isValidElement(label)) {
    return null;
  }

  const { props } = label;

  // Check if label is a string
  if (typeof props?.label === 'string') {
    return props.label;
  }

  // Check if label is an object with a 'get' method
  if (props?.label?.get) {
    return props.label.get('name');
  }

  // Check if name is a string
  if (typeof props?.name === 'string') {
    return props.name;
  }

  // Fallback to guessing text from children
  return guessByChildText(props.children);
}

export const guessTextOnIcon = (icon) => {
  switch (icon) {
    case FiMoreVertical:
      return 'Open Menu';
    case HiFilter:
      return 'Filter';
    case 'chevron-left':
      return 'left'; // or close Previous
    case 'chevron-right':
      return 'right'; // or next
    case 'chevron-up':
      return 'up'; // close or collapse
    case 'chevron-down':
      return 'down';
    case 'caret-left':
      return 'left'; // usually Previous
    case 'caret-right':
      return 'right'; // usually Next
    case 'caret-down':
      return 'close';
    case 'caret-up':
      return 'open';
    case 'cross':
      return 'remove';
    default:
      if (typeof icon === 'string') {
        return icon;
      }
      if (icon?.props?.children) {
        return guessByChildText(icon.props.children);
      }
      return '';
  }
};

export const formAriaProps = (propsOrRest, otherProps, options) => {
  options = {
    passOnExistingAria: true,
    enableTabIndexNotDisabled: false,
    explicitTabIndex: undefined,
    ...options
  };
  let ariaProps = {};
  const {
    ariaChecked,
    checked,
    ariaDisabled,
    disabled,
    ariaExpanded,
    expanded,
    ariaHelpTextId,
    helperTextId,
    ariaHaspopup,
    haspopup,
    ariaHidden,
    hidden,
    ariaLabel,
    label,
    ariaLabelId,
    labelId,
    ariaReadOnly,
    readOnly,
    ariaRequired,
    required,
    ariaSelected,
    selected,
    ariaLive,
    ariaDescribedby,
    tableLabelId,
    name
  } = {
    ...propsOrRest,
    // deliberately let this object override the rest
    ...otherProps
  };

  // according to specs, aria-labelledby will always take precedence over aria-label if both are applied to the same element.
  if (ariaLabelId || labelId || tableLabelId) {
    ariaProps['aria-labelledby'] = ariaLabelId || labelId || tableLabelId;
  } else if (ariaLabel || label || name) {
    ariaProps['aria-label'] = ariaLabel || label || name;
  }

  if (otherProps.name && !propsOrRest.name) {
    ariaProps.name = otherProps.name;
  }

  if (ariaDescribedby || ariaHelpTextId || helperTextId) {
    ariaProps['aria-describedby'] = ariaDescribedby || ariaHelpTextId || helperTextId;
  }

  // What if we want tabIndex on a disabled element?
  if (ariaDisabled === true || disabled === true) {
    ariaProps['aria-disabled'] = ariaDisabled || disabled;
  } else if (options.enableTabIndexNotDisabled) {
    if (options.explicitTabIndex !== undefined) {
      ariaProps.tabIndex = options.explicitTabIndex;
    } else {
      ariaProps.tabIndex = 0;
    }
  }

  if (ariaExpanded !== undefined || expanded !== undefined) {
    ariaProps['aria-expanded'] = ariaExpanded ?? expanded;
  }

  if (ariaHaspopup !== undefined || haspopup !== undefined) {
    ariaProps['aria-haspopup'] = ariaHaspopup ?? haspopup;
  }

  if (ariaRequired === true || required === true) {
    ariaProps['aria-required'] = true;
  }

  if (ariaReadOnly === true || readOnly === true) {
    ariaProps['aria-readonly'] = ariaReadOnly || readOnly;
  }

  if (ariaSelected !== undefined || selected !== undefined) {
    ariaProps['aria-selected'] = ariaSelected ?? selected;
  }

  if (ariaLive !== undefined) {
    ariaProps['aria-live'] = ariaLive;
  }

  if (ariaChecked !== undefined || checked !== undefined) {
    ariaProps['aria-checked'] = ariaChecked ?? checked;
  }

  if (ariaHidden === true || hidden === true) {
    ariaProps['aria-hidden'] = ariaHidden || hidden;
  }

  // CAREFUL - so original props with arai-XX will override any of the above
  if (options.passOnExistingAria) {
    ariaProps = {
      ...ariaProps,
      ...filterForAriaProps(propsOrRest)
    };
  }

  return ariaProps;
};
