import React from 'react';
import { withRouter } from 'react-router-dom';
import { Flex, Heading, Icon } from 'core/components';
import FavoriteButton from 'app/views/core/FavoriteButton';

const PageHeading = (props) => {
  const { match, favoriteId, hideFavorite = false, favoriteMetadata, level, title, icon, ...rest } = props;
  const favId = favoriteId || match.url;

  return (
    <Flex gap="4px">
      {!hideFavorite && <FavoriteButton type="page" id={favId} name={title} metadata={favoriteMetadata} />}

      <Heading level={level} {...rest} role="heading">
        {icon && <Icon icon={icon} color="warning" iconSize={32} mr={1} />}
        {title}
      </Heading>
    </Flex>
  );
};

PageHeading.defaultProps = {
  level: 1
};

export default withRouter(PageHeading);
