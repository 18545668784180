import React from 'react';
import { observer } from 'mobx-react';
import { Text } from 'core/components';

export const FieldLabel = observer(({ labelId, smallField, showError, inline, children, ...labelProps }) => (
  <Text
    id={labelId}
    color={showError ? 'danger' : undefined}
    fontSize={smallField ? 12 : 14}
    lineHeight={inline ? undefined : 1}
    {...labelProps}
  >
    {children}
  </Text>
));
