import React from 'react';
import styled from 'styled-components';
import { space, width, display, maxWidth, minWidth, themeGet } from 'styled-system';
import { Classes, FormGroup as BlueprintFormGroup } from '@blueprintjs/core';

import { omitFieldAndAriaProps } from './util/omitFieldProps';
import flex from '../../components/utils/flex';

const FormGroup = styled(BlueprintFormGroup)`
  ${display};
  ${flex};
  ${space};
  ${maxWidth};
  ${minWidth};
  ${width};

  &.${Classes.SMALL} {
    font-size: ${themeGet('fontSizes.small')};
  }

  &.right-aligned-label-info {
    .bp4-label > span:last-child {
      flex: 1;
      text-align: right;
    }
  }

  /* remove the top margin for controls within FormGroups */
  .${Classes.CONTROL} {
    margin-top: 0;
  }

  .${Classes.LABEL} {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: 1;
    display: flex;
    align-items: flex-end;

    /* (required) bits */
    .${Classes.TEXT_MUTED} {
      font-weight: ${themeGet('fontWeights.regular')};
      margin-left: 1px;
    }
  }

  &.bp4-intent-danger {
    .bp4-form-helper-text {
      color: ${(props) => props.theme.colors.danger} !important;
    }
  }
`;

const Component = (props) => <FormGroup {...omitFieldAndAriaProps(props)} />;

Component.displayName = 'FormGroup';

Component.defaultProps = {
  fontFamily: 'body'
};

export default Component;
