import React from 'react';
import styled from 'styled-components';
import { space, width, color, fontWeight } from 'styled-system';
import { Label as BlueprintLabel } from '@blueprintjs/core';
import { omitFieldAndAriaProps } from './util/omitFieldProps';

const Label = styled(BlueprintLabel)`
  ${color};
  ${space};
  ${width};
  ${fontWeight};
`;

const Component = (props) => <Label {...omitFieldAndAriaProps(props)} />;

Component.displayName = 'Label';

export default Component;
