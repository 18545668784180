import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Card, Flex, Heading, HTMLTable, Text } from 'core/components';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import InsightsMenu from 'app/components/navbar/InsightsMenu';
import Page from 'app/components/page/Page';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';

import ASNRenderer from 'app/components/asn/ASNRenderer';
import ASGroupRenderer from 'app/components/asn/ASGroupRenderer';
import FavoriteButton from 'app/views/core/FavoriteButton';
import CurrentCostTrend from 'app/views/edge/cost/components/CurrentCostTrend';
import AggregateViewHeader from '../aggregate/AggregateViewHeader';
import parentLinks from '../NetworkExplorerParentLink';
import NetworkExplorerTabs from '../NetworkExplorerTabs';
import PeeringInfo from './asns/PeeringInfo';
import PeeringDetailsSidebar from './asns/PeeringDetailsSidebar';
import PeeringInfoDialog from './asns/PeeringInfoDialog';

@inject('$auth', '$companySettings', '$cost', '$lookups', '$peering', '$tabs')
@observer
class AsnDetails extends Component {
  constructor(props) {
    super(props);

    const { $tabs } = this.props;
    const tabs = $tabs.getTabs();

    this.state.tabBlacklist = [tabs.ASNS.id];
  }

  state = {
    filters: [],
    asDetails: undefined,
    peeringDetails: undefined,
    detailsLoading: true,
    peeringLoading: true,
    selectedModel: null,
    peeringDialogOpen: false,
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec',
    snapshot: null
  };

  componentDidMount() {
    this.fetchAsDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.asn !== decodeURIComponent(prevProps.match.params.asn)) {
      this.fetchAsDetails();
    }
  }

  fetchAsDetails() {
    const { $auth, $cost, $lookups, $peering } = this.props;

    this.setState({
      detailsLoading: true,
      peeringLoading: true,
      asGroupDetails: null,
      asDetails: null,
      peeringDetails: null,
      selectedModel: null
    });

    if (this.isAsGroup) {
      $lookups.asGroup(this.asn).then((asResults) => {
        const asGroupDetails = asResults
          .find((result) => result.value.toLowerCase() === this.asn.toLowerCase())
          ?.matcher?.filter((m) => m !== null);
        this.setState({ asGroupDetails, detailsLoading: false });
      });
    } else {
      $lookups.asNumbers(this.asn).then((asResults) => {
        const asDetails = asResults.find((result) => result.value.toLowerCase() === this.asn.toLowerCase());
        this.setState({ asDetails: asDetails?.matcher, detailsLoading: false });
      });

      $peering.fetchPeeringAsn(this.asn).then((peeringDetails) => {
        this.setState({ peeringDetails, peeringLoading: false });
      });

      if ($auth.hasPermission('trafficCosts.enabled', { overrideForSudo: false })) {
        $cost.fetchLatestSnapshotByType('asn', this.asn).then((snapshot) => {
          this.setState({ snapshot });
        });
      }
    }
  }

  get asn() {
    const { match } = this.props;
    return decodeURIComponent(match.params.asn);
  }

  get isAsGroup() {
    return Number.isNaN(parseInt(this.asn));
  }

  get filters() {
    const { filters } = this.state;

    const detailFilter = {
      filterField: this.isAsGroup ? 'as_group' : 'asn',
      filterValue: this.asn
    };

    return filters.concat(detailFilter);
  }

  get queryOverrides() {
    const { lookback_seconds, aggregateType } = this.state;
    return {
      lookback_seconds,
      aggregateTypes: [aggregateType]
    };
  }

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  handleModelSelect = (selectedModel, evt) => {
    if (evt) {
      evt.stopPropagation();
    }

    this.setState({ selectedModel });
  };

  handleDrawerClose = () => this.setState({ selectedModel: null });

  renderHeader() {
    const { asDetails, asGroupDetails, detailsLoading } = this.state;
    const showMoreInfo = this.isAsGroup && asGroupDetails && asGroupDetails.length > 0;
    const showTabs = showMoreInfo || !this.isAsGroup;

    if (detailsLoading) {
      return (
        <Heading level={1} mb={1}>
          {this.asn}
        </Heading>
      );
    }

    if (this.isAsGroup) {
      return <ASGroupRenderer asns={asGroupDetails} name={this.asn} largeTag level={1} mb={1} />;
    }

    return (
      <ASNRenderer
        asn={this.asn}
        description={asDetails}
        showLogo
        level={1}
        largeTag
        mb={showTabs ? 1 : 0}
        iconProps={{ maxWidth: '120px', height: '36px' }}
        showLinks={['kmi']}
      />
    );
  }

  render() {
    const { $companySettings, history, match } = this.props;
    const {
      asDetails,
      asGroupDetails,
      peeringDetails,
      peeringLoading,
      peeringDialogOpen,
      selectedModel,
      aggregateType,
      filters,
      lookback_seconds,
      tabBlacklist,
      snapshot
    } = this.state;
    const title = !this.isAsGroup && asDetails ? `${asDetails} (${this.asn})` : this.asn;
    const showMoreInfo = this.isAsGroup && asGroupDetails && asGroupDetails.length > 0;
    const showPeering = !this.isAsGroup;

    return (
      <Page
        title={title}
        parentLinks={[...parentLinks, { link: '/v4/core/quick-views/asn', label: 'ASNs' }]}
        insightsMenu={<InsightsMenu query="fetchAsnInsights" params={this.asn} />}
        drawerContents={<PeeringDetailsSidebar model={selectedModel} onClose={this.handleDrawerClose} />}
        drawerIsOpen={!!selectedModel}
        drawerOnClose={this.handleDrawerClose}
        drawerProps={{ showDockedInLargeFullScreen: false }}
        minHeight="100%"
        scrolls
        showExport
        subnavTools={
          <Button
            icon="properties"
            text="My Peering Details"
            minimal
            onClick={() => this.setState({ peeringDialogOpen: true })}
          />
        }
      >
        <Flex alignItems="center" mb={1}>
          <Flex flex="1 1 auto" gap="4px" mr={2}>
            <FavoriteButton
              type="page"
              id={match.url}
              name={`AS${this.asn}: ${title}`}
              metadata={{ resultType: 'asn' }}
            />
            {this.renderHeader()}
          </Flex>
        </Flex>

        <NetworkExplorerTabs
          key={this.asn}
          trafficTabContent={
            <>
              <AggregateViewHeader
                aggregateType={aggregateType}
                filters={filters}
                lookbackSeconds={lookback_seconds}
                onFiltersChange={this.handleFiltersApply}
                onAggregateChange={this.handleAggregationChange}
                onMetricsChange={this.handleAggregationChange}
                onTimeRangeChange={this.handleLookbackChange}
              />

              <Card className="break-after" mb={3}>
                <TabbedChart childrenLocation="top" overrides={this.queryOverrides} simpleFilters={this.filters}>
                  {snapshot && (
                    <Flex justifyContent="space-between" mx={4} my={1}>
                      <CurrentCostTrend
                        pr={2}
                        flex={1}
                        current={snapshot.latest?.cost}
                        previous={snapshot.previous?.cost}
                        currency={$companySettings.currency}
                        labelSize={24}
                        onClick={() => history.push(`/v4/edge/traffic-costs/${snapshot.id}`)}
                        style={{ cursor: 'pointer' }}
                      />

                      <CurrentCostTrend
                        px={2}
                        ml={1}
                        flex={1}
                        current={snapshot.latest?.cost_per_mbps}
                        previous={snapshot.previous?.cost_per_mbps}
                        currency={$companySettings.currency}
                        label="Cost per Mbps"
                        labelSize={24}
                        onClick={() => history.push(`/v4/edge/traffic-costs/${snapshot.id}`)}
                        style={{ cursor: 'pointer' }}
                      />
                    </Flex>
                  )}
                </TabbedChart>
              </Card>

              <VerticalDetailTabs
                blacklist={tabBlacklist}
                queryOverrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
                simpleFilters={this.filters}
              />
            </>
          }
          moreInfoTabContent={
            showMoreInfo ? (
              <>
                <Text as="div" color="muted" fontWeight="bold" fontSize={16} mb={1}>
                  AS Group Members
                </Text>

                <Card flat p={0} overflow="hidden">
                  <HTMLTable striped>
                    <tbody>
                      {asGroupDetails.map((asn) => (
                        <tr key={asn.id}>
                          <td>
                            <ASNRenderer asn={asn.id} description={asn.description} showLinks />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </HTMLTable>
                </Card>
              </>
            ) : undefined
          }
          peeringDbTabContent={
            showPeering && (
              <PeeringInfo
                asn={this.asn}
                details={peeringDetails}
                loading={peeringLoading}
                selectedModel={selectedModel}
                onModelSelect={this.handleModelSelect}
              />
            )
          }
        />
        <PeeringInfoDialog isOpen={peeringDialogOpen} onClose={() => this.setState({ peeringDialogOpen: false })} />
      </Page>
    );
  }
}

export default AsnDetails;
