import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import Box from './Box';

const Heading = ({ level, className, fontWeight, color, ...rest }) => {
  if (level <= 2 && !fontWeight) {
    fontWeight = 'heavy';
  }

  if (!level) {
    console.warn('Heading specified with no level');
  }

  return (
    <Box
      fontWeight={fontWeight}
      color={color || undefined}
      {...rest}
      aria-level={level}
      as={`h${level}`}
      className={classNames(Classes.HEADING, className)}
    />
  );
};

export default Heading;
