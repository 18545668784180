import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { isEqual, noop, omitBy } from 'lodash';
import { withTheme } from 'styled-components';
import moment from 'moment';

import Box from 'core/components/Box';
import Button from 'core/components/Button';
import Flex, { FlexColumn } from 'core/components/Flex';
import DrawerSection from 'core/components/DrawerSection';
import Heading from 'core/components/Heading';
import ButtonLink from 'core/components/ButtonLink';
import Tag from 'core/components/Tag';
import Text from 'core/components/Text';
import CheckboxGroup from 'core/form/components/CheckboxGroup';
import LookbackDateRange from 'core/form/components/LookbackDateRange';
import DateRange from 'core/form/components/DateRange';
import Field from 'core/form/components/Field';
import FormComponent from 'core/form/components/FormComponent';
import MultiSelect from 'core/form/components/select/MultiSelect';
import RadioGroup from 'core/form/components/RadioGroup';

import AdminFilterItemRenderer from 'app/components/admin/AdminFilterItemRenderer';

@inject('$app', '$exports')
@withRouter
@withTheme
@observer
class AdminFilterSidebar extends Component {
  static defaultProps = {
    title: 'Filters',
    formName: 'AdminFilterSidebar',
    showTitle: true,
    showClose: false,
    resetFiltersOnClear: false,
    useDiscreteFilters: false,
    updateHashOnFilterChange: true,
    filterValues: undefined,
    defaultValues: {},
    resetValues: {},
    onClose: noop,
    onFilterChange: noop,
    onFilterStateChange: noop,
    // additional filters that can be passed in that are too custom to be included into baseline form / field composition
    additionalFilters: []
  };

  state = {
    groupDefaultOpen: {}
  };

  form = undefined;

  componentDidMount() {
    const { $exports, updateHashOnFilterChange } = this.props;

    $exports.getSettings().then(({ hashedFilters, groupDefaultOpen = {} }) => {
      if (this.form) {
        const { startDate, endDate, ...restHashedFilters } = hashedFilters || {};
        // If this view loaded with a ?q=xxx hash, then load hash filters into the form now
        if (hashedFilters && !isEqual(this.form.getValues(), hashedFilters)) {
          // Make sure we convert hashed dates into moment objects
          if (typeof startDate === 'string' || typeof startDate === 'number') {
            restHashedFilters.startDate = moment(startDate);
          }
          if (typeof endDate === 'string' || typeof endDate === 'number') {
            restHashedFilters.endDate = moment(endDate);
          }

          // Prefer lookback if we have it
          if (restHashedFilters.lookback) {
            restHashedFilters.startDate = moment().subtract(restHashedFilters.lookback, 'seconds');
            restHashedFilters.endDate = moment();
          }

          this.form.setValues(restHashedFilters);
        }

        // Then call our setFilters handle the current form values (the defaults)
        // - Don't call setFilters unless updateHashOnFilterChange has been specified. We need to skip this when we're embedded in the subscription dialog.
        if (updateHashOnFilterChange) {
          this.setFilters(this.form, this.form.getValues());
        }

        this.setState({ groupDefaultOpen });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { filterValues, resetFiltersOnClear, onFilterChange, filterFields, onFilterStateChange, loading } =
      this.props;

    if (prevProps.filterValues && !filterValues && resetFiltersOnClear && this.form) {
      this.handleResetToDefault(this.form);
    } else {
      // Set form values and call the filter handler when the filter props are different than what's in the form
      // To make an even comparison, clear out undefined properties from each side so that {} will equal {a: undefined}
      let newFilters = omitBy(filterValues, (val) => val === undefined);
      const formValues = omitBy(this.form.getValues(), (val) => val === undefined);

      // merge formValues into newFilters to cover cases where filterValues was only a subset of possible filters
      newFilters = { ...formValues, ...newFilters };

      if (!isEqual(prevProps.filterFields, filterFields)) {
        onFilterStateChange(filterValues, filterFields);
      }

      if (this.form && filterValues && (!isEqual(newFilters, formValues) || (prevProps.loading && !loading))) {
        this.form.setValues(filterValues);
        this.setFilters(this.form, this.form.getValues());
        onFilterChange(filterValues, this.form, filterFields);
      }
    }
  }

  filterCollection = (filters) => {
    const { useDiscreteFilters, collection, filterFields } = this.props;
    const discreteFilters = [];

    if (!useDiscreteFilters || !collection) {
      return;
    }

    Object.keys(filters).forEach((type) => {
      const field = filterFields.find((filterField) => filterField.name === type);

      if (field && field.filter && (!field.allowMultiSelect || filters[type]?.length > 0)) {
        discreteFilters.push({
          type,
          label: field.label,
          value: filters[type],
          fn: (model) => field.filter(model, filters[type])
        });
      }
    });

    collection.setDiscreteFilters(discreteFilters);
    collection.filter();
  };

  filtersDifferFromDefault(form) {
    const { filterFields, resetValues } = this.props;
    const filters = form.getValues();

    return filterFields
      .filter((field) => !field.isDateRange)
      .some((field) => {
        const value = filters[field.name];
        const resetValue = resetValues[field.name];

        // Do array check first - also check if list of items equals the default options
        if (Array.isArray(value)) {
          return !isEqual((resetValue || []).sort(), (value || []).sort());
        }

        // Ignore filter values that match reset values
        if (resetValue !== undefined && value === resetValue) {
          return false;
        }

        // Allow numbers (like 0), but check for falsy
        return Number.isFinite(value) || !!value;
      });
  }

  setFilters(form, filters) {
    const { filterFields, collection, onFilterChange, onFilterStateChange } = this.props;

    if (collection) {
      this.filterCollection(filters);
    }

    onFilterStateChange(filters, filterFields);
    onFilterChange(filters, form, filterFields);
  }

  handleFilterChange = (form, field, value, filterField) => {
    const { $exports, updateHashOnFilterChange, externalHashHandler } = this.props;
    const filters = form.getValues();

    if (filterField?.isDateRange && !filterField?.noLookback) {
      if (value.lookbackSeconds) {
        filters.lookback = value.lookbackSeconds;
        filters[filterField.startField] = null;
        filters[filterField.endField] = null;
      } else {
        filters.lookback = value.lookbackSeconds;
        filters[filterField.startField] = value[filterField.startField] * 1000;
        filters[filterField.endField] = value[filterField.endField] * 1000;
      }
    } else if (!Array.isArray(value) || (Array.isArray(value) && value.length > 0)) {
      filters[field.name] = value;
    } else {
      filters[field.name] = undefined;
    }

    this.setFilters(form, filters);

    if (updateHashOnFilterChange) {
      $exports.setHash({ hashedFilters: filters });
    } else if (externalHashHandler) {
      externalHashHandler(filters);
    }

    if (filterField?.onChange) {
      filterField.onChange(field, value);
    }
  };

  handleResetToDefault = (form) => {
    const { $exports, externalHashHandler, filterFields, updateHashOnFilterChange, useDiscreteFilters, resetValues } =
      this.props;
    const resetOptions = {};

    filterFields.forEach((field) => {
      const { name, startField, endField } = field;

      if (field.isDateRange) {
        resetOptions[startField] = form.getValue(startField);
        resetOptions[endField] = form.getValue(endField);
      } else if (useDiscreteFilters) {
        resetOptions[name] = resetValues?.[name] || '';
      }
    });

    form.init(resetOptions);
    this.setFilters(form, resetOptions);

    if (updateHashOnFilterChange) {
      $exports.setHash({ hashedFilters: resetOptions });
    } else if (externalHashHandler) {
      externalHashHandler(resetOptions);
    }
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleGroupToggle = (group, isOpen) => {
    const { $exports } = this.props;
    const { groupDefaultOpen } = this.state;
    groupDefaultOpen[group] = isOpen;
    this.setState({ groupDefaultOpen }, () => {
      $exports.setHash({ groupDefaultOpen });
    });
  };

  renderFilterField(form, filterField, idx) {
    const { filterFields, readOnly } = this.props;

    const {
      name,
      options,
      allowMultiSelect,
      showCheckboxes,
      inline,
      customField,
      rules,
      helpText,
      isDateRange,
      noLookback,
      closeDateRangeOnSelection,
      startField,
      endField,
      label,
      hidden,
      ...fieldProps
    } = filterField;

    if (hidden) {
      return null;
    }

    if (!customField && !isDateRange && (!options || options.length === 0)) {
      return null;
    }

    const isLastField = idx === filterFields.length - 1;

    if (isDateRange && noLookback) {
      return (
        <Box key={name} mb={isLastField ? 0 : '12px'}>
          {label && (
            <Text fontSize={14} as="div" fontWeight="bold" mb="4px">
              {label}
            </Text>
          )}
          <DateRange
            {...fieldProps}
            form={form}
            allowSingleDayRange
            closeOnSelection={closeDateRangeOnSelection}
            startField={startField}
            endField={endField}
            onChange={(field, value) => this.handleFilterChange(form, field, value)}
            popoverProps={{ position: 'bottom-right' }}
          />
        </Box>
      );
    }

    if (isDateRange) {
      const startFormField = form.getValue(startField);
      const startValue = startFormField ? Math.floor(startFormField.valueOf() / 1000) : null;
      const endFormField = form.getValue(endField);
      const endValue = endFormField ? Math.floor(endFormField.valueOf() / 1000) : null;
      const lookbackSeconds = form.getValue('lookback')?.valueOf();

      return (
        <Box key={name} mb={isLastField ? 0 : '12px'}>
          {label && (
            <Text fontSize={14} as="div" fontWeight="bold" mb="4px">
              {label}
            </Text>
          )}
          <LookbackDateRange
            {...fieldProps}
            form={form}
            showLabel={false}
            stacked
            allowSingleDayRange
            closeOnSelection={closeDateRangeOnSelection}
            startField={startField}
            endField={endField}
            startDate={startValue}
            endDate={endValue}
            lookbackSeconds={lookbackSeconds}
            onChange={(datesOrLookback) =>
              this.handleFilterChange(form, { startField, endField }, datesOrLookback, filterField)
            }
          />
        </Box>
      );
    }

    let fieldComponent;

    if (customField) {
      fieldComponent = customField;
    } else if (!allowMultiSelect) {
      fieldComponent = <RadioGroup itemRenderer={AdminFilterItemRenderer} />;
    } else if (showCheckboxes) {
      fieldComponent = <CheckboxGroup itemRenderer={AdminFilterItemRenderer} inline={inline} />;
    } else {
      fieldComponent = <MultiSelect fill showFilter />;
    }

    return (
      <Field
        key={name}
        name={name}
        beforeOnChange={filterField?.beforeOnChange}
        onChange={(field, value) => this.handleFilterChange(form, field, value)}
        options={options}
        rules={rules}
        helperText={helpText}
        mb={isLastField ? 0 : '12px'}
        large
        disabled={fieldProps.disabled || readOnly}
        labelProps={{ mb: '4px' }}
        fieldStyle={fieldProps.fieldStyle}
      >
        {fieldComponent}
      </Field>
    );
  }

  renderForm(form) {
    const { $app, filterFields, additionalFilters, group, title, showTitle, showClose, theme, readOnly } = this.props;
    const { groupDefaultOpen } = this.state;

    if (!this.form) {
      this.form = form;
    }

    const groupedFilters = group
      ? filterFields.reduce((acc, filterField) => {
          const groupIndex = acc.findIndex((grp) => grp.group === filterField.group);
          if (groupIndex === -1) {
            acc.push({ group: filterField.group, filters: [filterField] });
          } else {
            acc[groupIndex].filters.push(filterField);
          }
          return acc;
        }, [])
      : null;

    return (
      <>
        <Flex justifyContent="space-between" alignItems="flex-start">
          {showTitle && (
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              p="7px 8px"
              borderBottom="thin"
              style={{
                background: theme.backgrounds.tableHeader
              }}
            >
              <Flex alignItems="center" flex="1 1 auto">
                <Heading level={5} fontWeight="heavy" mb={0}>
                  {title}
                </Heading>
                {!$app.isExport && this.filtersDifferFromDefault(form) && (
                  <Box textAlign="left" mt="1px" ml={1}>
                    <ButtonLink
                      key="clear"
                      intent="primary"
                      onClick={() => this.handleResetToDefault(form)}
                      minimal
                      small
                      disabled={readOnly}
                    >
                      Reset To Default
                    </ButtonLink>
                  </Box>
                )}
              </Flex>
              {showClose && !$app.isExport && (
                <Button
                  onClick={this.handleClose}
                  icon="chevron-left"
                  small
                  minimal
                  aria-expanded
                  ariaLabel="close filter"
                />
              )}
            </Flex>
          )}
        </Flex>

        <FlexColumn flex="1 1 0%" p="12px" overflow="auto" maxWidth="320px">
          {group ? (
            <>
              {groupedFilters.map((grp) => {
                const enabledCount = grp.filters.reduce((acc, filterField) => {
                  const value = form.getValues()[filterField.name];
                  if (value) {
                    if (Array.isArray(value)) {
                      return acc + (value.length > 0 ? 1 : 0);
                    }
                    return acc + 1;
                  }
                  return acc;
                }, 0);
                return (
                  <Box key={grp.group} mb={3}>
                    <DrawerSection
                      defaultOpen={groupDefaultOpen[grp.group]}
                      headerProps={{ borderBottom: 'none' }}
                      label={
                        <Flex alignItems="center" justifyContent="space-between">
                          <Text large>{grp.group}</Text>
                          {enabledCount > 0 && (
                            <Tag circle minimal>
                              {enabledCount}
                            </Tag>
                          )}
                        </Flex>
                      }
                      onChange={(open) => this.handleGroupToggle(grp.group, open)}
                    >
                      {grp.filters.map((filterField, idx) => this.renderFilterField(form, filterField, idx))}
                    </DrawerSection>
                  </Box>
                );
              })}
            </>
          ) : (
            filterFields.map((filterField, idx) => this.renderFilterField(form, filterField, idx))
          )}
        </FlexColumn>
        {additionalFilters.map((extraField) => extraField)}
      </>
    );
  }

  render() {
    const { filterFields, defaultValues = {}, formName } = this.props;

    if (!Array.isArray(filterFields) || filterFields.length === 0) {
      return null;
    }

    const formFields = filterFields.reduce((acc, field) => {
      if (field.isDateRange) {
        acc[field.startField] = {
          defaultValue: defaultValues[field.startField] || field.startDefaultValue
        };
        acc[field.endField] = {
          defaultValue: defaultValues[field.endField] || field.endDefaultValue
        };
      } else {
        acc[field.name] = {
          label: field.label,
          placeholder: field.placeholder,
          defaultValue: defaultValues[field.name] || field.defaultValue,
          validateOptions: field.validateOptions !== false
        };
      }

      return acc;
    }, {});

    const formOptions = { name: formName };

    return (
      <FlexColumn width="100%" height="100%" overflow="hidden">
        <FormComponent fields={formFields} options={formOptions}>
          {({ form }) => this.renderForm(form)}
        </FormComponent>
      </FlexColumn>
    );
  }
}

export default AdminFilterSidebar;
