/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box, Flex, Icon } from 'core/components';
import { guessTextFromLabel } from '../../../core/util/ariaUtils';

const Column = ({ field, label, pl, onClick, sortState, justifyContent = 'flex-end' }) => {
  const ariaLabelGuess = guessTextFromLabel(label);
  return (
    <Box as="th" verticalAlign="bottom" aria-label={ariaLabelGuess} role="columnheader" aria-readonly="true">
      <Flex
        pl={pl}
        fontSize="small"
        alignItems="flex-end"
        justifyContent={justifyContent}
        cursor="pointer"
        data-column={field}
        onClick={onClick}
        aria-hidden="true"
      >
        <Box>{label}</Box>
        {sortState.field === field ? (
          <Icon
            color={sortState.field === field ? 'primary' : undefined}
            icon={sortState.direction === 'asc' ? 'chevron-up' : 'chevron-down'}
          />
        ) : (
          <Box width={16}> </Box>
        )}
      </Flex>
    </Box>
  );
};

export default Column;
