import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { merge } from 'lodash';

import FormDialog from 'core/form/components/FormDialog';
import BorderedTabs from 'core/components/BorderedTabs';
import BorderedTab from 'core/components/BorderedTab';

import { fields, options } from 'app/forms/config/notification';
import Preview from './Preview';
import Settings from './Settings';
import WhereUsed from './WhereUsed';

@inject('$notifications', '$auth')
export default class NotificationFormDialog extends Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
    filter: PropTypes.array,
    onClose: PropTypes.func,
    showUsedBy: PropTypes.bool
  };

  static defaultProps = {
    filter: [],
    onClose: () => {},
    showUsedBy: true
  };

  state = {
    previewContent: undefined,
    previewError: undefined
  };

  getPreviewContent = () => {
    const { $notifications, model } = this.props;

    $notifications.previewChannel(model.id).then(({ content, error }) => {
      this.setState({
        previewContent: content,
        previewError: error
      });
    });
  };

  handleTabChange = (tab) => {
    if (tab === 'preview') {
      this.getPreviewContent();
    }
  };

  handleRemove = () => {
    const { model } = this.props;
    return model.destroy();
  };

  handleSubmit = (form, values) => {
    const { model, onClose } = this.props;

    // lots of deeply nested properties here and only some are getting updated
    model.set({
      ...values,
      config: merge({}, model.get('config'), values.config)
    });

    // allow previous merge so we don't regress on current functionality, but force email updates as merge doesn't deal with array deletions the way we expect.
    const config = model.get('config');

    if (config?.sendingConfig?.usernames) {
      config.sendingConfig.usernames = values?.config?.sendingConfig?.usernames || [];
    }
    if (config?.sendingConfig?.customHeaders) {
      config.sendingConfig.customHeaders = values?.config?.sendingConfig?.customHeaders || [];
    }

    model.set({ config });

    return model.save().then(() => {
      onClose(model);
    });
  };

  render() {
    const { $auth, onClose, model, filter, showUsedBy, ...otherFormProps } = this.props;
    const { previewContent, previewError } = this.state;

    return (
      <FormDialog
        {...otherFormProps}
        model={model}
        fields={fields}
        options={options}
        title={model.isNew ? 'Add Notification Channel' : 'Edit Notification Channel'}
        entityName="Notification Channel"
        isOpen
        onClose={onClose}
        formComponentProps={{ large: true }}
        formActionsProps={{
          onSubmit: this.handleSubmit,
          onCancel: onClose,
          onRemove: this.handleRemove
        }}
        style={{ width: 780, height: 840 }}
        bodyStyle={{ padding: 0 }}
      >
        <BorderedTabs selectedTabBackgroundColor="dialogBackground" onChange={this.handleTabChange}>
          <BorderedTab
            id="settings"
            title="Settings"
            panel={<Settings model={model} filter={filter} role="tabpanel" />}
          />
          {$auth.isAdministrator && (
            <BorderedTab
              id="preview"
              title="Preview"
              panel={
                <Preview model={model} previewContent={previewContent} previewError={previewError} role="tabpanel" />
              }
              disabled={model.isNew}
            />
          )}
          {showUsedBy && (
            <BorderedTab
              id="whereUsed"
              title="Used By"
              panel={<WhereUsed model={model} role="tabpanel" />}
              disabled={model.isNew}
            />
          )}
        </BorderedTabs>
      </FormDialog>
    );
  }
}
