import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Text, Box } from 'core/components';
import { formConsumer } from 'core/form';
import FilterOptionsDialog from 'app/components/filters/FilterOptionsDialog';
import FilterOptionsDisplay from 'app/components/filters/FilterOptionsDisplay';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$savedFilters')
@formConsumer
@observer
class SidebarFilterOptions extends Component {
  static defaultProps = {
    fieldName: 'filters',
    filterGroupsField: undefined,
    smallText: true,
    emptyText: 'No filtering criteria have been applied',
    readOnly: false,
    muted: true,
    isOpen: false,
    showNames: false,
    showEmptyText: true,
    allowNestedFilters: false,
    showSaveFiltersButton: true,
    allowRightFilterField: true
  };

  get numFilters() {
    const { form, filterGroupsField, fieldName } = this.props;
    const field = filterGroupsField || form.getField(`${fieldName}.filterGroups`);
    return field.getValue().length;
  }

  handleCloseFilterDialog = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { className, emptyText, readOnly, muted, isOpen, smallText, showEmptyText, showSaveFiltersButton } =
      this.props;

    return (
      <Box className={className} opacity={readOnly && muted ? 0.4 : 1}>
        {this.numFilters === 0 && showEmptyText && (
          <Text muted small={smallText}>
            {emptyText}
          </Text>
        )}
        {this.numFilters > 0 && <FilterOptionsDisplay {...this.props} />}
        {isOpen && (
          <FilterOptionsDialog
            {...this.props}
            showSaveFiltersButton={showSaveFiltersButton}
            onClose={this.handleCloseFilterDialog}
          />
        )}
      </Box>
    );
  }
}

export default SidebarFilterOptions;
