/**
 * Global Azure vs China Azure difference documentation
 * https://learn.microsoft.com/en-us/azure/china/resources-developer-guide
 */

const KENTIK_AZURE_AUTH_URL = 'https://check-azure.kntk.cloud/';
const KENTIK_CHINA_AZURE_AUTH_URL = 'https://check-azure-cn.kntk.cloud/';

const AZURE_MANAGEMENT_URL = 'https://management.azure.com/';
const AZURE_CHINA_MANAGEMENT_URL = 'https://management.chinacloudapi.cn/';

const AZURE_LOGIN_URL = 'https://login.microsoftonline.com/';
const AZURE_CHINA_LOGIN_URL = 'https://login.partner.microsoftonline.cn/';

const AZURE_VNET_FLOW_LOGS_CONTAINER = 'insights-logs-flowlogflowevent';
const AZURE_NSG_FLOW_LOGS_CONTAINER = 'insights-logs-networksecuritygroupflowevent';
/**
 * @param [location] {string}
 * @returns {boolean}
 */
function isChinaRegion(location) {
  return location?.includes('china');
}

function getKentikAzureAuthUrlByLocation(location) {
  return isChinaRegion(location) ? KENTIK_CHINA_AZURE_AUTH_URL : KENTIK_AZURE_AUTH_URL;
}

function getAzureManagementUrlByLocation(location) {
  return isChinaRegion(location) ? AZURE_CHINA_MANAGEMENT_URL : AZURE_MANAGEMENT_URL;
}

function getAzureLoginUrlByLocation(location) {
  return isChinaRegion(location) ? AZURE_CHINA_LOGIN_URL : AZURE_LOGIN_URL;
}

module.exports = {
  isChinaRegion,
  AZURE_MANAGEMENT_URL,
  AZURE_CHINA_MANAGEMENT_URL,
  getAzureLoginUrlByLocation,
  AZURE_NSG_FLOW_LOGS_CONTAINER,
  AZURE_VNET_FLOW_LOGS_CONTAINER,
  getKentikAzureAuthUrlByLocation,
  getAzureManagementUrlByLocation
};
