import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Box from 'core/components/Box';
import Checkbox from 'core/components/Checkbox';
import Flex from 'core/components/Flex';
import Text from 'core/components/Text';
import Icon from 'core/components/Icon';
import styled from 'styled-components';
import Tooltip from 'core/components/Tooltip';
import CELL_ACTIONS from './CELL_ACTIONS';
import CELL_TYPES from './CELL_TYPES';
import { getCellStyle } from './Cell';

const StyledSortIcon = styled(Icon)`
  transition: transform 0.2s ease-out;
  transform: ${(props) => (props.direction === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const SelectAllCheckbox = (props) => {
  const { collection } = props;

  return (
    <Checkbox
      aria-label="Select all"
      indeterminate={
        !!(
          collection.selected &&
          collection.selected.length > 0 &&
          collection.selected.length < collection.models.length
        )
      }
      key="select"
      checked={!!collection.isAllSelected}
      onChange={() => null}
      onClick={(e) => {
        e.stopPropagation();
        collection.selectAll();
      }}
    />
  );
};

const HeaderCell = (props) => {
  const { collection, column, useSortTooltips, noHighlightSorted, onSort } = props;

  const { field, direction } = collection.sortState;
  const sortable = column.sortable !== false && column.type !== CELL_TYPES.ACTION;
  const hasOnHeaderClick = typeof column.onHeaderClick === 'function';
  const interactive = sortable || hasOnHeaderClick;
  const serverSortable = column.serverSortable === true && column.type !== CELL_TYPES.ACTION;
  const sorted = field && (field === column.name || field === column.sortField);
  const columnName = column.sortField || column.name;
  const selectAllCheckbox = (column.actions && column.actions.includes(CELL_ACTIONS.SELECT)) || column.customSelect;

  const onClick = () => {
    if (sortable) {
      collection[serverSortable ? 'serverSort' : 'sort'](columnName);

      // serverSort provides serverSortFn for callbacks; for sync sorting we use onSort
      if (onSort) {
        onSort({ field: collection.sortState.field, direction: collection.sortState.direction });
      }
    }
    if (hasOnHeaderClick) {
      column.onHeaderClick(column);
    }
  };

  const handleHeaderKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick();
    }
  };

  const className = classNames('th', { sortable, sorted, interactive, noHighlightSorted });
  const style = getCellStyle(props);
  const align = column.headerAlign || column.align;
  const padding = column.headerPadding ?? column.padding ?? '6px 0 6px 12px';
  const justifyContent = column.flexDirection !== 'column' ? column.justifyContent : undefined;
  const dirString = sorted && direction === 'asc' ? 'ascending' : 'descending';
  const ariaProps = {};
  if (dirString) {
    ariaProps['aria-sort'] = dirString;
  }
  let labelGuess = column.label || column.name;
  if (labelGuess?.length === 0 && column.type === 'action') {
    labelGuess = 'action options';
  }
  ariaProps['aria-label'] = labelGuess;
  return (
    <Flex
      {...ariaProps}
      className={className}
      onClick={onClick}
      css={{ cursor: interactive ? 'pointer' : 'default' }}
      style={style}
      alignItems="flex-end"
      justifyContent={justifyContent || (align === 'right' ? 'flex-end' : align)}
      p={padding}
      role="columnheader"
      onKeyDown={handleHeaderKeyDown}
    >
      {selectAllCheckbox && <SelectAllCheckbox collection={collection} />}
      {column.label || ''}
      {sorted && !useSortTooltips && <StyledSortIcon icon="chevron-up" direction={direction} />}
      {sorted && useSortTooltips && (
        <Tooltip content={<Text>{dirString}</Text>}>
          <StyledSortIcon icon="chevron-up" direction={direction} />
        </Tooltip>
      )}
      {!sorted && !selectAllCheckbox && sortable && align !== 'right' && <Box width={16} />}
    </Flex>
  );
};

export default observer(HeaderCell);
