import React, { Component } from 'react';
import PropTypes from 'prop-types';

import storeLoader from 'app/stores/storeLoader';
import formConsumer from 'core/form/formConsumer';
import Box from 'core/components/Box';
import Spinner from 'core/components/Spinner';

import NotificationUsageList from 'app/views/settings/notifications/NotificationUsageList';

@storeLoader('$notifications.supportedChannels')
@formConsumer
export default class WhereUsed extends Component {
  static propTypes = {
    model: PropTypes.object.isRequired
  };

  render() {
    const { loading, model, role } = this.props;

    if (loading) {
      return <Spinner />;
    }
    const ariaProps = {};
    if (role) {
      ariaProps.role = role;
    }

    return (
      <Box mb={1} {...ariaProps}>
        <NotificationUsageList asTable notificationModel={model} addDeleteColumn />
      </Box>
    );
  }
}
