import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Flex, Icon, showSuccessToast, Text } from 'core/components';
import PromptResponse from './PromptResponse';

@observer
class JourneyChat extends Component {
  componentDidMount() {
    const { journey, scrollToBottom } = this.props;
    if (journey.isNew || journey.isRequestActive()) {
      return;
    }
    journey.fetch().then(() => {
      scrollToBottom();
    });
  }

  handleDelete = (groupModel) => {
    const { journey } = this.props;
    return groupModel.destroyAll().then((success) => {
      if (success) {
        showSuccessToast(groupModel.messages.destroy, {
          action: {
            onClick: () =>
              groupModel
                .restoreAll({ journey })
                .then(() => journey.fetch({ force: true }))
                .catch((err) => console.error(err)),
            text: (
              <Flex alignItems="center">
                <Icon icon="reset" iconSize={12} />
                <Text ml={1}>Undo</Text>
              </Flex>
            )
          },
          timeout: 8000,
          ignoreLastMessage: true
        });
      }
    });
  };

  render() {
    const { journey, scrollToBottom, isScrolledToBottom } = this.props;

    return (
      <>
        {journey.promptGroupCollection.models.map((groupModel) => {
          const query = groupModel.get('models')?.[0];

          if (query.get('is_deleted')) {
            return null;
          }

          return (
            <PromptResponse
              key={groupModel.id}
              model={groupModel}
              query={query}
              loading={query.get('metadata.loading')}
              isOpen={groupModel.get('isOpen')}
              journey={journey}
              scrollToBottom={scrollToBottom}
              isScrolledToBottom={isScrolledToBottom}
              onDelete={() => this.handleDelete(groupModel)}
            />
          );
        })}
      </>
    );
  }
}

export default JourneyChat;
