/** NOTE: This component is shared with MKP!
 * Prevent regressions by spoofing as a tenant when testing. */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import storeLoader from 'app/stores/storeLoader';
import { isEqual, noop } from 'lodash';
import formatMetricValue from 'app/util/formatMetricValue';

import { AiFillBug, AiOutlineComment } from 'react-icons/ai';

import { formatDateTime } from 'core/util/dateUtils';
import { Box, Flex, Link, MenuDivider, Tag, Text } from 'core/components';
import DashboardLink from 'app/components/links/DashboardLink';
import AdminTable from 'app/components/admin/AdminTable';
import AlarmSwatch from 'app/views/alerting/components/AlarmSwatch';
import ClearAlarmButton from 'app/views/alerting/components/ClearAlarmButton';
import TargetRenderer from 'app/views/protect/ddos/analyze/components/TargetRenderer';
import { UnackAlertButton } from 'app/views/alerting/components/UnackAlertButton';
import {
  DeviceStatusTag,
  InterfaceOperAdminStatusTag,
  SessionStateRenderer
} from 'app/views/metrics/tables/StatusIndicators';

import { metricLabelRenderer } from 'app/components/metric/metricRenderers';
import MitigationLink from 'app/components/links/MitigationLink';
import AlertDebugDialog from 'app/views/alerting/components/AlertDebugDialog';
import { ALERT_TABLE_COLUMNS } from '@kentik/ui-shared/alerting/constants';
import AckDialog from './AckDialog';

import SilenceAlertButton from './SilenceAlertButton';
import SuppressAlertButton from './SuppressAlertButton';
import NmsNativeAlertDimensionRenderer from './nms/NmsNativeAlertDimensionRenderer';
import NmsNativeAlertMetricListRenderer from './nms/NmsNativeAlertMetricListRenderer';

const ROW_HEIGHT = 60;

@storeLoader('$alerting.policyCollection')
@inject('$app', '$auth', '$mkp', '$exports', '$comments', '$suppressions', '$users')
@observer
class AlertingTable extends Component {
  static propTypes = {
    onRowClick: PropTypes.func,
    showTenantAlerts: PropTypes.bool,
    tableProps: PropTypes.object,
    useVirtualizedTable: PropTypes.bool
  };

  static defaultProps = {
    onRowClick: noop,
    showTenantAlerts: false,
    tableProps: {},
    useVirtualizedTable: true
  };

  state = {
    debugModel: null,
    loadingSuppressions: true,
    loadingSilences: true
  };

  componentDidMount() {
    const { $app, $suppressions, $alerting, $exports, $users } = this.props;

    $exports.getSettings().then(({ sortState }) => {
      if (sortState && !isEqual($alerting.collection.sortState, sortState)) {
        $alerting.collection.serverSort(sortState.field, sortState.direction);
      }
    });

    if ($app.isSubtenant) {
      this.setState({ loadingSuppressions: false, loadingSilences: false });
    } else {
      $suppressions.collection.fetch().then(() => {
        this.setState({ loadingSuppressions: false });
      });
      $alerting.silenceCollection.fetch().then(() => {
        this.setState({ loadingSilences: false });
      });
      $users.collection.fetch();
    }
  }

  get defaultColumns() {
    const { $app } = this.props;

    const defaultColumns = ['severity', 'stateLabel', 'type', 'policyName', 'primaryDimension', 'metric', 'startTime'];

    if (!$app.isSubtenant) {
      defaultColumns.push('silenceModel', 'ackStateLabel');
    }

    return defaultColumns;
  }

  get columns() {
    const { $users, $app, $mkp, showTenantAlerts } = this.props;

    const columns = [
      {
        label: 'Severity',
        name: ALERT_TABLE_COLUMNS.SEVERITY,
        id: ALERT_TABLE_COLUMNS.SEVERITY,
        value: ALERT_TABLE_COLUMNS.SEVERITY,
        computed: true,
        width: 80,
        ellipsis: false,
        serverSortable: true,
        renderer: ({ model, value }) => (
          <Flex alignItems="center">
            <AlarmSwatch bg={model.severityColor} dot /> {value}
          </Flex>
        )
      },

      {
        label: 'Alert State',
        name: ALERT_TABLE_COLUMNS.STATE_LABEL,
        id: ALERT_TABLE_COLUMNS.STATE_LABEL,
        value: ALERT_TABLE_COLUMNS.STATE_LABEL,
        computed: true,
        width: 90,
        ellipsis: false,
        serverSortable: true,
        sortField: 'state',
        renderer: ({ model, value }) => (
          <Tag
            minimal
            intent={model.isActive ? 'danger' : 'success'}
            width="100%"
            round
            textAlign="center"
            fontWeight="500"
            py="4px"
          >
            {value}
          </Tag>
        )
      },

      {
        label: 'Type',
        name: ALERT_TABLE_COLUMNS.APPLICATION,
        id: ALERT_TABLE_COLUMNS.APPLICATION,
        value: ALERT_TABLE_COLUMNS.APPLICATION,
        computed: true,
        width: 100,
        ellipsis: false,
        serverSortable: true,
        renderer: ({ value }) =>
          value ? (
            <Tag minimal round>
              {value}
            </Tag>
          ) : null
      },

      {
        label: 'Policy',
        name: ALERT_TABLE_COLUMNS.POLICY_NAME,
        id: ALERT_TABLE_COLUMNS.POLICY_NAME,
        value: ALERT_TABLE_COLUMNS.POLICY_NAME,
        computed: true,
        flexBasis: 150,
        minWidth: 150,
        serverSortable: true,
        uiSortLogic: true
      },

      {
        label: 'Policy ID',
        name: ALERT_TABLE_COLUMNS.POLICY_ID,
        id: ALERT_TABLE_COLUMNS.POLICY_ID,
        value: ALERT_TABLE_COLUMNS.POLICY_ID,
        flexBasis: 80,
        minWidth: 80,
        serverSortable: true,
        uiSortLogic: true,
        renderer: ({ model }) => model.get('policy.id')
      },

      {
        label: 'Dimensions',
        name: ALERT_TABLE_COLUMNS.PRIMARY_DIMENSION,
        id: ALERT_TABLE_COLUMNS.PRIMARY_DIMENSION,
        value: ALERT_TABLE_COLUMNS.PRIMARY_DIMENSION,
        sortField: 'primary_dimension',
        flexBasis: 200,
        minWidth: 200,
        computed: true,
        ellipsis: false,
        serverSortable: true,
        sortable: true,
        renderer: ({ model }) =>
          model.isNmsPolicy ? (
            <NmsNativeAlertDimensionRenderer maxDimensionsToShow={3} alertModel={model} />
          ) : (
            <TargetRenderer model={model} />
          )
      },

      {
        label: 'Metric',
        name: ALERT_TABLE_COLUMNS.METRIC,
        id: ALERT_TABLE_COLUMNS.METRIC,
        value: ALERT_TABLE_COLUMNS.METRIC,
        sortable: false,
        ellipsis: false,
        flexBasis: 200,
        minWidth: 150,
        flexDirection: 'column',
        alignItems: 'flex-start',
        renderer: ({ model }) => (
          <Flex flexDirection="column" gap="4px" alignItems="flex-start">
            {this.renderMetricColumn(model)}
          </Flex>
        )
      },

      {
        label: 'Mitigation ID',
        name: ALERT_TABLE_COLUMNS.MITIGATION_ID,
        id: ALERT_TABLE_COLUMNS.MITIGATION_ID,
        value: ALERT_TABLE_COLUMNS.MITIGATION_ID,
        computed: true,
        flexBasis: 50,
        minWidth: 118,
        ellipsis: false,
        sortable: false,
        serverSortable: false,
        renderer: ({ value }) =>
          value ? (
            <MitigationLink mb={1} intent="primary" small blank id={value} isSubtenant={$app.isSubtenant}>
              {value}
            </MitigationLink>
          ) : (
            <Text muted>None</Text>
          )
      },

      {
        label: 'Alert ID',
        name: ALERT_TABLE_COLUMNS.ID,
        id: ALERT_TABLE_COLUMNS.ID,
        value: ALERT_TABLE_COLUMNS.ID,
        flexBasis: 150,
        minWidth: 150,
        ellipsis: false,
        serverSortable: true,
        sortField: 'alarm_id',
        renderer: ({ value }) => (
          <Link small to={`/v4/alerting/${value}`} blank>
            {value}
          </Link>
        )
      },
      {
        label: 'Time',
        name: ALERT_TABLE_COLUMNS.START_TIME,
        id: ALERT_TABLE_COLUMNS.START_TIME,
        value: ALERT_TABLE_COLUMNS.START_TIME,
        flexBasis: 60,
        minWidth: 180,
        ellipsis: false,
        flexDirection: 'column',
        alignItems: 'stretch',
        sortField: 'start_time',
        serverSortable: true,
        renderer: ({ model }) => {
          const startTime = model.get('start_time');
          const endTime = model.get('end_time');

          return (
            <>
              <Flex gap="4px">
                <Text muted>Start:</Text>
                <Text>{formatDateTime(startTime)}</Text>
              </Flex>

              {model.isCleared && (
                <Flex gap="4px">
                  <Text muted>Clear: </Text>
                  {endTime && <Text>{formatDateTime(endTime)}</Text>}
                </Flex>
              )}
              <Flex gap="4px">
                <Text as="div" muted>
                  Duration:
                </Text>
                <Text as="div">{model.displayDuration}</Text>
              </Flex>
            </>
          );
        }
      }
    ];

    if (!$app.isSubtenant) {
      columns.push(
        ...[
          {
            label: 'Tenant',
            name: ALERT_TABLE_COLUMNS.TENANT,
            id: ALERT_TABLE_COLUMNS.TENANT,
            value: ALERT_TABLE_COLUMNS.TENANT,
            computed: true,
            flexBasis: 80,
            minWidth: 150,
            sortable: false,
            hidden: !showTenantAlerts || $mkp.tenants.tenantAlertingOptions.length === 0
          },
          {
            label: 'Silence State',
            name: ALERT_TABLE_COLUMNS.SILENCE_MODEL,
            id: ALERT_TABLE_COLUMNS.SILENCE_MODEL,
            value: ALERT_TABLE_COLUMNS.SILENCE_MODEL,
            computed: true,
            sortable: false,
            width: 160,
            renderer: ({ value }) => {
              const silenceModel = value;

              if (silenceModel) {
                const { startTime, startDetails = {}, expirationDetails = {} } = silenceModel;
                return (
                  <Box>
                    <Text as="div">
                      Silence
                      {startDetails.started ? `d for ${expirationDetails.duration}` : ' pending'}
                    </Text>
                    {startDetails.started && (
                      <Text as="div" muted>
                        Started: {startTime}
                      </Text>
                    )}
                    {!startDetails.started && (
                      <Text as="div" muted>
                        Starts: {startTime}
                      </Text>
                    )}

                    <Text as="div" muted>
                      Ends: {expirationDetails.expirationDay}
                    </Text>
                  </Box>
                );
              }

              return 'Not Silenced';
            }
          },
          {
            label: 'Ack State',
            name: ALERT_TABLE_COLUMNS.ACK_STATE_LABEL,
            id: ALERT_TABLE_COLUMNS.ACK_STATE_LABEL,
            value: ALERT_TABLE_COLUMNS.ACK_STATE_LABEL,
            computed: true,
            sortable: false,
            flexBasis: 100,
            minWidth: 130,
            renderer: ({ model, value }) => {
              const userModel = $users.collection.get(model.ackUserId);
              // userModel should be present but we do not want to throw an error if not
              return userModel ? (
                <Box>
                  <Text as="div">{model.isAutoAcked ? 'Auto-acked' : value}</Text>
                  {model.isAcked && (
                    <>
                      <Text as="div" muted>
                        by {userModel.get('user_full_name')}
                      </Text>
                      <Text as="div" muted>
                        {formatDateTime(model.ackTimestamp)}
                      </Text>
                    </>
                  )}
                </Box>
              ) : (
                value || null
              );
            }
          }
        ]
      );
    }

    return columns;
  }

  get kebabActions() {
    const { $app, $alerting, miniKebab } = this.props;
    const { loadingSuppressions, loadingSilences } = this.state;

    const viewDetails = {
      id: 'view-details',
      label: 'View Alert Details',
      icon: 'zoom-in',
      handler: (model) => this.handleViewDetails(model)
    };

    if ($app.isExport) {
      return undefined;
    }

    if ($app.isSubtenant || miniKebab) {
      return [viewDetails];
    }

    return [
      viewDetails,
      {
        id: 'divider1',
        renderer: () => <MenuDivider key="divider1" />
      },
      {
        id: 'acknowledge',
        hidden: (model) => !model.canAck || model.isAcked,
        icon: 'confirm',
        label: 'Ack Alert',
        handler: (model) => this.handleAckAlertDialog(model)
      },
      {
        id: 'clear',
        hidden: (model) => model.isCleared,
        renderer: (model) => <ClearAlarmButton models={model} asMenuItem key="clear" />
      },
      {
        id: 'unacknowledge',
        hidden: (model) => !model.canAck || !model.isAcked,
        renderer: (model) => <UnackAlertButton model={model} asMenuItem key="unacknowledge" />
      },
      {
        id: 'silence',
        hidden: (model) => !model.canSilence || loadingSilences,
        renderer: (model) => <SilenceAlertButton alertModel={model} asMenuItem key="silence" />
      },
      {
        id: 'suppress',
        hidden: (model) => !model.canSuppress || loadingSuppressions,
        renderer: (model) => (
          <SuppressAlertButton
            key="suppress"
            mr={0}
            alertModel={model}
            asMenuItem
            comment="Alert"
            buttonProps={{ icon: null }}
          />
        )
      },
      {
        id: 'comment',
        icon: AiOutlineComment,
        label: 'Add Comment',
        handler: (model) => this.handleComment(model)
      },
      {
        id: 'divider2',
        renderer: () => <MenuDivider key="divider2" />,
        hidden: (model) => model.isMetricPolicy || model.isNmsPolicy
      },
      {
        id: 'view-dashboard',
        label: 'Open Dashboard',
        hidden: (model) => {
          const policy = model.get('policy');

          return model.isMetricPolicy || (policy && !policy.get('dashboardID'));
        },
        renderer: (model) => {
          const policy = model.get('policy');

          // TODO in theory hidden ensures that policy is always present, however there may be race conditions
          return policy ? (
            <DashboardLink
              as="MenuItem"
              key="dashboard"
              withIcon
              id={policy.get('dashboardID')}
              query={$alerting.getDashboardQuery(model)}
            />
          ) : null;
        }
      },
      {
        id: 'debug',
        icon: AiFillBug,
        label: 'Debug Alert',
        handler: (model) => this.handleDebug(model)
      }
    ];
  }

  handleComment = (model) => {
    const { onHandleComment } = this.props;

    onHandleComment(model);
  };

  renderMetricColumn = (model) => {
    // NMS Alert
    if (model.isNmsPolicy) {
      return <NmsNativeAlertMetricListRenderer alertModel={model} numMetricsToShow={2} />;
    }

    if (model.isEventPolicy) {
      return metricLabelRenderer(model.policy.metrics[0], null, {
        round: true,
        fontWeight: 'medium',
        p: '2px 6px'
      });
    }

    const metricToValue = model.get('metricToValue') || {};

    return model.sortedMetrics.slice(0, 1).map((metricKey) => {
      const metricStorageEntry = model.policyMeasurement?.storage?.Metrics[metricKey] || {};
      if (model.isStateChangeAlert && !model.isCustomStateChangeAlert) {
        const values = metricStorageEntry?.Values || {};
        const value = values?.[metricToValue[metricKey]];
        return (
          <Fragment key={metricKey}>
            <Flex gap="4px" alignItems="center" flexWrap="wrap">
              <Text as="div" whiteSpace="noWrap" ellipsis muted>
                {metricStorageEntry.Label}:
              </Text>
              {model.isDeviceStateChangeAlert && <DeviceStatusTag value={value} />}
              {model.isInterfaceStateChangeAlert && <InterfaceOperAdminStatusTag value={value} showPrefix />}
              {model.isBGPNeighborStateChangeAlert && <SessionStateRenderer availableStates={values} value={value} />}
            </Flex>
          </Fragment>
        );
      }

      const metricFormat = formatMetricValue(metricToValue[metricKey], metricKey);
      return <Text key={metricKey}>{`${metricFormat.value} ${metricStorageEntry.Label || metricFormat.metric}`}</Text>;
    });
  };

  handleViewDetails = (model) => {
    const path = `/v4/alerting/${model.id}`;
    window.open(path, '_blank');
  };

  handleOnSort = (sortState) => {
    const { $exports } = this.props;

    $exports.setHash({ sortState });
  };

  handleDebug = (model) => {
    this.setState({ debugModel: model });
  };

  handleAckAlertDialog = (model) => {
    this.setState({ ackAlertModel: model });
  };

  handleSubmitAckAlert = (formValues) => {
    const { $comments, $alerting } = this.props;
    const { ackAlertModel } = this.state;
    const { comment, autoAck, silence } = formValues || {};
    this.handleAckAlertDialog(null);
    $alerting.acknowledgeAlarm(ackAlertModel, { comment, autoAck, silence, toast: true }).then(() => {
      $comments.collection.fetch({ force: true });
    });
  };

  render() {
    const { $alerting, $app, useVirtualizedTable, onRowClick, tableProps, collection } = this.props;
    const { debugModel, ackAlertModel } = this.state;
    return (
      <>
        <AdminTable
          noVirtualizedTable={useVirtualizedTable === false || $app.isExport}
          collection={collection || $alerting.collection}
          defaultColumns={this.defaultColumns}
          columns={this.columns}
          fetchCollectionOnMount={false}
          kebabActions={this.kebabActions}
          maxVisibleColumns={11}
          onRowClick={onRowClick}
          onSort={this.handleOnSort}
          rowAlign="center"
          rowHeight={ROW_HEIGHT}
          searchBar={false}
          showColumnSelector={!$app.isExport}
          tabPrefId="alertManagerAlertingTable"
          {...tableProps}
        />
        {!!debugModel && (
          <AlertDebugDialog isOpen={!!debugModel} alertModel={debugModel} onClose={() => this.handleDebug(null)} />
        )}
        {!!ackAlertModel && (
          <AckDialog
            isOpen={!!ackAlertModel}
            alertModel={ackAlertModel}
            onClose={() => this.handleAckAlertDialog(null)}
            onSubmit={this.handleSubmitAckAlert}
          />
        )}
      </>
    );
  }
}

export default AlertingTable;
