import React from 'react';
import { observer } from 'mobx-react';
import { Box, Link } from 'core/components';
import { Field, InputGroup } from 'core/form';

const Integrations = ({ role }) => {
  const ariaProps = {};
  if (role) {
    ariaProps.role = role;
  }
  return (
    <Box overflow="auto" p={2} {...ariaProps}>
      <Field
        name="device_alert"
        mb={0}
        helpText={
          <>
            Enter the URL for your installed instance of Kentik&apos;s ktranslate agent. Kentik will stream enriched
            traffic data (correlated flow, SNMP, GeoIP, BGP, etc.) from the Kentik Data Engine to that agent, which will
            enable you to integrate the data into other analytics systems, either directly or through a data lake (see{' '}
            <Link blank to="https://kb.kentik.com/Fc19.htm">
              {' '}
              Kentik Firehose{' '}
            </Link>{' '}
            in the KB).
          </>
        }
      >
        <InputGroup width={450} />
      </Field>
    </Box>
  );
};

export default observer(Integrations);
