import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Icon, Select, Text } from 'core/components';

@inject('$capacity')
@observer
export default class SeverityFilters extends Component {
  valueRenderer = (props) => {
    const { className, color, icon, key, label, style, value } = props;
    const iconId = `SeverityFilters-icon-${label}`;
    const ariaProps = {};

    if (label) {
      ariaProps['aria-label'] = label;
      ariaProps['aria-labelledby'] = iconId;
    }

    return (
      <Flex key={key || value} className={className} style={style}>
        <Icon pr={1} icon={icon} color={color} ariaLabel={label} id={iconId} />
        <Text {...ariaProps}>{label}</Text>
      </Flex>
    );
  };

  optionRenderer = (props) => {
    const {
      className,
      color,
      disabled,
      icon,
      key,
      label,
      selectItem,
      selected,
      style,
      value,
      focused,
      handleOptionRef
    } = props;
    const onClick = !selected && !disabled ? () => selectItem(value) : undefined;
    const iconId = `SeverityFilters-icon-${label}`;
    const ariaProps = {};

    if (label) {
      ariaProps['aria-label'] = label;
      ariaProps['aria-labelledby'] = iconId;
    }

    if (focused && handleOptionRef) {
      return (
        <Flex key={key || value} className={className} style={style} tabIndex="0" ref={handleOptionRef}>
          <Icon pr={1} icon={icon} color={color} ariaLabel={label} id={iconId} />
          <Text {...ariaProps}>{label}</Text>
        </Flex>
      );
    }

    return (
      <Flex key={key || value} className={className} onClick={onClick} style={style}>
        <Icon pr={1} icon={icon} color={color} ariaLabel={label} />
        <Text {...ariaProps}>{label}</Text>
      </Flex>
    );
  };

  render() {
    const { $capacity, onChange, selectedFilter } = this.props;
    return (
      <Select
        valueRenderer={this.valueRenderer}
        optionRenderer={this.optionRenderer}
        width="310px"
        multi
        placeholder="Select severities"
        values={selectedFilter}
        options={Object.values($capacity.severityTypes)}
        onChange={onChange}
        ariaLabel="Severity Filters"
      />
    );
  }
}
