import React from 'react';
import { Popover as BlueprintPopover } from '@blueprintjs/core';

const Component = (props) => <BlueprintPopover {...props} />;

Component.displayName = 'Popover';

Component.defaultProps = {
  minimal: true,
  targetTagName: 'div',
  shouldReturnFocusOnClose: true,
  modifiers: {
    offset: {
      enabled: true,
      offset: '0, 4px'
    }
  }
};

export default Component;
