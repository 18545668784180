import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';
import {
  borderRadius,
  color,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  opacity,
  position,
  space,
  textAlign,
  top
} from 'styled-system';
import { transparentize } from 'polished';
import flexHelper from './utils/flex';
import size from './utils/size';
import { textTransform } from './utils/typography';
import Icon from './Icon';
import Text from './Text';
import { guessByChildText } from '../util/ariaUtils';

const TINY = 'Tiny';

export const TAG_SMALL_ROUND_PADDING = 7;

const StyledTag = styled.span`
  &.${Classes.TAG} {
    ${borderRadius};
    ${color};
    ${flexHelper};
    ${fontFamily};
    ${fontSize};
    ${fontWeight};
    ${lineHeight};
    ${size};
    ${space};
    ${textAlign};
    ${position};
    ${textTransform};
    ${top};
    ${opacity};
  }

  &.${Classes.SMALL} {
    font-size: 12px;
    line-height: 14px;
    padding: 0 4px;
  }

  &.${TINY} {
    font-size: 9px;
    line-height: 9px;
    padding: 0 6px;

    min-width: 16px;
    min-height: 15px;
  }

  /* round padding needs some adjustment */
  &.${Classes.TAG}.${Classes.ROUND} {
    padding-left: 8px;
    padding-right: 8px;

    &.${Classes.SMALL} {
      padding-left: ${TAG_SMALL_ROUND_PADDING}px;
      padding-right: ${TAG_SMALL_ROUND_PADDING}px;
    }

    &.${TINY} {
      padding-left: 6px;
      padding-right: 6px;
    }

    &.${Classes.LARGE} {
      padding-left: 12px;
      padding-right: 12px;
    }

    > .${Classes.ICON} {
      color: unset;
    }
  }

  /* circle, assumes center-able text*/
  &.${Classes.TAG}.circle {
    border-radius: 50%;
    padding: 0;
    text-align: center;

    &.${Classes.SMALL} {
      padding: 3px 6px;
    }

    &.${Classes.LARGE} {
      font-size: 14px;
      min-width: 26px;
      min-height: 26px;
    }
  }

  &.${Classes.MULTILINE} .${Classes.TAG_REMOVE} {
    align-self: flex-start;
  }

  &.bp4-minimal {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.bp4-minimal {
    .bp4-icon.tag-icon svg {
      fill: #aeb8c0 !important;
    }
  }

  &.bp4-minimal.bp4-intent-success {
    color: #006d00;
    background-color: #ddf5d3;

    .bp4-icon.tag-icon svg {
      fill: #29a200 !important;
    }
  }

  &.bp4-minimal.bp4-intent-primary {
    background-color: #d4e8fc;
    color: #0e3da8;

    .bp4-icon.tag-icon svg {
      fill: #096ed9 !important;
    }
  }

  &.bp4-minimal.bp4-intent-warning {
    background-color: #fcfad2;
    color: #714523;

    .bp4-icon.tag-icon svg {
      fill: #e57f30 !important;
    }
  }

  &.bp4-minimal.bp4-intent-danger {
    background-color: #f7cbe0;
    color: #860059;

    .bp4-icon.tag-icon svg {
      fill: #c30066 !important;
    }
  }

  .bp4-dark &.bp4-minimal.bp4-intent-primary {
    background-color: #142f4d;
    color: #b9dbfe;
  }

  .bp4-dark &.bp4-minimal.bp4-intent-success {
    background-color: #0e390e;
    color: #d0efc0;
  }

  .bp4-dark &.bp4-minimal.bp4-intent-danger {
    background-color: #5c3549;
    color: #f7cbe0;
  }

  .bp4-dark &.bp4-minimal.bp4-intent-warning {
    background-color: #463407;
    color: #fcf8bf;
  }

  .${Classes.TAG} {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${({ minimal, outline }) =>
    !minimal &&
    !outline &&
    css`
      &.${Classes.TAG} {
        &.${Classes.INTENT_WARNING} {
          background-color: ${({ theme: { colors } }) => colors.warning};
        }

        &.${Classes.INTENT_SUCCESS} {
          background-color: ${({ theme: { colors } }) => colors.success};
        }

        &.${Classes.INTENT_PRIMARY} {
          background-color: ${({ theme: { colors } }) => colors.primary};
        }

        &.${Classes.INTENT_DANGER} {
          background-color: ${({ theme: { colors } }) => colors.danger};
        }
      }
    `}

  ${({ theme, outline }) =>
    outline &&
    css`
      &.${Classes.TAG} {
        border: 1px solid transparent;

        &.card.card {
          color: ${({ intent }) => theme.colors[intent] || theme.colors.body};
          background-color: ${theme.name === 'dark' ? theme.colors.cardBackground : '#fff'};
          border: ${({ intent }) => (intent ? `1px solid ${theme.colors[intent]}` : theme.borders.thin)};
        }

        ${({ muted }) =>
          muted &&
          css`
            background-color: ${theme.colors.tags.muted};
            color: ${theme.colors.muted};
          `}

        &.${Classes.INTENT_WARNING} {
          background-color: ${transparentize(0.85, theme.colors.warning)};
          color: ${theme.textIntents.warning};
        }

        &.${Classes.INTENT_SUCCESS} {
          background-color: ${transparentize(0.85, theme.colors.success)};
          color: ${theme.textIntents.success};
        }

        &.${Classes.INTENT_PRIMARY} {
          background-color: ${transparentize(0.85, theme.colors.primary)};
          color: ${theme.textIntents.primary};
        }

        &.${Classes.INTENT_DANGER} {
          background-color: ${transparentize(0.85, theme.colors.danger)};
          color: ${theme.textIntents.danger};
        }
      }
    `}

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;
    `}
`;

StyledTag.defaultProps = {
  p: '2px 6px'
};

function Tag({
  active,
  ariaLabel,
  children,
  circle,
  className,
  fill,
  icon,
  intent,
  interactive,
  large,
  minimal,
  monospace,
  multiline,
  rightIcon,
  round,
  small,
  onRemove,
  iconProps,
  ellipsis,
  muted,
  ...rest
}) {
  const theme = useContext(ThemeContext) || {};
  const isDarkMode = theme.name === 'dark';
  const style = {};
  if (muted) {
    style.opacity = isDarkMode ? '65%' : '80%';
    style.bg = 'lightGray1';
    style.color = 'blackText';
  }

  const ariaLabelText = ariaLabel || guessByChildText(children);
  const ariaProps = {};
  if (ariaLabelText) {
    ariaProps['aria-label'] = ariaLabelText;
  }

  const handleRemoveKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onRemove(e);
    }
  };
  return (
    <StyledTag
      className={classNames(Classes.TAG, className, {
        [Classes.ACTIVE]: active,
        [Classes.FILL]: fill,
        [Classes.INTERACTIVE]: interactive,
        [Classes.intentClass(intent)]: intent,
        [Classes.LARGE]: large,
        [Classes.MINIMAL]: minimal,
        [Classes.MONOSPACE_TEXT]: monospace,
        [Classes.MULTILINE]: multiline,
        [Classes.ROUND]: round,
        [Classes.SMALL]: small,
        circle
      })}
      {...ariaProps}
      intent={intent}
      minimal={minimal}
      {...style}
      {...rest}
    >
      {icon && <Icon className="tag-icon" icon={icon} iconSize={small ? 12 : 14} mr="4px" {...iconProps} />}
      <Text
        className={classNames(Classes.FILL, {
          [Classes.TEXT_OVERFLOW_ELLIPSIS]: ellipsis
        })}
        small={small}
        monospace={monospace}
      >
        {children}
      </Text>
      {rightIcon && <Icon className="tag-icon" icon={rightIcon} iconSize={small ? 12 : 14} />}
      {onRemove && (
        <Icon
          className={Classes.TAG_REMOVE}
          aria-label={`Remove ${ariaLabelText}`}
          aria-hidden="false"
          tabindex="0"
          icon="small-cross"
          onClick={onRemove}
          ml="-4px"
        />
      )}
    </StyledTag>
  );
}

Tag.defaultProps = {
  justifyContent: 'center',
  ellipsis: true
};

export const TagClasses = {
  TINY
};

export default Tag;
