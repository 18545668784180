import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import storeLoader from 'app/stores/storeLoader';
import moment from 'moment';
import { Button, FlexColumn, Text, Tooltip, MenuItem } from 'core/components';
import { POLICY_APPLICATIONS, POLICY_APPLICATION_LABELS, SUPPRESSION_TYPES } from 'shared/alerting/constants';

@storeLoader('$suppressions')
@inject('$auth', '$alerting', '$dictionary', '$metrics')
@observer
export default class SuppressAlertButton extends Component {
  static defaultProps = {
    comment: '',
    disabled: false
  };

  state = {
    createRequestPending: false
  };

  filterDimensions = (dimensionsToKeyPart) =>
    Object.entries(dimensionsToKeyPart).filter(([, value]) => value !== '' && value !== undefined && value !== '---');

  getDimensionLabel = (dimensionKey) => {
    const { $metrics, $dictionary, alertModel } = this.props;

    if (alertModel.policy?.isMetricPolicy) {
      return $metrics.getDimensionLabelForPolicy(alertModel.policy, dimensionKey);
    }

    return $dictionary.dimensionLabelsMap[dimensionKey]?.label || dimensionKey;
  };

  handleCreateSuppression = () => {
    const { $suppressions, alertModel } = this.props;

    this.setState({ createRequestPending: true });
    const { id, policyName, pattern } = alertModel;
    const { measurement, application, dimensionToKeyPart } = pattern;

    const data = {
      application,
      expirationTime: moment().add(7, 'days'),
      dimensionToKeyPart,
      comment: `${POLICY_APPLICATION_LABELS[application] || application}: "${policyName || ''}" (Alert ID: ${id})`,
      type: SUPPRESSION_TYPES.CONDITION,
      strict: false,
      applicationMetadata: { application, type: SUPPRESSION_TYPES.CONDITION }
    };

    if (application === POLICY_APPLICATIONS.METRIC) {
      data.type = SUPPRESSION_TYPES.METRIC; // Needed if we navigate to suppressions without reloading/refetching
      data.applicationMetadata.type = SUPPRESSION_TYPES.METRIC;
      data.applicationMetadata.measurement = measurement;
    }

    if (application === POLICY_APPLICATIONS.NMS) {
      data.type = SUPPRESSION_TYPES.NMS;
      data.applicationMetadata.type = SUPPRESSION_TYPES.NMS;
      data.applicationMetadata.measurement = measurement;
    }

    const suppression = $suppressions.collection.build(data, { deserialize: false, select: true });

    suppression.create().then((r) => {
      if (r.suppression) {
        this.setState({ createRequestPending: false });
      }
    });
  };

  get matchingSuppression() {
    const { $alerting, $suppressions, alertModel } = this.props;
    const dimensionToKeyPart = alertModel.get('dimensionToKeyPart');

    return $suppressions.collection.unfiltered.find((suppression) => {
      const type = suppression.get('type');
      const suppressionDimensions = suppression.dimensions;

      if (type === 'policy' || !suppressionDimensions || suppression.expired) {
        return false;
      }

      return $alerting.matchesKeyValuePattern({
        pattern: suppressionDimensions,
        target: dimensionToKeyPart
      });
    });
  }

  renderTooltipText = () => {
    const { alertModel } = this.props;

    if (this.matchingSuppression) {
      const { startTime, startDetails, expirationDetails } = this.matchingSuppression;

      return startDetails.started ? (
        <>
          <Text>Alert is already suppressed:</Text>
          <Text>
            <b>Started:</b> {startTime} ({startDetails.diff} ago)
          </Text>
          <Text>
            <b>Ends:</b> {expirationDetails.expirationDay} (in {expirationDetails.duration})
          </Text>
        </>
      ) : (
        <>
          <Text>Alert has a pending suppression:</Text>
          <Text>
            <b>Starts:</b> {startTime} (in {startDetails.diff})
          </Text>
          <Text>
            <b>Ends:</b> {expirationDetails.expirationDay} (in {expirationDetails.duration})
          </Text>
        </>
      );
    }

    if (alertModel.isCleared) {
      return <Text>Suppress this alert for 7 days:</Text>;
    }

    return <Text>Clear this alert, and suppress it for 7 days:</Text>;
  };

  render() {
    const { $auth, alertModel, asMenuItem, buttonProps, disabled } = this.props;
    const { createRequestPending } = this.state;
    const dimensionToKeyPart = alertModel.get('dimensionToKeyPart');
    const isAllowedToCreateSuppression = $auth.hasPermission('alerts.silentMode.create');

    if (!dimensionToKeyPart || !alertModel.canSuppress) {
      return null;
    }

    const filteredDimensions = this.filterDimensions(dimensionToKeyPart);
    const isDisabled = createRequestPending || this.matchingSuppression || !isAllowedToCreateSuppression || disabled;

    const ButtonComp = (
      <Button
        mr={0}
        width="100%"
        icon="pause"
        onClick={this.handleCreateSuppression}
        disabled={isDisabled}
        text="Suppress Alert"
        textAlign="left"
        {...buttonProps}
      />
    );

    const MenuItemComp = (
      <MenuItem icon="pause" disabled={isDisabled} text="Suppress Alert" onClick={this.handleCreateSuppression} />
    );

    const ClickElement = asMenuItem ? MenuItemComp : ButtonComp;

    return createRequestPending ? (
      ClickElement
    ) : (
      <Tooltip
        fill
        content={
          <FlexColumn>
            {this.renderTooltipText()}
            {!this.matchingSuppression &&
              filteredDimensions.slice(0, 4).map(([dimensionKey, dimensionValue], index) =>
                index === 3 && filteredDimensions.length > 4 ? (
                  <Text key="plusmore" fontWeight="bold">
                    Plus {filteredDimensions.length - 3} more
                  </Text>
                ) : (
                  <Text key={`${dimensionKey}-${dimensionValue}`}>
                    <b>{this.getDimensionLabel(dimensionKey)}:</b> {dimensionValue}
                  </Text>
                )
              )}
          </FlexColumn>
        }
      >
        {ClickElement}
      </Tooltip>
    );
  }
}
