import styled from 'styled-components';
import { color, space } from 'styled-system';
import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';
import React from 'react';
import size from './utils/size';
import { guessByChildText } from '../util/ariaUtils';

const StyledButtonLink = styled.button`
  ${color};
  ${size};
  ${space};

  appearance: none;
  background: none;
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: inline;

  &:hover {
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
  }
`;

const ButtonLink = ({
  className,
  monospace,
  runningText,
  large,
  small,
  showSkeleton,
  muted,
  disabled,
  ellipsis,
  tracker,
  maxWidth,
  ...rest
}) => {
  let { fontSize: fontSizeProp } = rest;
  if (monospace && small) {
    fontSizeProp = 10;
  }

  const ariaProps = {};
  const ariaLabelGuess = rest.text || guessByChildText(rest.children);

  if (rest?.ariaLabel || ariaLabelGuess) {
    ariaProps['aria-label'] = rest?.ariaLabel || ariaLabelGuess;
  }
  if (disabled) {
    ariaProps['aria-disabled'] = true;
  }

  return (
    <StyledButtonLink
      {...ariaProps}
      {...rest}
      disabled={disabled}
      color={disabled ? undefined : rest.color}
      fontSize={fontSizeProp}
      maxWidth={maxWidth || (ellipsis ? '100%' : undefined)}
      className={classNames(
        {
          [Classes.MONOSPACE_TEXT]: monospace,
          [Classes.RUNNING_TEXT]: runningText,
          [Classes.TEXT_LARGE]: large,
          [Classes.TEXT_SMALL]: small,
          [Classes.TEXT_MUTED]: muted,
          [Classes.TEXT_DISABLED]: disabled,
          [Classes.TEXT_OVERFLOW_ELLIPSIS]: ellipsis,
          [Classes.SKELETON]: showSkeleton
        },
        className,
        tracker
      )}
    />
  );
};

ButtonLink.defaultProps = {
  color: 'primary',
  m: 0,
  p: 0
};

export default ButtonLink;
