import React from 'react';
import { Box } from 'core/components';

export function ValueCell({ renderer, model, value, loading, queryResultsCollection, column }) {
  return (
    <Box as="td" textAlign="right" color={loading ? 'muted' : 'body'} role="cell">
      {renderer({
        collection: queryResultsCollection,
        model,
        name: column,
        value: model.get(value)
      })}
    </Box>
  );
}
