import React, { Component } from 'react';
import styled from 'styled-components';
import { space, width, maxWidth } from 'styled-system';
import { Box } from 'core/components';
import withUrlPathTabs from 'core/components/withUrlPathTabs';

const TabsWrapper = styled.div`
  display: flex;
  position: relative;
  ${width};
  ${maxWidth};
  border: none;
  border-bottom: ${({ theme, borderless }) => (borderless ? 'none' : `1px solid ${theme.borderColors.tabs}`)};
  margin-bottom: 16px;
  min-height: fit-content;
  ${space}
`;

const TabsInner = styled.div`
  margin-bottom: -1px;
  display: flex;
`;

@withUrlPathTabs
class BorderedTabs extends Component {
  static defaultProps = {
    minimal: false,
    selectedTabBorderColor: 'primary',
    selectedTabFontColor: 'body',
    width: '100%',
    maxWidth: '100%',
    borderless: false
  };

  state = {
    selectedTabId: this.getInitialSelectedTabId()
  };

  static getDerivedStateFromProps({ selectedTabId }) {
    if (selectedTabId !== undefined) {
      // keep state in sync with controlled prop, so state is canonical source of truth
      return { selectedTabId };
    }
    return null;
  }

  getInitialSelectedTabId() {
    const { defaultSelectedTabId, selectedTabId, children } = this.props;

    if (selectedTabId !== undefined) {
      return selectedTabId;
    }

    if (defaultSelectedTabId !== undefined) {
      return defaultSelectedTabId;
    }

    const tabs = React.Children.toArray(children);
    return tabs.length === 0 ? undefined : tabs[0].props.id;
  }

  handleTabClick = (newTabId) => {
    const { onChange, selectedTabId } = this.props;
    if (onChange) {
      onChange(newTabId);
    }

    if (selectedTabId === undefined) {
      this.setState({ selectedTabId: newTabId });
    }
  };

  render() {
    const { selectedTabId } = this.state;
    const {
      children,
      selectedTabBorderColor,
      selectedTabBackgroundColor,
      selectedTabFontColor,
      minimal,
      large,
      tabIndent,
      trailingActions,
      borderless,
      ...rest
    } = this.props;

    const tabs = React.Children.toArray(children);
    const activeTab = tabs.find((tab) => tab.props.id === selectedTabId);

    return (
      <>
        <TabsWrapper borderless={borderless} {...rest} role="tablist">
          <TabsInner>
            {React.Children.map(children, (child) =>
              child
                ? React.cloneElement(child, {
                    selectedTabId,
                    onClick: this.handleTabClick,
                    minimal,
                    large,
                    selectedTabBorderColor,
                    selectedTabBackgroundColor,
                    selectedTabFontColor,
                    tabIndent,
                    borderless
                  })
                : null
            )}
          </TabsInner>
          <Box ml="auto">{trailingActions}</Box>
        </TabsWrapper>
        {activeTab && activeTab.props.panel}
      </>
    );
  }
}

export default BorderedTabs;
