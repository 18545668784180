import { omit } from 'lodash';

const fieldProps = [
  'autoFocus',
  'field',
  'hasEditLink',
  'isEditing',
  'noEditLink',
  'onEditComplete',
  'options',
  'rules',
  'showFieldLabel',
  'showOptionLabel',
  'showBoxLabel',
  'switchLabel',
  'onQuery',
  'form',
  'loading',
  'isOpen',
  'autoGrow'
];

const ariaProps = ['ariaLabel', 'ariaRequired', 'labelId', 'helperTextId', 'inputRef', 'isRequired'];

export default function omitFieldProps(props) {
  return omit(props, fieldProps);
}

export function omitAriaProps(props) {
  return omit(props, ariaProps);
}

// This removes the field and aria props when sending props/rest directly to a blueprint component
export function omitFieldAndAriaProps(props) {
  const allConcerns = fieldProps.concat(ariaProps);
  return omit(props, allConcerns);
}
