import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Field, InputGroup, Select, Switch, formConsumer, PasswordInput } from 'core/form';
import { Flex, Tag, Text, Icon, Callout, Link, CalloutOutline } from 'core/components';
import { defaultOptionRenderer } from 'core/components/select/SelectPopover';
import storeLoader from 'app/stores/storeLoader';

const tetherOptions = {
  offset: '-3px 0',
  constraints: [{ attachment: 'together', pin: true, to: 'window' }]
};

const DeviceOptionRenderer = (props) => {
  const { className, disabled, key, label, selectItem, selected, style, value } = props;
  if (value !== 'device') {
    return defaultOptionRenderer(props);
  }

  const onClick = !selected && !disabled ? () => selectItem(value) : undefined;

  return (
    <Flex justifyContent="space-between" key={key || value} className={className} onClick={onClick} style={style}>
      {label}
      <Tag small px="6px" intent="primary">
        Required for RTBH/FlowSpec
      </Tag>
    </Flex>
  );
};

const DeviceValueRenderer = (option, placeholder) => {
  if (!option) {
    return <Text muted>{placeholder || 'Select a value...'}</Text>;
  }

  const { iconCls, iconName, className, label } = option;

  if (label === 'Peer with device') {
    return (
      <Text as={Flex} justifyContent="space-between" ellipsis className={className} style={{ width: '100%' }}>
        <span style={{ padding: 2 }}>{label}</span>
        <Tag small px="6px" intent="primary">
          Required for RTBH/FlowSpec
        </Tag>
      </Text>
    );
  }

  return (
    <span className={className}>
      {(iconCls || iconName) && <Icon icon={iconCls || iconName} style={{ marginRight: 6 }} />}
      {label}
    </span>
  );
};

@formConsumer
@storeLoader('$mitigations.activeMitigationCollection')
@inject('$devices', '$dictionary', '$auth')
@observer
class BGPSettings extends Component {
  state = {
    associatedMitigationPlatforms: [],
    associatedActiveMitigations: []
  };

  constructor(props) {
    super(props);
    const { form } = props;
    const device_bgp_type = form.getValue('device_bgp_type');

    if (device_bgp_type === 'device') {
      const associatedMitigationPlatforms = this.getAssociatedMitigationPlatforms();
      const associatedActiveMitigations = this.getActiveMitigationsForDevice();
      const disableCondition = associatedActiveMitigations.length || associatedMitigationPlatforms.length;

      form.getField('device_bgp_type')[disableCondition ? 'disable' : 'enable']();
      form.getField('device_bgp_flowspec')[disableCondition ? 'disable' : 'enable']();

      // eslint-disable-next-line react/state-in-constructor
      this.state = { associatedActiveMitigations, associatedMitigationPlatforms };
    }
  }

  getAssociatedMitigationPlatforms = () => {
    const { $mitigations, form } = this.props;
    const { id, isNew } = form.model;
    if (!isNew) {
      return $mitigations.getAssociatedMitigationPlatforms(id);
    }
    return [];
  };

  getActiveMitigationsForDevice = () => {
    const { form, $mitigations } = this.props;

    return $mitigations.getActiveMitigationsUsingDevice(`${form.model.id}`);
  };

  get bgpLookupOptions() {
    const { $auth, form } = this.props;

    const extraOptions = {
      vrf_only: {
        label: 'VPN table only (sudoers only)',
        value: 'vrf_only'
      },
      lu_only: {
        label: 'Labeled-Unicast table only (sudoers only)',
        value: 'lu_only'
      },
      global_only: {
        label: 'Unicast table only (sudoers only)',
        value: 'global_only'
      }
    };
    const options = [
      {
        label: 'VPN table for VRF interface, Unicast table for non-VRF interface',
        value: 'auto'
      },
      {
        label: 'VPN table, fallback to Unicast table',
        value: 'global_fallback'
      },
      {
        label: 'VPN table, fallback to Labeled-Unicast table, fallback to Unicast table',
        value: 'lu_global_fallback'
      },
      {
        label: 'VPN Table for VRF, Labeled-Unicast, fallback to Unicast for non-VRF (both directions)',
        value: 'bidir_lu_global'
      },
      {
        label: 'VPN Table for VRF, Unicast for non-VRF (both directions)',
        value: 'bidir_global'
      }
    ];

    if ($auth.hasSudo || $auth.isSpoofed) {
      return options.concat(Object.values(extraOptions));
    }

    const extraValue = form.getValue('bgp_lookup_strategy') && extraOptions[form.getValue('bgp_lookup_strategy')];
    if (extraValue) {
      options.push({ ...extraValue, disabled: true });
    }

    return options;
  }

  render() {
    const { $devices, $dictionary, onChangeBgpType, $auth, form, loadingBgp, role } = this.props;
    const { associatedMitigationPlatforms, associatedActiveMitigations } = this.state;
    const { masterBGPDevices } = $devices;
    const { model } = form;
    const device_bgp_type = form.getValue('device_bgp_type');
    const kbLink = $dictionary.getHelpUrl('admin', 'configuringBGP');

    const ariaProps = {};
    if (role) {
      ariaProps.role = role;
    }

    return (
      <Flex flexDirection="column" overflow="auto" p={2} gap={2} {...ariaProps}>
        <CalloutOutline intent="warning" p={2}>
          <Flex flexDirection="column" gap="12px">
            <Text>
              Configure each peering session as iBGP(internal), as a <strong>route-reflector-client</strong>.
            </Text>
            <Link to={kbLink} blank>
              Need help configuring your BGP sessions?
            </Link>
          </Flex>
        </CalloutOutline>

        <Field m={0} name="device_bgp_type" inputStyle={{ width: 350 }} onChange={onChangeBgpType}>
          <Select
            optionRenderer={DeviceOptionRenderer}
            valueRenderer={DeviceValueRenderer}
            menuWidth={350}
            tetherOptions={tetherOptions}
          />
        </Field>

        {!associatedActiveMitigations.length > 0 && associatedMitigationPlatforms.length > 0 && (
          <Callout intent="warning">
            To unlock BGP Type and/or Flowspec Compatibility, remove the device from the following mitigation
            platform(s):{' '}
            {associatedMitigationPlatforms.map((platform) => (
              <span key={platform.id} style={{ marginRight: 8 }}>
                {platform.get('name')}
              </span>
            ))}
          </Callout>
        )}

        {associatedActiveMitigations.length > 0 && (
          <Callout intent="warning">
            To unlock BGP Type and/or Flowspec Compatibility, currently active mitigations need to conclude.&nbsp;(
            {associatedActiveMitigations.length} active)
          </Callout>
        )}

        {device_bgp_type === 'none' && (
          <Field m={0} name="use_asn_from_flow" showLabel={false}>
            <Switch switchLabel="Use AS Numbers from Flow" />
          </Field>
        )}

        {$auth.hasPermission('alerts.flowSpec.enabled') && (
          <Field m={0} name="device_bgp_flowspec" showLabel={false}>
            <Switch switchLabel="BGP Flowspec Compatible" />
          </Field>
        )}

        {($auth.hasPermission('segmentRouting.enabled') || $auth.isSpoofed) && (
          <Field m={0} name="device_bgp_label_unicast" showLabel={false}>
            <Switch switchLabel="Segment Routing" />
          </Field>
        )}

        <Field m={0} name="bgp_lookup_strategy" options={this.bgpLookupOptions}>
          <Select width={575} menuWidth={575} />
        </Field>

        {device_bgp_type === 'device' && (
          <>
            <Field name="device_bgp_neighbor_ip" flex={1} loading={loadingBgp}>
              <InputGroup
                fill
                rightElement={
                  <Tag intent="primary" minimal>
                    <Icon icon="arrow-right" style={{ margin: '0 4px' }} />
                    <Text color="primary" mr="4px" muted>
                      Kentik Ingest&#39;s Peering IPv4:
                    </Text>

                    <Text color="primary" fontWeight="bold" monospace>
                      {model.get('bgpPeerIP4')}
                    </Text>
                  </Tag>
                }
              />
            </Field>
            <Field name="device_bgp_neighbor_ip6" flex={1} loading={loadingBgp}>
              <InputGroup
                fill
                rightElement={
                  <Tag intent="primary" minimal>
                    <Icon icon="arrow-right" style={{ margin: '0 4px' }} />
                    <Text color="primary" mr="4px" muted>
                      Kentik Ingest&#39;s Peering IPv6:
                    </Text>
                    <Text color="primary" fontWeight="bold" monospace>
                      {model.get('bgpPeerIP6')}
                    </Text>
                  </Tag>
                }
              />
            </Field>
            <Field name="device_bgp_neighbor_asn" width={150}>
              <InputGroup />
            </Field>
            <Field name="device_bgp_password" width={250} mb={0}>
              <PasswordInput autoComplete="new-password" />
            </Field>
          </>
        )}

        {device_bgp_type === 'other_device' && (
          <Field
            name="use_bgp_device_id"
            width={300}
            options={masterBGPDevices.filter(({ value }) => value !== model.id)}
            mb={0}
          >
            <Select showFilter menuWidth={300} tetherOptions={tetherOptions} />
          </Field>
        )}
      </Flex>
    );
  }
}

export default BGPSettings;
