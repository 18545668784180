import React, { Component } from 'react';
import { Button, ConfirmPopover, MenuItem } from 'core/components';
import { inject, observer } from 'mobx-react';

@inject('$alerting')
@observer
export default class ClearAlarmButton extends Component {
  static defaultProps = {
    fill: true,
    disabled: false,
    showConfirm: false
  };

  get alertModels() {
    const { models } = this.props;
    return Array.isArray(models) ? models : [models];
  }

  get buttonText() {
    return `Clear ${this.alertModels.length > 1 ? `${this.alertModels.length} Alerts` : 'Alert'}`;
  }

  get confirmBodyText() {
    return `Are you sure you want to clear ${
      this.alertModels.length === 1 ? 'this alert' : `${this.alertModels.length} alerts`
    }?`;
  }

  render() {
    const { $alerting, asMenuItem, showConfirm, fill, disabled } = this.props;
    const ButtonComponent = asMenuItem ? MenuItem : Button;
    const disableButton = disabled || this.alertModels.length === 0;
    const buttonProps = { icon: 'tick', disabled: disableButton, text: this.buttonText, fill, textAlign: 'left' };

    if (showConfirm) {
      return (
        <ConfirmPopover
          onConfirm={() => $alerting.clearAlarms(this.alertModels)}
          confirmBodyContent={this.confirmBodyText}
          confirmIntent="primary"
          confirmText="Confirm"
          fill={fill}
        >
          <ButtonComponent {...buttonProps} />
        </ConfirmPopover>
      );
    }

    return <ButtonComponent {...buttonProps} onClick={() => $alerting.clearAlarms(this.alertModels)} />;
  }
}
