import React, { Component } from 'react';
import { PopoverPosition } from '@blueprintjs/core';

import { Button, Menu, MenuItem, Popover, Text } from 'core/components';

export default class MenuSelect extends Component {
  state = {
    isOpen: false
  };

  handleInteraction = (nextOpenState) => {
    this.setState({ isOpen: nextOpenState });
  };

  handleSelect = (value) => {
    const { onChange } = this.props;

    onChange(value);
  };

  render() {
    const {
      options,
      value,
      label,
      width = 130,
      placeholder = 'All',
      buttonProps,
      labelProps,
      optionProps,
      ...rest
    } = this.props;
    const { isOpen } = this.state;

    const selectedOption = options.find((option) => option.value === value);
    const ariaButtonProps = {};
    ariaButtonProps.ariaLabel = rest.ariaLabel || label;
    ariaButtonProps.ariaHaspopup = 'menu';
    return (
      <Popover
        {...rest}
        isOpen={isOpen}
        onInteraction={this.handleInteraction}
        position={PopoverPosition.BOTTOM_RIGHT}
        content={
          <Menu minWidth={width}>
            {options.map((option) => (
              <MenuItem
                key={option.label}
                active={value === option.value}
                text={option.label}
                onClick={() => this.handleSelect(option.value)}
                minWidth={width - 8}
              />
            ))}
          </Menu>
        }
      >
        <Button
          {...ariaButtonProps}
          textAlign="left"
          rightIcon="caret-down"
          active={isOpen}
          width={width}
          {...buttonProps}
          role="menu"
          ariaHaspopup="menu"
        >
          <Text as="div" fontSize="small" color="muted" pb="1px" {...labelProps}>
            {label}
          </Text>
          <Text as="div" fontWeight="bold" fontSize="small" {...optionProps}>
            {selectedOption ? selectedOption.label : placeholder}
          </Text>
        </Button>
      </Popover>
    );
  }
}
