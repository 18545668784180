import React from 'react';
import { withTheme } from 'styled-components';

import Box from 'core/components/Box';
import Button from 'core/components/Button';
import Text from 'core/components/Text';

import SelectValueRenderer from './SelectValueRenderer';

const SelectButton = withTheme((props) => {
  const {
    active,
    ariaLabel,
    labelId,
    disabled,
    height,
    icon,
    inlineLabel,
    label,
    loading,
    minimal,
    onClick,
    options,
    rightIcon,
    small,
    theme,
    valueRenderer,
    values,
    placeholder,
    showCount,
    width,
    intent,
    hideSelected,
    ...rest
  } = props;

  // Note: you can't have a danger Select, which you probably shouldn't do anyway!
  const intentProps = { intent };
  if (intent === 'danger') {
    intentProps.intent = undefined;
    intentProps.border = `1px solid ${theme.colors.danger}`;
  }
  const ariaLabelwGuesses = ariaLabel || placeholder || label;
  const ariaProps = {};
  if (labelId) {
    ariaProps['aria-labelledby'] = labelId;
  } else if (ariaLabelwGuesses?.length > 0) {
    ariaProps['aria-label'] = ariaLabelwGuesses;
  }
  if (rest.role) {
    ariaProps.role = rest.role;
  } else {
    ariaProps.role = 'listbox';
  }
  ariaProps['aria-haspopup'] = 'listbox';
  ariaProps['aria-expanded'] = active;

  return (
    <Button
      {...ariaProps}
      active={active}
      ariaLabel={ariaLabelwGuesses}
      disabled={disabled}
      height={inlineLabel ? 38 : height}
      justifyContent="space-between"
      loading={loading}
      minimal={minimal}
      onClick={onClick}
      icon={icon}
      rightIcon={rightIcon}
      role="listbox"
      textAlign="left"
      small={small}
      width={width}
      {...intentProps}
      {...rest}
    >
      {inlineLabel && (
        <Text muted pb="1px">
          {label}
        </Text>
      )}
      {!hideSelected && (
        <Box
          fontWeight={inlineLabel ? 'bold' : 'inherit'}
          height={inlineLabel ? 14 : undefined}
          role="option"
          aria-selected="true"
        >
          <SelectValueRenderer
            valueRenderer={valueRenderer}
            options={options}
            values={values}
            placeholder={placeholder}
            showCount={showCount}
          />
        </Box>
      )}
    </Button>
  );
});

SelectButton.defaultProps = {
  rightIcon: 'caret-down'
};

export default SelectButton;
