import React from 'react';
import { action, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';
import { Classes } from '@blueprintjs/core';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import classNames from 'classnames';

import getScrollbarWidth from 'core/util/getScrollbarWidth';
import { formAriaProps } from 'core/util/ariaUtils';

import GroupRow from './GroupRow';
import Header from './Header';
import Row from './Row';
import StickyHeader from './StickyHeader';
import Table from './Table';

@observer
export default class VirtualizedTable extends Table {
  @observable.ref
  groupedRows = [];

  isRenderingVisibleRows = false;

  @observable
  stickyHeaderProps = {};

  stickyHeaderModel = null;

  static defaultProps = {
    noHighlightSorted: false,
    useSortTooltips: false,
    // style object passed to Header directly
    headerStyle: {},
    infiniteScroll: false,
    minimal: false,
    rowHeight: 52,
    breakpoint: 1024,
    selectOnRowClick: true,
    stickyHeader: false,
    hideHeader: false,
    tableLabelId: null
  };

  // This links functions on the collection to a table function
  // Could we someday create a 'excuse to updateGrid' or just updateGroupedRows?
  setupReactions(collection) {
    this.filterDisposer = reaction(() => collection.filterState, this.updateGroupedRows, { delay: 50 });
    this.presetFilterDisposer = reaction(() => collection.activePresetFilter, this.updateGroupedRows, { delay: 50 });
    this.sortDisposer = reaction(() => collection.sortState, this.updateGroupedRows, { delay: 50 });
    this.groupByDisposer = reaction(() => collection.groupBy, this.updateGroupedRows);
    this.groupCollapseDisposer = reaction(() => this.collapsedRows.entries(), this.updateGroupedRows);
    this.lastUpdatedDisposer = reaction(() => collection.lastUpdated, this.updateGroupedRows);
    this.selectedDisposer = reaction(() => collection.selected, this.updateGroupedRows);
    this.discreteFilterDisposer = reaction(() => collection.discreteFilters, this.updateGroupedRows, {
      delay: 50
    });

    if (collection.groupBy) {
      this.updateGroupedRows();
    }
  }

  clearDisposers() {
    this.filterDisposer();
    this.presetFilterDisposer();
    this.sortDisposer();
    this.groupByDisposer();
    this.groupCollapseDisposer();
    this.lastUpdatedDisposer();
    this.selectedDisposer();
    this.discreteFilterDisposer();
  }

  componentDidMount() {
    const { collection } = this.props;
    this.setupReactions(collection);
    super.componentDidMount();
  }

  componentDidUpdate(prevProps) {
    const { collection } = this.props;
    if (collection !== prevProps.collection) {
      this.clearDisposers();
      this.setupReactions(collection);
      this.updateGrid();
    }
  }

  componentWillUnmount() {
    this.clearDisposers();
    super.componentWillUnmount();
  }

  listRefHandler = (ref) => {
    if (ref) {
      this.listRef = ref;
    }
  };

  @action
  updateGrid = () => {
    if (this.listRef && this.listRef.Grid) {
      this.listRef.recomputeRowHeights();
      this.listRef.forceUpdateGrid();
    }
  };

  @action
  updateGroupedRows = () => {
    const { collection } = this.props;
    const groupedRows = [];

    if (collection.isTreeGroupBy) {
      const groupedData = collection.getGroupedData(this.collapsedRows);

      for (let i = 0; i < groupedData.length; i += 1) {
        const model = groupedData[i];
        const index = model.get('index');

        groupedRows.push({
          model,
          isExpanded: !this.collapsedRows.has(index),
          hasChildren: model.hasChildren,
          onToggle: (e) => {
            e.stopPropagation();
            this.toggleGroup(index);
          }
        });
      }
    } else if (collection.groupBy) {
      const { groupedData, sortedGroupKeys } = collection;

      sortedGroupKeys.forEach((groupKey, idx, arr) => {
        const isExpanded = !this.collapsedRows.has(groupKey);

        groupedRows.push({
          groupKey,
          isGroupSummary: true,
          isExpanded,
          onToggle: () => this.toggleGroup(groupKey),
          last: idx === arr.length - 1
        });

        if (isExpanded) {
          groupedRows.push(...groupedData[groupKey].map((model) => ({ groupKey, model })));
        }
      });
    }

    this.groupedRows = groupedRows;

    this.updateGrid();
  };

  getRowHeight = ({ index }) => {
    const { collection, rowHeight } = this.props;

    if (typeof rowHeight === 'number') {
      return rowHeight;
    }

    let model = collection.at(index);
    if (collection.groupBy) {
      const row = this.getGroupedRow(index);
      // group summary row is not part of the collection
      model = row?.isGroupSummary ? row : row?.model;
    }
    return rowHeight({ index, model }, this);
  };

  getGroupedRow(index) {
    // Returning null can occur when this.props.showTotalRow is true as it increases the rowCount
    return index < this.groupedRows.length ? this.groupedRows[index] : null;
  }

  @action
  setStickyHeader = debounce((model, props) => {
    this.stickyHeaderModel = model;
    this.stickyHeaderProps = props;
  }, 100);

  isItemLoaded = ({ index }) => {
    const { collection } = this.props;
    const fullSize = parseInt(collection.totalCount);
    const itemModelPresent = collection.models.length - 1 >= index && !!collection.models[index];

    let collapseCount = 0;

    if (collection.groupBy) {
      for (const key of this.collapsedRows.keys()) {
        collapseCount += collection.groupedData?.[key]?.length || 0;
      }
    }

    return index + collapseCount < collection.size || (itemModelPresent && index <= fullSize);
  };

  loadMoreItems = ({ startIndex, stopIndex }) => {
    const { collection } = this.props;
    const { totalCount, size, groupBy, loading } = collection;

    // don't try to load anymore if the collection is fully loaded or is currently loading
    if (loading || (totalCount && Number(size) >= Number(totalCount))) {
      return Promise.resolve();
    }

    // if the collection is grouped, just pass the collection size as the next index to load
    if (groupBy) {
      startIndex = size;
    }

    return collection.loadMoreItems({ startIndex, stopIndex });
  };

  handleScroll = ({ scrollTop }) => {
    if (scrollTop === 0) {
      this.setStickyHeader(null, {});
    }
  };

  renderRow = ({ index, key, style, isVisible }) => {
    const { collection, modelField, showTotalRow, stickyHeader, rowKeyField } = this.props;
    const { visibleColumns } = this.state;

    if (collection.isTreeGroupBy) {
      const row = this.getGroupedRow(index);
      if (!row) {
        return null;
      }

      const { model, isExpanded, onToggle, hasChildren } = row;

      return (
        <Row
          {...this.props}
          key={rowKeyField ? model.get(rowKeyField) : model.id}
          model={model}
          style={style}
          updateGrid={this.updateGrid}
          selected={this.isRowSelected(model)}
          disabled={this.isRowDisabled(model)}
          columns={visibleColumns}
          isExpanded={isExpanded}
          onToggle={onToggle}
          hasChildren={hasChildren}
          virtualized
        />
      );
    }

    let model = index < collection.size ? collection.at(index) : null;
    if (modelField) {
      model = collection[modelField][index];
    }
    let rowKeyPrefix = '';

    if (collection.groupBy) {
      const row = this.getGroupedRow(index);

      if (row) {
        model = row.model;
        rowKeyPrefix = `${row.groupKey}-`;

        if (stickyHeader) {
          // For the first item visible, let's make sure we get a group header
          if (!this.isRenderingVisibleRows && isVisible) {
            let stickyModel = row;
            for (let i = index - 1; i >= 0 && stickyModel && !stickyModel.isGroupSummary; i -= 1) {
              stickyModel = this.getGroupedRow(i);
            }
            if (stickyModel && stickyModel.isGroupSummary) {
              if (this.stickyHeaderModel !== stickyModel) {
                this.setStickyHeader(stickyModel, { ...this.props, ...stickyModel, key, style });
              }
            }
          }
          this.isRenderingVisibleRows = isVisible;
        }

        if (row.isGroupSummary) {
          return <GroupRow {...this.props} {...row} key={row.groupKey} style={style} />;
        }
      }
    } else if (stickyHeader && this.stickyHeaderModel) {
      this.setStickyHeader(null, {});
    }

    if (!model) {
      if (showTotalRow) {
        return this.renderTotals({ key, style });
      }
      return null;
    }

    return (
      <Row
        {...this.props}
        key={rowKeyPrefix + (rowKeyField ? model.get(rowKeyField) : model.id)}
        model={model}
        style={style}
        updateGrid={this.updateGrid}
        selected={this.isRowSelected(model)}
        disabled={this.isRowDisabled(model)}
        columns={visibleColumns}
        virtualized
      />
    );
  };

  renderBody() {
    const {
      bodyStyle,
      collection,
      modelField,
      flexed,
      className,
      showTotalRow,
      stickyHeader,
      infiniteScroll,
      loading,
      scrollToIndex,
      scrollToAlignment
    } = this.props;

    const style = Object.assign({}, flexed ? { flex: 1 } : {}, bodyStyle);

    this.isRenderingVisibleRows = false;

    if (collection.isRequestActive('fetching') || collection.size === 0 || loading) {
      return super.renderBody({ style });
    }

    const groupedSize = this.groupedRows.length;
    const fullSize = collection.size;
    let rowCount = fullSize;
    if (collection.groupBy) {
      rowCount = groupedSize;
    } else if (modelField) {
      rowCount = collection[modelField].length;
    }

    if (showTotalRow) {
      rowCount += 1;
    }

    const listProps = {
      noRowsRenderer: () => this.renderEmpty(),
      rowCount,
      rowHeight: this.getRowHeight,
      rowRenderer: this.renderRow,
      ref: this.listRefHandler,
      width: 100 /* placeholder value - we're injecting 100% via css */,
      onScroll: stickyHeader ? this.handleScroll : undefined,
      scrollToIndex,
      scrollToAlignment
    };

    if (!infiniteScroll) {
      return (
        <div className={classNames('tbody', className)} style={style}>
          <AutoSizer disableWidth>{({ height }) => <List height={height} {...listProps} />}</AutoSizer>
          {stickyHeader && <StickyHeader headerProps={this.stickyHeaderProps} />}
        </div>
      );
    }

    return (
      <div className={classNames('tbody', className)} style={style}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <InfiniteLoader
              isRowLoaded={this.isItemLoaded}
              threshold={50}
              minimumBatchSize={50}
              rowCount={collection.groupBy || modelField ? parseInt(rowCount) : parseInt(collection.totalCount)}
              loadMoreRows={this.loadMoreItems}
            >
              {({ onRowsRendered, ref }) => (
                <List height={height} {...listProps} ref={ref} onRowsRendered={onRowsRendered} />
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
        {stickyHeader && <StickyHeader headerProps={this.stickyHeaderProps} />}
      </div>
    );
  }

  render() {
    const {
      className,
      headerRef,
      collection,
      flexed,
      headerStyle,
      hideHeader,
      minimal,
      noHighlightSorted,
      onRowClick,
      onSort,
      selectOnRowClick,
      useSortTooltips
    } = this.props;
    const { visibleColumns } = this.state;

    const tableClassName = classNames(Classes.HTML_TABLE, className, {
      [Classes.INTERACTIVE]: !minimal && (selectOnRowClick || onRowClick)
    });

    const style = Object.assign(
      { overflow: 'auto hidden', display: 'grid', gridTemplateRows: hideHeader ? '1fr' : 'auto 1fr' },
      flexed ? { flex: 1 } : {},
      this.props.style
    );

    const ariaProps = formAriaProps(
      this.props,
      {},
      {
        passOnExistingAria: true
      }
    );
    if (collection && collection.size) {
      ariaProps['aria-rowcount'] = collection.size;
    }
    if (visibleColumns && visibleColumns.length) {
      ariaProps['aria-colcount'] = visibleColumns.length;
    }

    return (
      <div style={style} className={tableClassName} role="table" {...ariaProps}>
        {!hideHeader && (
          <Header
            headerRef={headerRef}
            useSortTooltips={useSortTooltips}
            noHighlightSorted={noHighlightSorted}
            collection={collection}
            columns={visibleColumns}
            style={{ paddingRight: getScrollbarWidth(), ...headerStyle }}
            onSort={onSort}
          />
        )}
        {this.renderBody()}
      </div>
    );
  }
}
