import Model from 'core/model/Model';
import { showSuccessToast } from 'core/components';
import { action } from 'mobx';

class PromptGroupModel extends Model {
  get defaults() {
    return {
      prompt: '',
      models: [],
      journey: {}
    };
  }

  destroyAll() {
    return Promise.all(this.get('models').map((model) => model.destroy()))
      .then(() => this.destroy())
      .catch((err) => console.error(err));
  }

  async restoreAll(options = {}) {
    // note: toast true by default
    const { toast = true, journey } = options;

    const models = this.get('models');
    if (journey && !journey.isDeleted) {
      return Promise.resolve(options)
        .then(() => Promise.all(models.map((m) => m.restore(options))))
        .then(
          action((success) => {
            if (toast) {
              showSuccessToast(this.messages.restore);
            }

            return success;
          }),
          action((error) => {
            this.error = { label: 'updating', body: error };
            this.requestStatus = null;
            throw error;
          })
        );
    }

    return false;
  }

  // doesn't remove from collection
  // doesn't change request status back
  // fakes delete b/c essentially a wrapper
  @action
  // eslint-disable-next-line no-unused-vars
  destroyModel = async (options) => {
    this.requestStatus = 'destroying';
    return Promise.resolve().then(() => {
      this.collection.clearSelection();
      return true;
    });
  };

  async destroy(options = {}) {
    // don't remove from collection
    return this.destroyModel(options);
  }

  get messages() {
    return {
      create: 'Prompt was added successfully',
      update: 'Thank you for your feedback!',
      destroy: 'Prompt was removed successfully',
      restore: 'Prompt was restored successfully'
    };
  }
}

export default PromptGroupModel;
