import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core';
import classNames from 'classnames';
import { Button } from 'core/components';

const StyledMenuWrapper = styled.div`
  position: fixed;
  ${({ topOffset, extraOffset = 0 }) => css`
    top: ${topOffset + extraOffset}px;
    height: calc(100vh - ${topOffset + extraOffset}px);
    max-height: calc(100vh - ${topOffset + extraOffset}px);
  `}
  right: -15px;
  background: ${({ theme }) => theme.colors.drawerBackground};

  box-shadow: ${(props) => props.theme.shadows.hovered};
  border-left: none;
  z-index: 7;
  opacity: 0;
  pointer-events: none;
  overflow-y: auto;
  overflow-x: hidden;

  transition:
    opacity linear 150ms,
    right linear 150ms,
    left linear 150ms;

  &.expanded {
    right: 0;
    opacity: 1;
    pointer-events: all;

    &.showDockedInLargeFullScreen {
      @media (min-width: 1600px) {
        display: block;
        position: relative;
        top: 0;
        right: unset;
        box-shadow: none;
        border-left: ${(props) => props.theme.borders.thin};
      }
    }

    &.forceDocked {
      display: block;
      position: relative;
      top: 0;
      right: unset;
      box-shadow: none;
      border-left: ${(props) => props.theme.borders.thin};
    }
  }

  &.left {
    right: unset;
    left: -15px;

    &.expanded {
      left: 0;

      &.showDockedInLargeFullScreen {
        @media (min-width: 1600px) {
          left: unset;
          border-right: ${(props) => props.theme.borders.thin};
          border-left-width: 0;
        }
      }

      &.forceDocked {
        left: unset;
        border-right: ${(props) => props.theme.borders.thin};
        border-left-width: 0;
      }
    }
  }
`;

@HotkeysTarget
class Drawer extends Component {
  componentDidMount() {
    const { isOpen } = this.props;
    if (isOpen) {
      setTimeout(() => {
        window.addEventListener('click', this.handlePageClick);
      }, 250);
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    if (isOpen && !prevProps.isOpen) {
      setTimeout(() => {
        window.addEventListener('click', this.handlePageClick);
      }, 250);
    } else if (!isOpen && prevProps.isOpen) {
      window.removeEventListener('click', this.handlePageClick);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handlePageClick);
  }

  handlePageClick = (e) => {
    const { isOpen, onClose, forceDocked, showDockedInLargeFullScreen } = this.props;
    if (isOpen) {
      const pageContentEl = document.querySelector('.page-main-content');
      const pageDrawerEl = document.querySelector('.page-drawer');

      const { matches } = window.matchMedia('(min-width: 1600px)');

      if (
        !!onClose &&
        !forceDocked &&
        (!showDockedInLargeFullScreen || !matches) && // if not docked
        pageContentEl &&
        pageDrawerEl &&
        pageContentEl.contains(e.target) &&
        !pageDrawerEl.contains(e.target)
      ) {
        onClose();
      }
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderHotkeys() {
    const { onClose } = this.props;

    return (
      <Hotkeys>
        <Hotkey global combo="esc" label="Close Drawer" onKeyDown={onClose} />
      </Hotkeys>
    );
  }

  render() {
    const { isOpen, contents, forceDocked, left, showDockedInLargeFullScreen, onClose, ...drawerProps } = this.props;
    const className = classNames('page-drawer', { expanded: isOpen, forceDocked, left, showDockedInLargeFullScreen });

    const { showCloseButton = false, ...restOfDrawerProps } = drawerProps;

    return (
      <StyledMenuWrapper key="menu" className={className} {...restOfDrawerProps} role="dialog">
        {showCloseButton && (
          <div style={{ position: 'absolute', top: '16px', right: '16px', zIndex: 1 }}>
            <Button minimal icon="cross" onClick={onClose} />
          </div>
        )}
        {contents}
      </StyledMenuWrapper>
    );
  }
}

export default Drawer;
