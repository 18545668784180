import React from 'react';
import { observer } from 'mobx-react';
import { TimePrecision } from '@blueprintjs/datetime';
import moment from 'moment';

import whitelistFieldProps from 'core/form/components/util/whitelistFieldProps';

import DateRangeInputShortcuts from './DateRangeInputShortcuts';

function getDefaultShortcuts() {
  return [
    {
      label: 'Today',
      includeTime: true,
      dateRange: [moment().startOf('day').toDate(), moment().endOf('day').toDate()]
    },
    {
      label: 'This week',
      includeTime: true,
      dateRange: [moment().startOf('week').toDate(), moment().endOf('day').toDate()]
    },
    {
      label: 'This month',
      includeTime: true,
      dateRange: [moment().startOf('month').toDate(), moment().endOf('day').toDate()]
    },
    {
      label: 'Last month',
      includeTime: true,
      dateRange: [
        moment().subtract(1, 'months').startOf('month').toDate(),
        moment().subtract(1, 'months').endOf('month').toDate()
      ]
    },
    {
      label: 'Last 7 days',
      includeTime: true,
      dateRange: [moment().subtract(7, 'days').startOf('day').toDate(), moment().endOf('day').toDate()]
    },
    {
      label: 'Last 30 days',
      includeTime: true,
      dateRange: [moment().subtract(30, 'days').startOf('day').toDate(), moment().endOf('day').toDate()]
    }
  ];
}

const DateRange = (props) => {
  const {
    startField,
    endField,
    form,
    format,
    style,
    small,
    shortcuts,
    asStrings,
    closeOnSelection,
    onChange,
    popoverProps
  } = props;

  const startFieldState = form.getField(startField);
  const endFieldState = form.getField(endField);

  const { value: start, fieldProps } = startFieldState.getProps();
  const end = endFieldState.getValue();

  let value = [start, end];
  if (asStrings) {
    value = value.map((val) => moment(val, format).toDate());
  }

  // VPAT: consider a wrapper - aria-label does not seem to work.

  return (
    <div style={style}>
      <DateRangeInputShortcuts
        {...fieldProps}
        {...whitelistFieldProps(props)}
        shortcuts={shortcuts === true || shortcuts === undefined ? getDefaultShortcuts() : shortcuts}
        closeOnSelection={closeOnSelection || false}
        formatDate={(date) => moment(date).format(format)}
        parseDate={(str) => moment(str, format).toDate()}
        value={value}
        startInputProps={{ small, style: { width: 'calc(100% + 2px)' } }}
        endInputProps={{ small, style: { width: 'calc(100% + 2px)', marginLeft: 2 } }}
        onChange={([newStart, newEnd]) => {
          const prevStartValue = startFieldState.getValue();
          const prevEndValue = endFieldState.getValue();

          const newStartValue = asStrings ? moment(newStart).format(format) : newStart;
          const newEndValue = asStrings ? moment(newEnd).format(format) : newEnd;

          startFieldState.setValue(newStartValue);
          endFieldState.setValue(newEndValue);

          if (onChange) {
            if (prevStartValue !== newStartValue) {
              onChange(startFieldState, newStartValue, prevStartValue, form);
            }
            if (prevEndValue !== newEndValue) {
              onChange(endFieldState, newEndValue, prevEndValue, form);
            }
          }
        }}
        popoverProps={popoverProps}
      />
    </div>
  );
};

DateRange.defaultProps = {
  format: 'YYYY-MM-DD HH:mm',
  timePrecision: TimePrecision.MINUTE
};

export default observer(DateRange);
