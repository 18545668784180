/*
 I am not a component you should use directly. I just have to be broken out because hipsters.
 */
import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';

import Text from 'core/components/Text';
import { formAriaProps } from 'core/util/ariaUtils';
import SelectValuesRenderer from 'core/components/select/SelectValuesRenderer';

const MultiSelectInputWrapperJunk = styled.div`
  &.${Classes.INPUT} {
    cursor: pointer;
    display: inline-flex;
    flex-wrap: wrap;
    height: auto;
    min-height: 30px;
    max-height: 140px;
    align-items: center;
    gap: 4px;
    overflow: auto;
    padding: 4px;
  }

  &.${Classes.DISABLED} {
    pointer-events: none;
  }
`;

const MultiSelectInput = (props) => {
  const {
    active,
    style,
    options,
    placeholder,
    onClick,
    onUnselectBuilder,
    tagFill,
    values,
    valueRenderer,
    valueTagProps,
    valueTagRenderer,
    className,
    disabled
  } = props;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (onClick && !disabled) {
        onClick();
      }
    }
  };

  const ValueRenderer = valueTagRenderer || SelectValuesRenderer;
  const ariaProps = formAriaProps(props, { ariaHaspopup: 'listbox', ariaExpanded: active });

  ariaProps.role = 'combobox';
  ariaProps['aria-autocomplete'] = 'list';

  return (
    <MultiSelectInputWrapperJunk
      className={classNames({ [Classes.DISABLED]: disabled }, Classes.INPUT, className)}
      tabIndex="0"
      onClick={onClick}
      style={style}
      onKeyDown={handleKeyDown}
      {...ariaProps}
    >
      {values.length > 0 && (
        <ValueRenderer
          onUnselectBuilder={onUnselectBuilder}
          options={options}
          tagFill={tagFill}
          valueRenderer={valueRenderer}
          valueTagProps={valueTagProps}
          values={values}
        />
      )}
      {values.length === 0 && <Text muted>{placeholder}</Text>}
    </MultiSelectInputWrapperJunk>
  );
};

export default observer(MultiSelectInput);
