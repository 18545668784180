import React from 'react';
import styled from 'styled-components';
import { space, width, height, minHeight, maxHeight, color, overflow } from 'styled-system';
import { Classes, Menu as BlueprintMenu } from '@blueprintjs/core';
import { menuDividerStyles } from './MenuDivider';

const StyledMenu = styled(BlueprintMenu)`
  ${space};
  ${width};
  ${color};
  ${height};
  ${minHeight};
  ${maxHeight};
  ${overflow};

  /* some reasonable resets */
  .${Classes.MENU} {
    min-width: 0;
    background: 'transparent';
  }

  ${menuDividerStyles};
`;

const Menu = (props) => {
  const { minWidth, borderRadius, style = {}, ...rest } = props;
  return <StyledMenu {...rest} style={{ minWidth, borderRadius, ...style }} role="menu" />;
};

export default Menu;
