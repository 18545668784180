import React, { Component } from 'react';
import copy from 'copy-to-clipboard';

export default class CopyToClipboard extends Component {
  static defaultProps = {
    copyConfirmationText: 'Copied!',
    timeout: undefined
  };

  state = {
    copied: false
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onClick = (e) => {
    const { text, onCopyComplete, children, options } = this.props;
    const elem = React.Children.only(children);

    copy(text, options);

    // change button text on success, then change back
    this.setState({ copied: true });
    this.timeout = setTimeout(() => {
      this.setState({ copied: false });

      if (onCopyComplete) {
        onCopyComplete();
      }
    }, 1000);

    // Bypass onClick if it was present
    if (elem && elem.props && typeof elem.props.onClick === 'function') {
      elem.props.onClick(e);
    }
  };

  render() {
    const { children, onCopyComplete, copyConfirmationText, ...props } = this.props;
    const { copied } = this.state;
    const elem = React.Children.only(children);

    return React.cloneElement(elem, {
      intent: 'primary',
      ...props,
      onClick: this.onClick,
      icon: copied ? 'tick' : elem.props.icon,
      text: copied ? copyConfirmationText : elem.props.text,
      ariaLabel: copied ? copyConfirmationText : elem.props.ariaLabel,
      disabled: copied
    });
  }
}
