import React, { Component } from 'react';
import classNames from 'classnames';

import Flex from 'core/components/Flex';
import Text from 'core/components/Text';
import { stopBubbling } from 'app/util/utils';
import TreeExpander from 'core/components/table/TreeExpander';
import CELL_TYPES from './CELL_TYPES';

function getColSpan(column, model, totalRow) {
  if (column.colSpan && typeof column.colSpan === 'function') {
    return column.colSpan(model, totalRow);
  }

  return column.colSpan || 1;
}

export function getCellStyle({ column, columns, index, model, totalRow, lastColumnPaddingRight = 12, collection }) {
  const colSpan = getColSpan(column, model, totalRow);
  const defaultFlexBasis = 100;

  // Check to see if anything is obscuring this cell via colSpan if this isn't a header row
  if (model || totalRow) {
    for (let i = 0; i < index; i += 1) {
      if (i + getColSpan(columns[i], model, totalRow) - 1 >= index) {
        return {
          display: 'none'
        };
      }
    }
  }

  // Get this column's width/flex and if it has colSpan > 1, get from the columns it'll obscure
  let sumFlexBasis = 0;
  let sumWidth = 0;
  for (let i = index; i < index + colSpan; i += 1) {
    const col = columns[i];
    if (col.width) {
      sumWidth += col.width;
    } else if (col.actions) {
      sumWidth += 24 * col.actions.length + 24;
    } else {
      sumFlexBasis += col.flexBasis || defaultFlexBasis;
    }
  }

  // Get the width/flex totals for all columns
  // We need to exclude widths of fixed-width columns that wind up flexed due to colSpans too.
  // This is why this atrocious looking 'colSpanBlocker' exists...
  let totalFlexBasis = 0;
  let totalWidth = 0;
  let colSpanBlocker = -1;
  let colSpanWidth = 0;
  let colSpanHasFlex = false;
  for (let i = 0; i < columns.length; i += 1) {
    const col = columns[i];
    const colColSpan = getColSpan(columns[i], model, totalRow);

    if (colSpanBlocker < i) {
      if (!colSpanHasFlex && colSpanWidth > 0) {
        totalWidth += colSpanWidth;
      }

      if (colColSpan > 1) {
        colSpanBlocker = i + colColSpan - 1;
      }

      colSpanWidth = 0;
      colSpanHasFlex = false;
    }

    if (col.width) {
      if (colSpanBlocker < i) {
        totalWidth += col.width;
      } else {
        colSpanWidth += col.width;
      }
    } else if (col.actions) {
      if (colSpanBlocker < i) {
        totalWidth += 24 * col.actions.length + 24;
      } else {
        colSpanWidth += col.width;
      }
    } else {
      totalFlexBasis += col.flexBasis || defaultFlexBasis;
      if (colSpanBlocker >= i) {
        colSpanHasFlex = true;
      }
    }
  }

  if (!colSpanHasFlex && colSpanWidth > 0) {
    totalWidth += colSpanWidth;
  }

  let minWidth;
  if (index === 0 && collection.isTreeGroupBy) {
    minWidth = (column.minWidth || 80) + 24 + (collection.maxTreeDepth || 0) * 12;
  }

  return {
    minWidth: minWidth ?? column.minWidth ?? sumWidth ?? 'auto',
    width: sumFlexBasis > 0 ? `calc((100% - ${totalWidth}px) * ${sumFlexBasis} / ${totalFlexBasis})` : sumWidth,
    paddingRight: column.paddingRight ?? (index === columns.length - 1 ? lastColumnPaddingRight : 0),
    overflowWrap: column.wrapText ? 'break-word' : 'normal'
  };
}

class Cell extends Component {
  shouldComponentUpdate(props) {
    const {
      value,
      loading,
      modelId,
      isSelected,
      isExpanded,
      column,
      columns,
      staticColumns,
      hasChildren,
      treeExpander
    } = this.props;

    return (
      value !== props.value ||
      loading !== props.loading ||
      isSelected !== props.isSelected ||
      isExpanded !== props.isExpanded ||
      hasChildren !== props.hasChildren ||
      treeExpander !== props.treeExpander ||
      (column.type === CELL_TYPES.ACTION && modelId !== props.modelId) ||
      (!staticColumns && columns !== props.columns)
    );
  }

  render() {
    const {
      column,
      model,
      rawValue,
      updateGrid,
      collection,
      loading,
      hasChildren,
      isExpanded,
      onToggle,
      treeExpander
    } = this.props;

    const style = getCellStyle(this.props);
    const { flexDirection, justifyContent, alignItems, gap } = column;
    const verticalAlign = column.verticalAlign === 'top' ? 'flex-start' : column.verticalAlign || 'center';
    const horizontalAlign = column.align === 'right' ? 'flex-end' : column.align || 'flex-start';

    if (column.type === CELL_TYPES.ACTION) {
      const tdClassName = classNames('td actions', column.dynamicClassName ? column.dynamicClassName(model) : '', {
        select: column.key === 'select'
      });
      return (
        <Flex
          className={tdClassName}
          style={style}
          flexDirection={flexDirection}
          justifyContent={justifyContent || horizontalAlign}
          alignItems={alignItems || verticalAlign}
          p={column.padding}
          onClick={stopBubbling}
          onFocus={column.onFocus ? () => column.onFocus(model) : undefined}
          onMouseEnter={column.onMouseEnter ? () => column.onMouseEnter(model) : undefined}
          gap={gap}
          role="cell"
        >
          {column.actions.map((action) => action(model))}
        </Flex>
      );
    }

    const value = column.renderer
      ? column.renderer({ value: rawValue, column, model, updateGrid, collection, loading })
      : rawValue;
    const ellipsis = column.ellipsis !== undefined ? column.ellipsis : !column.wrapText;
    const className = classNames(
      'legend-table-cell',
      column.className,
      column.dynamicClassName ? column.dynamicClassName(model) : '',
      `${column.key || column.name || column.type}-cell`,
      {
        bracket: column.type === 'bracket'
      }
    );

    const rawTitle = rawValue ? rawValue.toString() : undefined;
    let displayTitle = column.title ? column.title({ model, value: rawValue }) : rawTitle;

    if (displayTitle === '[object Object]') {
      displayTitle = undefined;
    }

    const defaultPadding = '6px 0px 6px 12px';
    const padding = column.padding ?? defaultPadding;

    return (
      <Flex
        className={className}
        style={style}
        flexDirection={flexDirection}
        flexWrap={column.wrapText ? 'wrap' : undefined}
        p={model.get('isOverlay') ? defaultPadding : padding}
        justifyContent={justifyContent || horizontalAlign}
        alignItems={alignItems || verticalAlign}
        title={ellipsis ? displayTitle : undefined}
        gap={gap}
        overflow="hidden"
        role="cell"
      >
        {treeExpander && (
          <TreeExpander
            isExpanded={isExpanded}
            hasChildren={hasChildren}
            onToggle={onToggle}
            depth={model.treeDepth}
            small
            mr={1}
          />
        )}
        {ellipsis && (
          <Text title={displayTitle} ellipsis>
            {value}
          </Text>
        )}
        {!ellipsis && value}
      </Flex>
    );
  }
}

export default Cell;
