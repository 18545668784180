import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Classes, PopoverPosition } from '@blueprintjs/core';

import { Box, Button, Flex, Text, Tag, Popover } from 'core/components';
import { InterfaceNameDeviceNameAndDescription } from 'app/components/Interface';

const filterKeyMap = {
  interface_description: 'Name',
  snmp_alias: 'Description',
  device_id: 'Device',
  interface_ip: 'IP Address',
  snmp_speed: 'Capacity',
  connectivity_type: 'Connectivity Type',
  network_boundary: 'Network Boundary',
  provider: 'Provider',
  interface_type: 'Interface Type',
  label_id: 'Device Label',
  site_id: 'Site',
  admin_status: 'Admin Status'
};

@inject('$dictionary', '$devices', '$labels', '$sites')
@observer
export default class SelectedFilterGroup extends Component {
  getLabel = (key, value) => {
    const { $dictionary, $devices, $labels, $sites } = this.props;
    if (key === 'connectivity_type') {
      return $dictionary.get(`interfaceClassification.connectivityTypes.${value}`);
    }
    if (key === 'snmp_speed') {
      const snmp_speed = parseInt(value);
      let label;
      label = `${snmp_speed}Mbits/s`;

      if (snmp_speed >= 1000) {
        label = `${snmp_speed / 1000}Gbits/s`;
      }
      return label;
    }
    if (key === 'network_boundary') {
      return $dictionary.get(`interfaceClassification.networkBoundaryTypes.${value}`);
    }
    if (key === 'device_id') {
      return $devices.deviceSummariesById[value]?.device_name || value;
    }
    if (key === 'label_id') {
      return $labels.labels.get(value)?.get('name') || value;
    }
    if (key === 'site_id') {
      return $sites.collection.get(value)?.get('title') || value;
    }

    return value;
  };

  handleRemove = () => {
    const { onRemove, filterGroup } = this.props;
    onRemove(filterGroup);
  };

  render() {
    const { $devices, filterGroup, onRemove, isLast } = this.props;
    const { filters, interfaces, total_count } = filterGroup;

    return (
      <Box overflow="auto" borderTop="thin" borderBottom={isLast ? 'thin' : undefined} px={2} py={1}>
        <Flex alignItems="center" justifyContent="space-between" overflow="hidden">
          <Flex overflow="hidden" mr={1} fontSize="small">
            {Object.keys(filters).map((field) => {
              const filterValue = filters[field];
              const filterValueDisplay = Array.isArray(filterValue)
                ? filterValue.map((val) => this.getLabel(field, val)).join(', ')
                : this.getLabel(field, filterValue);

              return (
                <Box key={field} ml="4px" small minimal>
                  <Text fontWeight="bold">{filterKeyMap[field] || field}</Text>: {filterValueDisplay}
                </Box>
              );
            })}
            <Popover minimal={false} position={PopoverPosition.BOTTOM_RIGHT}>
              <Tag style={{ cursor: 'pointer' }} ml={2} small role="button" aria-haspopup="true" tabIndex="0">
                {total_count} Interfaces
              </Tag>
              <Box maxWidth={300} maxHeight={300} overflow="auto" p={1}>
                <Text as="div" small mb={1}>
                  Showing {total_count > 250 ? 'first 250' : total_count} interfaces:
                </Text>
                <ul className={Classes.LIST_UNSTYLED}>
                  {interfaces.map((intf) => (
                    <li key={`${intf.device_id}|${intf.snmp_id}`} style={{ marginBottom: 8 }}>
                      <InterfaceNameDeviceNameAndDescription
                        interface_description={intf.interface_description}
                        snmp_alias={intf.snmp_alias}
                        device_name={$devices.deviceSummariesById[intf.device_id]?.device_name}
                        small
                      />
                    </li>
                  ))}
                </ul>
              </Box>
            </Popover>
          </Flex>

          {onRemove && (
            <Button alignSelf="flex-start" onClick={this.handleRemove} intent="danger" icon="cross" small minimal />
          )}
        </Flex>
      </Box>
    );
  }
}
