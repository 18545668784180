import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { formConsumer, Field, InputGroup, InputMultiple, Select } from 'core/form';
import {
  Link,
  Text,
  Flex,
  CalloutOutline,
  Callout,
  Box,
  Tabs,
  Tab,
  Select as BaseSelect,
  Heading
} from 'core/components';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$kproxyAgents')
@inject('$devices', '$dictionary', '$labels', '$setup')
@formConsumer
@observer
class FlowSettings extends Component {
  state = {
    kproxyIP: undefined,
    kproxyId: undefined
  };

  renderDirect() {
    const { $devices } = this.props;
    const { companySettings } = $devices;

    return (
      <Flex flexDirection="column" gap="12px">
        <Text>Configure this device to send flows to Kentik with the following details:</Text>
        <Flex>
          <Text width={180} ml={2}>
            Kentik Ingest IP:
          </Text>
          <Text fontWeight="bold" monospace>
            {companySettings.flow_ips.join(', ')}
          </Text>
        </Flex>
        <Flex>
          <Text width={180} ml={2}>
            Kentik Ingest UDP port:
          </Text>
          <Text fontWeight="bold" monospace>
            {companySettings.port}
          </Text>
        </Flex>
      </Flex>
    );
  }

  renderKproxy() {
    const { form, $kproxyAgents, role } = this.props;
    const { kproxyId, kproxyIP } = this.state;
    const device_name = form.getValue('device_name');

    const ariaProps = {};
    if (role) {
      ariaProps.role = role;
    }

    return (
      <Flex flexDirection="column" gap="12px" {...ariaProps}>
        <BaseSelect
          menuWidth={310}
          placeholder="Select a kproxy to show the private IP below"
          options={$kproxyAgents.configurableKproxyAgentOptions}
          onChange={this.handleKproxySelect}
          values={kproxyId}
        />

        <Text as="div">
          Configure {device_name ? <strong>{device_name}</strong> : 'this device'} to send flows to kproxy with the
          following details:
        </Text>
        <Flex>
          <Text as="div" width={180} ml={2}>
            kproxy Private IP:
          </Text>
          <Text as="div" fontWeight="bold" monospace>
            {kproxyIP || '<kproxy_ip_address>'}
          </Text>
        </Flex>
        <Flex>
          <Text as="div" width={180} ml={2}>
            kproxy port:
          </Text>
          <Text as="div" fontWeight="bold" monospace>
            9995
          </Text>
        </Flex>
      </Flex>
    );
  }

  handleKproxySelect = (id) => {
    const { $kproxyAgents } = this.props;
    const model = id ? $kproxyAgents.collection.get(id) : undefined;

    this.setState({ kproxyId: id, kproxyIP: model ? model.get('agent_private_ip') : undefined });
  };

  render() {
    const { form, $devices, $dictionary, $setup } = this.props;
    const { model } = form;

    const device_subtype = form.getValue('device_subtype');
    const deviceType = $devices.getDeviceTypeFromSubtype(device_subtype);

    const isnProbe = deviceType === 'host-nprobe-basic';
    const isKP = deviceType === 'host-nprobe-dns-www';
    const isRouter = deviceType === 'router';
    const kbLinkFlows = $dictionary.getHelpUrl('admin', 'configuringFlows');
    const kbLinkKprobe = $dictionary.getHelpUrl('admin', 'configuringKprobe');

    const sampleRateHasChanged =
      (model.isNew &&
        `${form.getValue('device_sample_rate')}` !== `${model.getDefaultSampleRateForDeviceType(deviceType)}`) ||
      (!model.isNew && `${form.getValue('device_sample_rate')}` !== `${form.initialValues.device_sample_rate}`);

    return (
      <Box overflow="auto" p={2}>
        <Heading level={5} pb="6px" mb={1} borderBottom="thin">
          Collection method
        </Heading>

        {isRouter && (
          <CalloutOutline intent="warning" mb={2} p={2}>
            <Tabs defaultSelectedTabId="direct">
              <Tab id="direct" title="Direct" panel={this.renderDirect()} />
              <Tab id="kproxy" title="Via kproxy" panel={this.renderKproxy()} />
            </Tabs>

            <Box mt={2}>
              <Link to={kbLinkFlows} blank>
                Need help configuring Flows?
              </Link>
            </Box>
          </CalloutOutline>
        )}

        {isnProbe && (
          <Callout intent="primary" mb={2}>
            <Text as="div" fontWeight="bold">
              nProbe Command Line Info
            </Text>
            <p>
              Download nprobe:
              <Link ml={1} to="http://packages.ntop.org" blank>
                http://packages.ntop.org
              </Link>
            </p>

            <p>
              The direct static version, &quot;nprobes&quot;, requires no dependencies and is available in each package
              directory as a standalone executable.
            </p>

            <div>
              <p>Execute</p>
              <pre>nprobe: ./nprobes --kentik-host -i eth0 &</pre>
            </div>

            <p className="no-margin">
              In the command above, replace eth0 with the interface that you wish to monitor. You may run multiple
              instances for multiple interfaces. Only 1 Kentik &quot;device&quot; is needed per host, regardless of how
              many nprobe instances are running on that host for multiple interfaces.
            </p>
          </Callout>
        )}

        {isKP && (
          <Callout intent="primary" mb={2}>
            <Link to={kbLinkKprobe} blank>
              Need help installing and configuring kprobe?
            </Link>
          </Callout>
        )}

        <Flex flexDirection="column" gap={2} mb={2}>
          <Heading level={5} pb="6px" mb={0} borderBottom="thin">
            Flow export configuration
          </Heading>

          <Field name="sending_ips" m={0}>
            <InputMultiple inputStyle={{ width: 250 }} addButtonText="Add Sending IP" />
          </Field>

          <Flex mb={0}>
            <Field name="device_sample_rate" helpText={this.sampleRateHelpText} m={0}>
              <InputGroup width={120} />
            </Field>

            {deviceType === 'host-nprobe-dns-www' && sampleRateHasChanged && (
              <Callout intent="danger" ml={2} mb={0}>
                kprobe sample rate changes for instances where sample rate is CLI controlled will cause the kprobe
                process to exit. We recommend running kprobe supervised with a time between restarts of 30 seconds and
                give up after 5 retries.
              </Callout>
            )}
          </Flex>
        </Flex>

        <Flex flexDirection="column" gap={2}>
          <Heading level={5} pb="6px" mb={0} borderBottom="thin">
            Licenses
          </Heading>

          <Field
            name="plan.id"
            m={0}
            disabled={!$setup.planOptions.length}
            helpText={
              $setup.planOptions.length
                ? 'The flow plan to apply to this device'
                : 'There are no available device slots in your plans. Please contact sales@kentik.com to add more devices.'
            }
            options={$setup.planOptions}
            inputStyle={{ width: 400 }}
          >
            <Select menuWidth={400} />
          </Field>
        </Flex>
      </Box>
    );
  }
}

export default FlowSettings;
