import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';

import { Box, Text } from 'core/components';
import AlarmValue from './AlarmValue';

const DetailsBox = styled(Box)`
  margin-right: 40px;
  justify-content: flex-start;

  &:last-child {
    margin-right: 0;
  }
`;

const HelperText = styled(Text)`
  display: block;
  padding: 2px 0 1px 0;
  white-space: nowrap;
  font-size: 12px;
`;

@inject('$metrics', '$alerting')
@observer
class AlertDimensionInfo extends Component {
  renderDimensions = () => {
    const { model } = this.props;

    return model.dimensions
      .filter((dim) => dim.key !== 'app_module')
      .map(({ key, label, lookup }) => {
        const alarmText = lookup;

        // Skip time window dimensions
        if (['test_window_end', 'test_window_start', 'ref_window_end', 'ref_window_start'].includes(key)) {
          return null;
        }

        if (key.startsWith('~') && key.endsWith('~')) {
          return null;
        }

        return (
          <DetailsBox key={key} mt={0} mb={2}>
            <Text muted>{label}</Text>
            <AlarmValue large bold value={alarmText} />
          </DetailsBox>
        );
      });
  };

  renderValues = (baseline) => {
    const { model } = this.props;

    if (model.values.length === 0) {
      return null;
    }

    return model.values.map(({ formattedValue, formattedMetric, metric }, idx) => {
      const metricThreshold = model.getThreshold(metric);

      const hasBaseline = baseline && Number.isFinite(baseline.percentage) && baseline.metric === metric;
      const threshold = metricThreshold && metricThreshold.direction === 'currentToHistory' ? metricThreshold : null;

      if (formattedValue === 'Missing' && !formattedMetric) {
        return null;
      }

      if (model.isEventPolicy) {
        // Event policies have one display-only metric
        return (
          <DetailsBox key={metric} display="flex" flexDirection="column" justifyContent="flex-end">
            <Text muted>Primary Metric</Text>
            <AlarmValue large bold value={formattedMetric} />
          </DetailsBox>
        );
      }

      return (
        <DetailsBox key={metric} display="flex" flexDirection="column" justifyContent="flex-end">
          <Text muted>{idx === 0 ? 'Primary' : 'Secondary'} Metric</Text>
          <AlarmValue large bold value={formattedValue} unit={formattedMetric} />
          {hasBaseline && (
            <HelperText color="severity.baseline">
              <strong>{baseline.percentage}%</strong> {baseline.over ? 'above' : 'below'} baseline
            </HelperText>
          )}
          {threshold && (
            <HelperText color={model.severityColor}>
              <strong>{threshold.percentage < 10000 ? `${threshold.percentage}%` : threshold.formattedDiff}</strong>{' '}
              {threshold.over ? 'above' : 'below'} {model.severity.toLowerCase()} threshold
            </HelperText>
          )}
        </DetailsBox>
      );
    });
  };

  renderBaseline = (baseline) => {
    const { model } = this.props;

    return (
      <DetailsBox>
        <Text muted>Baseline</Text>
        <AlarmValue large bold value={baseline.formattedValue} unit={baseline.formattedMetric} />
        {model.threshold && model.threshold.direction === 'historyToCurrent' && (
          <HelperText color={model.severityColor}>
            <strong>
              {model.threshold.percentage < 10000 ? `${model.threshold.percentage}%` : model.threshold.formattedDiff}
            </strong>{' '}
            {model.threshold.over ? 'above' : 'below'} {model.severity.toLowerCase()} threshold
          </HelperText>
        )}
      </DetailsBox>
    );
  };

  render() {
    const { model, $alerting, hideValues } = this.props;
    const baseline = $alerting.getBaseline(model);

    return (
      <>
        {model.isEventPolicy && this.renderValues(baseline)}
        {this.renderDimensions()}
        {!model.isEventPolicy && baseline && this.renderBaseline(baseline)}
        {!model.isEventPolicy && !hideValues && this.renderValues(baseline)}
      </>
    );
  }
}

export default AlertDimensionInfo;
