const ALARM_NULL_END_DATE = '0001-01-01T00:00:00Z';

const ALARM_STATES_RAW = {
  ACTIVE: 'ALARM_STATE_ACTIVE',
  CLEAR: 'ALARM_STATE_CLEAR'
};

const ALARM_STATES_KEY = {
  ACTIVE: 'alarm',
  CLEAR: 'clear'
};

const ALARM_STATE_KEY_TO_RAW = {
  [ALARM_STATES_KEY.ACTIVE]: ALARM_STATES_RAW.ACTIVE,
  [ALARM_STATES_KEY.CLEAR]: ALARM_STATES_RAW.CLEAR
};

const ALARM_STATE_RAW_TO_KEY = {
  [ALARM_STATES_RAW.ACTIVE]: ALARM_STATES_KEY.ACTIVE,
  [ALARM_STATES_RAW.CLEAR]: ALARM_STATES_KEY.CLEAR
};

const ALERT_TABLE_COLUMNS = {
  TYPE: 'type',
  APPLICATION: 'application',
  STATE: 'state',
  STATE_LABEL: 'stateLabel',
  STATUS: 'status',
  SEVERITY: 'severity',
  POLICY_NAME: 'policyName',
  POLICY_ID: 'policyID',
  MITIGATION_ID: 'mitigationID',
  START_TIME: 'startTime',
  TIME: 'time',
  DURATION: 'duration',
  PRIMARY_DIMENSION: 'primaryDimension',
  METRIC: 'metric',
  ID: 'id',
  TENANT: 'tenant',
  SILENCE_MODEL: 'silenceModel',
  ACK_STATE_LABEL: 'ackStateLabel'
};

const ALERT_BASELINE_REASONS = {
  ACT_NOT_USED_BASELINE: {
    message: 'A static threshold was used (no baselining).',
    isMatch: true,
    isBaselineFallback: true
  },
  ACT_BASELINE_MISSING_SKIP: { message: '', isMatch: true, isBaselineFallback: true },
  ACT_BASELINE_MISSING_TRIGGER: {
    message: 'No baseline value was found for this key.',
    isMatch: true,
    isBaselineFallback: true
  },
  ACT_BASELINE_USED_FOUND: {
    message: 'The key’s current value exceeded the baseline value.',
    isMatch: true,
    isBaselineFallback: false
  },
  ACT_BASELINE_MISSING_DEFAULT: {
    message: 'No baseline value was found for this key and this key’s current value exceeded a default value.',
    isMatch: true,
    isBaselineFallback: true
  },
  ACT_BASELINE_MISSING_LOWEST: {
    message:
      'No baseline value was found for this key and this key’s current value exceeded the lowest value for all other baseline key values.',
    isMatch: true,
    isBaselineFallback: true
  },
  ACT_BASELINE_MISSING_HIGHEST: {
    message:
      'No baseline value was found for this key and this key’s current value exceeded the highest value for all other baseline key values.',
    isMatch: true,
    isBaselineFallback: true
  },
  ACT_BASELINE_NOT_FOUND_EXISTS: {
    message: 'No baseline value was found for this key and this key has a current value.',
    isMatch: true,
    isBaselineFallback: true
  },
  ACT_CURRENT_MISSING_SKIP: { message: '', isMatch: false, isBaselineFallback: false },
  ACT_CURRENT_MISSING_TRIGGER: {
    message: 'No current value was found for this key.',
    isMatch: false,
    isBaselineFallback: false
  },
  ACT_CURRENT_USED_FOUND: {
    message: 'The key’s baseline value exceeded its current value.',
    isMatch: true,
    isBaselineFallback: false
  },
  ACT_CURRENT_MISSING_DEFAULT: {
    message: 'No current value was found for this key and this key’s baseline value exceeded a default value.',
    isMatch: false,
    isBaselineFallback: false
  },
  ACT_CURRENT_MISSING_LOWEST: {
    message:
      'No current value was found for this key and this key’s baseline value exceeded the lowest value for all other current values.',
    isMatch: false,
    isBaselineFallback: false
  },
  ACT_CURRENT_MISSING_HIGHEST: {
    message:
      'No current value was found for this key and this key’s baseline value exceeded the highest value for all other current values.',
    isMatch: false,
    isBaselineFallback: false
  },
  ACT_CURRENT_NOT_FOUND_EXISTS: {
    message: 'No current value was found for this key and this key has a baseline value.',
    isMatch: false,
    isBaselineFallback: false
  },
  ACT_BASELINE_MISSING_DEFAULT_INSTEAD_OF_LOWEST: {
    message:
      'No baseline value was found for this key and this key’s current value exceeded the default value and there were no other (lowest) values in the baseline available.',
    isMatch: true,
    isBaselineFallback: true
  },
  ACT_BASELINE_MISSING_DEFAULT_INSTEAD_OF_HIGHEST: {
    message:
      'No baseline value was found for this key and this key’s current value exceeded the default value and there were no other (highest) values in the baseline available.',
    isMatch: true,
    isBaselineFallback: true
  },
  ACT_CURRENT_MISSING_DEFAULT_INSTEAD_OF_LOWEST: {
    message:
      'No current value was found for this key and this key’s baseline value exceeded the default value and there were no other (lowest) current values available.',
    isMatch: false,
    isBaselineFallback: false
  },
  ACT_CURRENT_MISSING_DEFAULT_INSTEAD_OF_HIGHEST: {
    message:
      'No current value was found for this key and this key’s baseline value exceeded the default value and there were no other (highest) current values available.',
    isMatch: false,
    isBaselineFallback: false
  }
};

const ALERT_ID_SEARCH_REGEX = /^[a-zA-Z0-9-]*$/;

const MAX_WINDOW_LENGTH_MIN = 6 * 60;

const ALERT_BASELINE_REASONS_MAP = {
  0: 'ACT_NOT_USED_BASELINE',
  1: 'ACT_BASELINE_MISSING_SKIP',
  2: 'ACT_BASELINE_MISSING_TRIGGER',
  3: 'ACT_BASELINE_USED_FOUND',
  4: 'ACT_BASELINE_MISSING_DEFAULT',
  5: 'ACT_BASELINE_MISSING_LOWEST',
  6: 'ACT_BASELINE_MISSING_HIGHEST',
  7: 'ACT_BASELINE_NOT_FOUND_EXISTS',
  8: 'ACT_CURRENT_MISSING_SKIP',
  9: 'ACT_CURRENT_MISSING_TRIGGER',
  10: 'ACT_CURRENT_USED_FOUND',
  11: 'ACT_CURRENT_MISSING_DEFAULT',
  12: 'ACT_CURRENT_MISSING_LOWEST',
  13: 'ACT_CURRENT_MISSING_HIGHEST',
  14: 'ACT_CURRENT_NOT_FOUND_EXISTS',
  15: 'ACT_BASELINE_MISSING_DEFAULT_INSTEAD_OF_LOWEST',
  16: 'ACT_BASELINE_MISSING_DEFAULT_INSTEAD_OF_HIGHEST',
  17: 'ACT_CURRENT_MISSING_DEFAULT_INSTEAD_OF_LOWEST',
  18: 'ACT_CURRENT_MISSING_DEFAULT_INSTEAD_OF_HIGHEST'
};

const ALERT_STATE_COLORS = {
  alarm: 'danger',
  ackReq: 'primary',
  clear: 'success'
};

const ALERT_STATE_ICONS = {
  alarm: 'notifications',
  ackReq: 'confirm',
  clear: 'tick'
};

const ALERT_STATE_LABELS = {
  alarm: 'Active',
  clear: 'Cleared'
};

const ALERT_SUMMARY_OPTIONS = [
  { label: 'State', value: 'states' },
  { label: 'Severity', value: 'severities' },
  { label: 'Type', value: 'application' },
  { label: 'Policy', value: 'policies' }
];

const DIMENSION_LOOKUP_PATHS = {
  i_device_id: 'device.name',
  i_device_site_name: 'site.name',
  InterfaceID_src: 'interface.snmpDescription',
  InterfaceID_dst: 'interface.snmpDescription',
  ktappprotocol__snmp__output_port: 'interface.snmpDescription',
  ktappprotocol__snmp__i_device_name: 'device.name',
  ktappprotocol__snmp__i_device_site_name: 'site.name',
  src_nexthop_asn: 'asn.description',
  dst_nexthop_asn: 'asn.description',
  src_as: 'asn.description',
  dst_as: 'asn.description',
  AS_src: 'asn.description',
  AS_dst: 'asn.description'
};

const DIMENSION_TO_LABEL = {
  cdn: 'CDN',
  i_src_cdn: 'CDN',
  src_cdn: 'CDN',
  src_as: 'Src AS Number',
  dst_as: 'Dest AS Number',
  src_geo: 'Src Country',
  dst_geo: 'Dest Country',
  inet_dst_addr: 'Dest IP Address',
  inet_src_addr: 'Src IP Address',
  device_id: 'Device',
  snmp_id: 'Interface',
  agent_alias: 'Agent',
  agent_cloud_region: 'Region',
  agent_cloud_provider: 'Cloud',
  agent_asn: 'ASN',
  agent_ip: 'IP Address',
  test_id: 'Test ID',
  test_name: 'Test Name',
  test_type: 'Test Type',
  test_target: 'Test Target',
  dst_inet_addr: 'Tested IP',
  ktsubtype__aws_subnet__str17: 'Gateway ID',
  ktsubtype__aws_subnet__str19: 'Gateway',
  capacity_plan_id: 'Capacity plan'
};

const EVALUATION_FREQUENCY_OPTIONS = [
  {
    value: 15,
    label: '15 Seconds'
  },
  {
    value: 20,
    label: '20 Seconds'
  },
  {
    value: 30,
    label: '30 Seconds'
  },
  {
    value: 60,
    label: '60 Seconds'
  },
  {
    value: 120,
    label: '2 Minutes'
  },
  {
    value: 300,
    label: '5 Minutes'
  }
];

/*
  NMS Interface Up/Down policies use the Interface selector.
  However, the output from this component does not match the dimensions
  we need to match correctly on Kmetrics policies. This map is used to
  convert the output from the component to the correct dimension, as well
  as define the operator to use, and friendly names for display.
*/
const INTERFACE_FILTER_MAP = {
  interface_description: {
    dimension: 'name',
    label: 'Interface Name',
    operator: '~*'
  },
  snmp_alias: {
    dimension: 'description',
    label: 'Interface Description',
    readable: 'matches regex',
    operator: '~*'
  },
  // TODO: This isn't currently supported but we'd love to bring it back
  // interface_ip: {
  //   dimension: 'if_Address',
  //   label: 'Interface IP Address',
  //   readable: 'like',
  //   operator: 'ILIKE'
  // },
  device_id: {
    dimension: 'km_device_id',
    label: 'On devices',
    readable: 'including',
    operator: '='
  },
  device_name: {
    dimension: 'device_name',
    label: 'Devices',
    readable: 'matches regex',
    operator: '~*'
  },
  // TODO: This isn't currently supported but we'd love to bring it back
  // site_id: {
  //   dimension: 'tags.site',
  //   label: 'Site',
  //   readable: 'matches regex',
  //   operator: 'ILIKE'
  // },
  snmp_speed: {
    dimension: 'speed',
    label: 'Capacity',
    readable: 'is',
    operator: 'ILIKE'
  }
  // TODO: This isn't currently supported but we'd love to bring it back
  // interface_type: {
  //   dimension: 'type',
  //   label: 'Interface Type',
  //   readable: 'matches regex',
  //   operator: '~*'
  // }
};

const POLICY_AGG_FUNCTIONS = {
  avg: 'AGG_FUNC_AVERAGE',
  last: 'AGG_FUNC_LAST',
  min: 'AGG_FUNC_MIN',
  max: 'AGG_FUNC_MAX'
};

// Same object as POLICY_AGG_FUNCTIONS, but with key & value swapped
const POLICY_AGG_FUNCTIONS_DESERIALIZE = Object.keys(POLICY_AGG_FUNCTIONS).reduce(
  (acc, key) => ({ ...acc, [POLICY_AGG_FUNCTIONS[key]]: key }),
  {}
);

const POLICY_APPLICATION_LABELS = {
  cloud: 'Cloud',
  ddos: 'Protect',
  core: 'Traffic',
  synthetics: 'Synthetics',
  kmetrics: 'NMS',
  nms: 'NMS',
  kevent: 'NMS'
};

const NMS_INTERNAL_POLICY_APPLICATION_LABEL = 'NMS-native only';
const KEVENT_INTERNAL_POLICY_APPLICATION_LABEL = 'Event only';

const POLICY_APPLICATION_COLORS = {
  core: '#4046CA',
  ddos: '#0FB5AE',
  synthetics: '#FE5200',
  kmetrics: '#DE3D82',
  cloud: '#7E84FA'
};

const POLICY_APPLICATIONS = {
  CLOUD: 'cloud',
  DDOS: 'ddos',
  CORE: 'core',
  SYNTHETICS: 'synthetics',
  METRIC: 'kmetrics',
  NMS: 'nms',
  KEVENT: 'kevent'
};

// Intentionally omits: Native NMS, Synthetics, Kevent
// Empty string stays until we know it's safe to omit.
const STANDARD_POLICY_APPLICATIONS = [
  POLICY_APPLICATIONS.CLOUD,
  POLICY_APPLICATIONS.CORE,
  POLICY_APPLICATIONS.DDOS,
  POLICY_APPLICATIONS.METRIC,
  ''
];

const POLICY_MODES = {
  TOGGLE: 'activationModeToggle',
  WINDOW: 'activationModeRollingWindow'
};

const RECON_POLICY_SUBTYPES = {
  INTERFACES: 'interfaces',
  DEVICES: 'devices',
  BGP_NEIGHBORS: 'bgp_neighbors',
  CUSTOM: 'custom'
};

const EVENT_POLICY_TYPES = {
  SNMP_TRAP: 'snmp_traps', // Using plural under the hood to match dimension app proto
  SYSLOG: 'syslog' // This also matches the dimension app proto
};

const EVENT_POLICY_METRICS = {
  SNMP_TRAP: `ktappprotocol__event_${EVENT_POLICY_TYPES.SNMP_TRAP}__i_count`,
  SYSLOG: `ktappprotocol__event_${EVENT_POLICY_TYPES.SYSLOG}__i_count`
};

const ALERT_ACKSTATE_LABELS = {
  ALARM_ACKNOWLEDGEMENT_NOT_ACKED: 'Not Acked',
  ALARM_ACKNOWLEDGEMENT_DONE: 'Acked',
  ALARM_ACKNOWLEDGEMENT_REQUIRED: 'Ack Required',
  ACKED_BY_ME: 'Acked by Me'
};

const ALERT_ACKSTATES = {
  NOT_ACKED: 'ALARM_ACKNOWLEDGEMENT_NOT_ACKED',
  DONE: 'ALARM_ACKNOWLEDGEMENT_DONE',
  REQUIRED: 'ALARM_ACKNOWLEDGEMENT_REQUIRED'
};

// Different services use different values for severity.
// Standard alert policies have severities using STANDARD_ALERT values
// Alert manager resources, such as alarms, have severities using ALERT_MANAGER values. NMS alert policies also use alert manager severity values.
// Notify service resources, such as channel conjunctions, have severities using NOTIFY values
const SEVERITY_MAP = {
  MINOR: {
    ALERT_MANAGER: 'SEVERITY_MINOR',
    NOTIFY: 'minor',
    STANDARD_ALERT: 'minor'
  },
  WARNING: {
    ALERT_MANAGER: 'SEVERITY_WARNING',
    NOTIFY: 'warning',
    STANDARD_ALERT: 'minor2'
  },
  MAJOR: {
    ALERT_MANAGER: 'SEVERITY_MAJOR',
    NOTIFY: 'major',
    STANDARD_ALERT: 'major'
  },
  SEVERE: {
    ALERT_MANAGER: 'SEVERITY_SEVERE',
    NOTIFY: 'severe',
    STANDARD_ALERT: 'major2'
  },
  CRITICAL: {
    ALERT_MANAGER: 'SEVERITY_CRITICAL',
    NOTIFY: 'critical',
    STANDARD_ALERT: 'critical'
  },
  CLEAR: {
    ALERT_MANAGER: 'SEVERITY_CLEAR',
    NOTIFY: '',
    STANDARD_ALERT: 'clear'
  }
};

const STANDARD_ALERT_SEVERITY_TO_ALERT_MANAGER_SEVERITY = {
  [SEVERITY_MAP.MINOR.STANDARD_ALERT]: SEVERITY_MAP.MINOR.ALERT_MANAGER,
  [SEVERITY_MAP.WARNING.STANDARD_ALERT]: SEVERITY_MAP.WARNING.ALERT_MANAGER,
  [SEVERITY_MAP.MAJOR.STANDARD_ALERT]: SEVERITY_MAP.MAJOR.ALERT_MANAGER,
  [SEVERITY_MAP.SEVERE.STANDARD_ALERT]: SEVERITY_MAP.SEVERE.ALERT_MANAGER,
  [SEVERITY_MAP.CRITICAL.STANDARD_ALERT]: SEVERITY_MAP.CRITICAL.ALERT_MANAGER,
  [SEVERITY_MAP.CLEAR.STANDARD_ALERT]: SEVERITY_MAP.CLEAR.ALERT_MANAGER
};

const ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY = {
  [SEVERITY_MAP.MINOR.ALERT_MANAGER]: SEVERITY_MAP.MINOR.STANDARD_ALERT,
  [SEVERITY_MAP.WARNING.ALERT_MANAGER]: SEVERITY_MAP.WARNING.STANDARD_ALERT,
  [SEVERITY_MAP.MAJOR.ALERT_MANAGER]: SEVERITY_MAP.MAJOR.STANDARD_ALERT,
  [SEVERITY_MAP.SEVERE.ALERT_MANAGER]: SEVERITY_MAP.SEVERE.STANDARD_ALERT,
  [SEVERITY_MAP.CRITICAL.ALERT_MANAGER]: SEVERITY_MAP.CRITICAL.STANDARD_ALERT,
  [SEVERITY_MAP.CLEAR.ALERT_MANAGER]: SEVERITY_MAP.CLEAR.STANDARD_ALERT
};

const SEVERITY_KEY_OVERRIDES = {
  [SEVERITY_MAP.SEVERE.NOTIFY]: SEVERITY_MAP.SEVERE.STANDARD_ALERT,
  [SEVERITY_MAP.WARNING.NOTIFY]: SEVERITY_MAP.WARNING.STANDARD_ALERT
};

const ALERT_SEVERITIES = [
  SEVERITY_MAP.CRITICAL.STANDARD_ALERT,
  SEVERITY_MAP.SEVERE.STANDARD_ALERT,
  SEVERITY_MAP.MAJOR.STANDARD_ALERT,
  SEVERITY_MAP.WARNING.STANDARD_ALERT,
  SEVERITY_MAP.MINOR.STANDARD_ALERT
];

const ALERT_SEVERITY_COLORS = {
  [SEVERITY_MAP.CRITICAL.STANDARD_ALERT]: 'severity.critical',
  [SEVERITY_MAP.SEVERE.STANDARD_ALERT]: 'severity.major2',
  [SEVERITY_MAP.MAJOR.STANDARD_ALERT]: 'severity.major',
  [SEVERITY_MAP.WARNING.STANDARD_ALERT]: 'severity.minor2',
  [SEVERITY_MAP.MINOR.STANDARD_ALERT]: 'severity.minor',
  [SEVERITY_MAP.CLEAR.STANDARD_ALERT]: 'severity.baseline'
};

const ALERT_SEVERITY_LABELS = {
  [SEVERITY_MAP.CRITICAL.STANDARD_ALERT]: 'Critical',
  [SEVERITY_MAP.SEVERE.STANDARD_ALERT]: 'Severe',
  [SEVERITY_MAP.MAJOR.STANDARD_ALERT]: 'Major',
  [SEVERITY_MAP.WARNING.STANDARD_ALERT]: 'Warning',
  [SEVERITY_MAP.MINOR.STANDARD_ALERT]: 'Minor',
  clear: 'Clear'
};

const ALERT_SEVERITY_RANKS = {
  Critical: 5,
  Severe: 4,
  Major: 3,
  Warning: 2,
  Minor: 1,
  Clear: 0
};

const SEVERITIES_TEMPLATE = {
  [SEVERITY_MAP.CRITICAL.STANDARD_ALERT]: 0,
  [SEVERITY_MAP.SEVERE.STANDARD_ALERT]: 0,
  [SEVERITY_MAP.MAJOR.STANDARD_ALERT]: 0,
  [SEVERITY_MAP.WARNING.STANDARD_ALERT]: 0,
  [SEVERITY_MAP.MINOR.STANDARD_ALERT]: 0
};

const SUPPORTED_AGGREGATION_FUNCTIONS = [{ value: 'avg', label: 'Average' }];

const PERCENTAGE_METRICS = [
  'flows_week_over_week',
  'bps_day_over_day',
  'bps_week_over_week',
  'hopcount_percent_change',
  'last_average_packet_loss_percent',
  'new_average_packet_loss_percent',
  'last_max_packet_loss_percent',
  'new_max_packet_loss_percent'
];

const LATENCY_METRICS = [
  'last_average_latency_without_zero',
  'new_average_latency_without_zero',
  'last_stddev_latency_without_zero',
  'new_stddev_latency_without_zero',
  'last_max_latency',
  'new_max_latency',
  'last_latency',
  'new_latency'
];

const SUPPRESSION_TYPES = {
  METRIC: 'conditionNms',
  NMS: 'conditionNmsNative',
  SYNTH: 'testv2',
  POLICY: 'policy',
  AGENT: 'agent',
  CONDITION: 'condition'
};

const MAPPED_METRICS = { bps: 'bytes', bytes: 'total_bytes', peak_bps: 'bytes' };

const METRIC_LABELS = {
  last_average_latency_without_zero: 'Last Week Avg Latency',
  new_average_latency_without_zero: 'New Avg Latency',
  last_stddev_latency_without_zero: 'Last Week Latency Std Dev',
  new_stddev_latency_without_zero: 'New Latency Std Dev',
  last_max_latency: 'Last Week Max Latency',
  new_max_latency: 'New Max Latency',
  last_latency: 'Latency',
  new_latency: 'New Latency',
  last_average_packet_loss_percent: 'Last Week Avg Packet Loss',
  new_average_packet_loss_percent: 'New Avg Packet Loss',
  last_max_packet_loss_percent: 'Last Week Max Packet Loss',
  new_max_packet_loss_percent: 'New Max Packet Loss',
  last_hop_count: 'Hop Count',
  new_hop_count: 'New Hop Count',
  ingress_flow_avg: 'Ingress Flow Average',
  egress_flow_avg: 'Egress Flow Average',
  ingress_snmp_avg: 'Ingress SNMP Average',
  egress_snmp_avg: 'Egress SNMP Average',
  last_agent_count: 'Agent Count',
  last_attempt_count: 'Test Attempts',
  last_error_count: 'Errors',
  last_timeout_count: 'Timeouts',
  last_success_count: 'Successful Tests',
  new_agent_count: 'New Agent Count',
  new_attempt_count: 'New Test Attempts',
  new_error_count: 'New Errors',
  new_timeout_count: 'New Timeouts',
  new_success_count: 'New Successful Tests',
  num_ifaces_in_plan: 'Number of interfaces in plan',
  iface_limit: 'Interfaces Limit'
};

const HOUR_IN_SECONDS = 3600;

const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;

const ALERT_LOOKBACK_OPTIONS = [
  {
    label: 'Alert +/- 1 hour',
    value: -HOUR_IN_SECONDS
  },
  {
    label: 'Alert +/- 24 hours',
    value: -DAY_IN_SECONDS
  },
  {
    label: 'Last hour',
    value: HOUR_IN_SECONDS
  },
  {
    label: 'Last day',
    value: DAY_IN_SECONDS
  },
  {
    label: 'Last 7 days',
    value: DAY_IN_SECONDS * 7
  },
  {
    label: 'Last 14 days',
    value: DAY_IN_SECONDS * 14
  },
  {
    label: 'Last 30 days',
    value: DAY_IN_SECONDS * 30
  }
];

// We use these as fallback max key values if the company doesn't have a keysPerPolicy/keysPerMetricPolicy permission defined
const MAX_KEYS_PER_POLICY = Object.freeze({
  // alerts.limits.keyPerMetricPolicy
  [POLICY_APPLICATIONS.METRIC]: 10000,
  // alerts.limits.keyPerPolicy
  default: 1000
});

const POLICY_DEFAULT_VALUES = Object.freeze({
  nTopKeysEvaluated: 25
});

const COMPARISON_DIRECTIONS = Object.freeze({
  TO_HISTORY: 'currentToHistory', // Default value
  TO_CURRENT: 'historyToCurrent'
});

const THRESHOLD_CONDITION_TYPES = Object.freeze({
  STATIC: 'static',
  BASELINE: 'baseline',
  BASELINE_PERCENT: 'baselinePercent',
  CAPACITY: 'interfaceCapacity',
  CAPACITY_PERCENT: 'interfaceCapacityPercent',
  RATIO: 'ratio',
  TOP_KEYS: 'keyNotInTop'
});

const OPERATOR_SHORT_LABELS = Object.freeze({
  equals: '=',
  notEquals: '≠',
  lessThan: '<',
  greaterThan: '>',
  lessThanOrEquals: '≤',
  greaterThanOrEquals: '≥'
});

const SERIALIZE_FILTER_OPERATOR_MAP = Object.freeze({
  '=': 'equals',
  '<>': 'notEquals',
  '&': 'bitwiseAnd',
  '|': 'bitwiseOr',
  '<': 'lessThan',
  '>': 'greaterThan',
  '<=': 'lessThanOrEquals',
  '>=': 'greaterThanOrEquals',
  'NOT ILIKE': 'notContains',
  ILIKE: 'contains',
  '!~': 'notContainsRe',
  '~': 'containsRe',
  '!~*': 'notContainsReStar',
  '~*': 'containsReStar'
});

// Based on SERIALIZE_FILTER_OPERATOR_MAP, but with key & value swapped
const DESERIALIZE_FILTER_OPERATOR_MAP = Object.keys(SERIALIZE_FILTER_OPERATOR_MAP).reduce(
  (acc, key) => ({ ...acc, [SERIALIZE_FILTER_OPERATOR_MAP[key]]: key }),
  {}
);

/**
 * Note: rule responses are snake_cased, whereas policy responses are camelCased
 *
 * @typedef {T[keyof T]} Enum<T>
 * @template T
 *
 * @typedef {{
 *   filterField: string;
 *   operator: import('../filters/constants.js').FilterOperator;
 *   filterValue: string;
 * }} ApiFilter
 * @typedef {{
 *   connector: Enum<FILTER_CONNECTOR>;
 *   not: boolean;
 *   filters: ApiFilter[];
 *   filterGroups: ApiFilterGroup[];
 *   savedFilters: { id: number, not: boolean }[];
 * }} ApiFilterGroup
 * @typedef {{
 *   connector: Enum<FILTER_CONNECTOR>;
 *   filterGroups: ApiFilterGroup[];
 * }} ApiFilterRoot
 */
/**
 * rolling_window and toggle should be one of two
 * @typedef {{
 *   severity: ApiAlertSeverity;
 *   ack_required: boolean;
 *   disabled: boolean;
 *   rolling_window?: { triggerCount: number, windowLength: string, gracePeriod: string };
 *   toggle?: { activationDelay: string, clearanceDelay: string };
 * }} ApiAlertManagerRuleLevel
 */
/**
 * @typedef {Enum<STANDARD_ALERT_SEVERITY_TO_ALERT_MANAGER_SEVERITY>} ApiAlertSeverity
 * @typedef {{
 *   enrich_event_view_model: boolean;
 *   silence_notifications: boolean;
 * }} ApiAlertManagerNotificationSettings
 * @typedef {{
 *   id?: string;
 *   revision?: number;
 *   created_at?: string;
 *   modified_at?: string;
 *   created_by?: string;
 *   modified_by?: string;
 *   alias?: string;
 *   application?: string;
 *   disabled?: boolean;
 *   expire_at?: string;
 *   silent_mode_expire_at?: string;
 *   levels?: ApiAlertManagerRuleLevel[];
 *   alarms_limit?: number;
 *   notification_settings?: ApiAlertManagerNotificationSettings;
 *   alarm_key_primary_dimension?: string;
 * }} ApiAlertManagerRule
 */

module.exports = {
  ALARM_NULL_END_DATE,
  ALARM_STATES_RAW,
  ALARM_STATE_KEY_TO_RAW,
  ALARM_STATE_RAW_TO_KEY,
  ALERT_ACKSTATE_LABELS,
  ALERT_ACKSTATES,
  ALERT_BASELINE_REASONS,
  ALERT_BASELINE_REASONS_MAP,
  ALERT_ID_SEARCH_REGEX,
  ALERT_SEVERITIES,
  ALERT_SEVERITY_COLORS,
  ALERT_SEVERITY_LABELS,
  ALERT_SEVERITY_RANKS,
  ALERT_STATE_COLORS,
  ALERT_STATE_ICONS,
  ALERT_STATE_LABELS,
  ALERT_SUMMARY_OPTIONS,
  ALERT_TABLE_COLUMNS,
  HOUR_IN_SECONDS,
  DAY_IN_SECONDS,
  DIMENSION_LOOKUP_PATHS,
  DIMENSION_TO_LABEL,
  EVALUATION_FREQUENCY_OPTIONS,
  INTERFACE_FILTER_MAP,
  LATENCY_METRICS,
  MAPPED_METRICS,
  MAX_WINDOW_LENGTH_MIN,
  METRIC_LABELS,
  PERCENTAGE_METRICS,
  POLICY_AGG_FUNCTIONS,
  POLICY_AGG_FUNCTIONS_DESERIALIZE,
  POLICY_APPLICATION_COLORS,
  POLICY_APPLICATION_LABELS,
  NMS_INTERNAL_POLICY_APPLICATION_LABEL,
  KEVENT_INTERNAL_POLICY_APPLICATION_LABEL,
  POLICY_APPLICATIONS,
  STANDARD_POLICY_APPLICATIONS,
  POLICY_MODES,
  EVENT_POLICY_TYPES,
  EVENT_POLICY_METRICS,
  RECON_POLICY_SUBTYPES,
  SEVERITIES_TEMPLATE,
  SEVERITY_KEY_OVERRIDES,
  STANDARD_ALERT_SEVERITY_TO_ALERT_MANAGER_SEVERITY,
  ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY,
  SEVERITY_MAP,
  SUPPRESSION_TYPES,
  SUPPORTED_AGGREGATION_FUNCTIONS,
  ALERT_LOOKBACK_OPTIONS,
  MAX_KEYS_PER_POLICY,
  POLICY_DEFAULT_VALUES,
  COMPARISON_DIRECTIONS,
  THRESHOLD_CONDITION_TYPES,
  OPERATOR_SHORT_LABELS,
  SERIALIZE_FILTER_OPERATOR_MAP,
  DESERIALIZE_FILTER_OPERATOR_MAP
};
