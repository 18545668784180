import React, { Component } from 'react';
import { Dialog, Text } from 'core/components';

export default class DescriptionDialog extends Component {
  render() {
    const { isOpen, title, onClose, description, ...rest } = this.props;
    return (
      <Dialog
        title={title}
        isOpen={isOpen}
        canEscapeKeyClose
        canOutsideClickClose
        isCloseButtonShown
        onClose={onClose}
        {...rest}
      >
        <Dialog.Body>
          <Text as="div" whiteSpace="pre-wrap">
            {description}
          </Text>
        </Dialog.Body>
      </Dialog>
    );
  }
}
