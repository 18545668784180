import React, { Component } from 'react';
import { ENTER } from 'core/util/keyCodes';
import { Box, Button } from 'core/components';
import { TextArea } from 'core/form';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { PROMPT_LENGTH_LIMIT } from 'shared/journeys/constants';

const Wrapper = styled(Box)`
  position: relative;
  margin-bottom: 16px;
  width: 100%;
  max-width: 825px;

  &:before {
    content: '✨';
    left: 16px;
    top: 16px;
    position: absolute;
    font-size: 16px;
  }
`;

const WrappedInput = styled(TextArea)`
  &.bp4-input {
    padding-top: 16px;

    // make room for absolutely positioned button
    padding-right: 50px;
    padding-bottom: 16px;
    padding-left: 46px;
    border-radius: 10px;
  }
`;

const SendButton = styled(Button)`
  position: absolute;
  top: 9px;
  right: 10px;
  background: rgba(45, 114, 210, 0.1)
  min-width: 36px !important;
  min-height: 36px !important;
  width: 36px;
  border-radius: 30px;
  padding: 0;
`;

@inject('$auth')
@observer
class JourneyInput extends Component {
  state = {
    value: ''
  };

  componentDidMount() {
    const { prompt } = this.props;
    this.setState({ value: prompt });
  }

  onKeyDown = (e) => {
    const { value } = this.state;

    const key = e.keyCode ? e.keyCode : e.which;
    const isShiftPressed = e.shiftKey;
    const hasValue = value?.length > 0;

    // user presses ENTER without shift, and the input is empty, don't submit, don't make a new line
    if (key === ENTER && !isShiftPressed && !hasValue) {
      e.preventDefault();
      return;
    }

    // user presses ENTER with shift, add a new line
    if (key === ENTER && isShiftPressed) {
      return;
    }

    // - shift + enter does not submit, it makes a new line
    // - enter without shift submits
    // - this is the same behavior as most common apps, ChatGPT, Slack, etc.
    if (key === ENTER && !isShiftPressed && hasValue) {
      e.preventDefault();
      this.handleSendPrompt();
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ value: e.target.value });
  };

  handleSendPrompt = () => {
    const { inputRef, journey, scrollToBottom, createJourney } = this.props;
    const { value } = this.state;

    // clear the input
    this.setState({ value: '' });

    const prompt = String(value).trim().substring(0, PROMPT_LENGTH_LIMIT);

    if (!prompt?.length) {
      return;
    }

    if (!journey) {
      createJourney().then((newJourney) => {
        newJourney.addPrompt(prompt);
      });
    } else {
      journey.addPrompt(prompt).then(() => {
        inputRef?.current.focus();
      });
    }

    scrollToBottom();
  };

  render() {
    const { value } = this.state;
    const { placeholder, inputRef } = this.props;

    return (
      <Wrapper>
        <WrappedInput
          fill
          autoFocus
          autoGrow
          rows={1}
          className="jumbo"
          inputRef={inputRef}
          onChange={this.handleChange}
          placeholder={placeholder}
          value={value}
          onKeyDown={this.onKeyDown}
        />
        <SendButton
          onClick={this.handleSendPrompt}
          disabled={!value?.length}
          intent="primary"
          icon="send-message"
          minimal
          ariaLabel="Call Query"
        />
      </Wrapper>
    );
  }
}

export default JourneyInput;
