import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Text, Tooltip, MenuItem, Spinner, Flex, Box } from 'core/components';

import { getMoment, DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';

import storeLoader from 'app/stores/storeLoader';
import AckDialog from './AckDialog';

@storeLoader('$users', '$comments', '$alerting.silenceCollection')
@inject('$auth')
@observer
export class AckAlertButton extends Component {
  state = {
    ackRequestPending: false,
    showDialog: false
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  get ackUserName() {
    const { $users, model } = this.props;
    const user = $users.collection.get(model.ackUserId);

    if (user?.get) {
      return user.get('user_full_name');
    }
    return model.ackUserId;
  }

  get ackedTime() {
    const { model, $auth } = this.props;
    const moment = getMoment(model.ackTimestamp, $auth.userTimezone);
    return moment.format(DEFAULT_DATETIME_FORMAT);
  }

  get ackComment() {
    const { $comments, model } = this.props;
    const commentModel = $comments.collection.getAckCommentForEntityById(
      model.isAutoAcked ? model.autoAckId : model.id,
      model.isAutoAcked ? 'alertAutoAck' : 'alertAck'
    );
    return commentModel && commentModel.get('comment');
  }

  get ackedTooltip() {
    const { model } = this.props;
    return (
      <Box maxWidth="240px">
        <Box mb="2px">
          <Text fontWeight="bold">{this.ackUserName}</Text> ({model.ackTimestamp && <Text>{this.ackedTime})</Text>}
        </Box>
        {this.ackComment && <Text>{this.ackComment}</Text>}
      </Box>
    );
  }

  handleAckAlert = (formValues) => {
    const { model, $comments, $alerting } = this.props;
    const { comment, autoAck, silence } = formValues || {};

    this.setState({ ackRequestPending: true, showDialog: false });

    $alerting
      .acknowledgeAlarm(model, { comment, autoAck, silence, toast: true })
      .then(() => {
        if (this._mounted) {
          this.setState({
            ackRequestPending: false
          });
        }

        // We create the comment in node, so force fetch to show new model
        $comments.collection.fetch({ force: true });
      })
      .catch(() => {
        if (this._mounted) {
          this.setState({
            ackRequestPending: false
          });
        }
      });
  };

  openAckDialog = () => this.setState({ showDialog: true });

  closeAckDialog = () => this.setState({ showDialog: false });

  render() {
    const { $users, model, asMenuItem, buttonProps } = this.props;
    const { ackRequestPending, showDialog } = this.state;

    if (!model.canAck) {
      return null;
    }

    const text = 'Ack Alert';
    const Item = <MenuItem icon="confirm" text={text} disabled={ackRequestPending} onClick={this.openAckDialog} />;

    let ClickElement = asMenuItem ? (
      Item
    ) : (
      <Button
        disabled={ackRequestPending}
        fill
        tabindex={0}
        textAlign="left"
        icon="confirm"
        onClick={this.openAckDialog}
        text={text}
        rightIcon={ackRequestPending ? <Spinner ml={1} size={14} /> : null}
        {...buttonProps}
      />
    );

    if (model.isAcked) {
      ClickElement = (
        <Tooltip fill content={<Flex alignItems="center">{model.isAcked && <Text>{this.ackedTooltip}</Text>}</Flex>}>
          <Box>
            <Text as="div" fontWeight="500">
              {model.isAutoAcked ? 'Auto-acked' : 'Acked'} by
            </Text>
            <Text as="div" muted showSkeleton={$users.collection.loading}>
              {this.ackUserName}
            </Text>
          </Box>
        </Tooltip>
      );
    }

    return (
      <Box>
        {ClickElement}
        <AckDialog
          isOpen={!!showDialog}
          alertModel={model}
          onClose={this.closeAckDialog}
          onSubmit={this.handleAckAlert}
        />
      </Box>
    );
  }
}
