import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { orderBy, uniqBy } from 'lodash';

import { Button, Flex, Box, Text, SmallCaps } from 'core/components';

import ShortcutButton from './ShortcutButton';

@observer
class ShortcutGroup extends Component {
  static defaultProps = {
    showAddAllButton: false,
    showClearButton: true,
    expandable: true,
    filterable: true,
    sorted: false,
    noResultsText: 'No results',
    mb: 2
  };

  state = {
    expanded: false
  };

  static getDerivedStateFromProps(props) {
    const { options } = props;

    return {
      unfilteredOptions: uniqBy(options, 'value')
    };
  }

  componentDidMount() {
    const { selectedValues } = this.props;
    this.setState({ expanded: selectedValues.length > 0 });
  }

  handleResetGroup = () => {
    const { onChange, field } = this.props;
    onChange([], field);
  };

  handleAddAll = () => {
    const { filterable, searchInputValue, field, onAddAll } = this.props;
    const { unfilteredOptions } = this.state;
    const options = filterable
      ? unfilteredOptions.filter((opt) => opt.label.toLowerCase().includes(searchInputValue.toLowerCase()))
      : unfilteredOptions;

    onAddAll(options, field);
  };

  handleToggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  render() {
    const {
      title,
      noResultsText,
      onAdd,
      field,
      type,
      selectedValues,
      disabled,
      filterable,
      expandable,
      showClearButton,
      searchInputValue,
      sorted,
      mb
    } = this.props;
    const { expanded, unfilteredOptions } = this.state;

    const filteredOptions = filterable
      ? unfilteredOptions.filter((opt) => opt.label.toLowerCase().includes(searchInputValue.toLowerCase()))
      : unfilteredOptions;

    const options = sorted ? filteredOptions : orderBy(filteredOptions, ['label'], ['asc']);

    const showAddAll = searchInputValue && options.length > 0;
    const showClear = showClearButton && selectedValues.length > 0 && !searchInputValue;
    const showExpandButton = expandable && options.length > 5 && !expanded;

    let slicedOptions = [];
    if (options) {
      slicedOptions = options.filter(Boolean);

      if (expandable !== false && !expanded) {
        slicedOptions = slicedOptions.slice(0, 5);
      }
    }

    // If there are no options, hide the entire ShortcutGroup
    if (options.length === 0) {
      return null;
    }

    return (
      <Box mb={mb} flex={1}>
        {title && (
          <Flex alignItems="center" justifyContent="space-between" role="list">
            <SmallCaps>{title}</SmallCaps>
            <Box>
              {showAddAll && (
                <Button
                  small
                  minimal
                  intent="primary"
                  onClick={this.handleAddAll}
                  disabled={options.every((opt) => !!selectedValues.find((val) => val.id === opt.id))}
                >
                  Add All
                </Button>
              )}
              {showClear && (
                <Button small minimal intent="primary" onClick={this.handleResetGroup}>
                  Clear
                </Button>
              )}
            </Box>
          </Flex>
        )}
        {options.length === 0 && (
          <Text small muted>
            {noResultsText}
          </Text>
        )}

        {slicedOptions.map((opt) => {
          const selected = selectedValues.find((sel) => sel.value === opt.value);
          const { iconName, icon: shortcutIcon, value, label, ...rest } = opt;
          return (
            <ShortcutButton
              {...rest}
              disabled={disabled}
              active={selected}
              icon={shortcutIcon || iconName}
              key={value}
              onClick={() => onAdd(opt, field)}
              text={label}
              type={type}
              role="listitem"
            />
          );
        })}
        {showExpandButton && (
          <Button
            small
            minimal
            fill
            textAlign="left"
            color="muted"
            disabled={disabled}
            onClick={this.handleToggleExpanded}
            text={`Show ${options.length - 5} more...`}
          />
        )}
      </Box>
    );
  }
}

export default ShortcutGroup;
