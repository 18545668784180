import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import classNames from 'classnames';
import { Hotkeys, Hotkey, HotkeysTarget } from '@blueprintjs/core';

import { formAriaProps, guessTextFromPossibleElement } from 'core/util/ariaUtils';
import Flex from './Flex';
import Icon from './Icon';
import Tooltip from './Tooltip';

const Tab = styled.div`
  background-color: initial;
  border: ${({ borderless }) => (borderless ? 'none' : '1px solid transparent')};
  border-bottom-width: 0;
  border-radius: 4px 4px 0 0;
  color: ${({ theme }) => theme.colors.muted};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme, large }) => (large ? theme.fontSizes.large : theme.fontSizes.normal)};
  padding: ${({ minimal, large, hasIcon }) => {
    if (minimal) {
      return '8px 16px';
    }

    if (large) {
      return `12px ${hasIcon ? '26px' : '16px'} 12px 16px`;
    }

    return '10px 16px 8px 16px';
  }};
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    border-radius: 3px 3px 0 0;

    ${({ borderless }) =>
      borderless &&
      css`
        top: unset;
        bottom: 0;
        border-radius: 0;
      `}
  }

  &:first-child {
    margin-left: ${({ tabIndent }) => tabIndent}px;
  }

  ${({ disabled }) => {
    if (disabled) {
      return css`
        opacity: 0.5;
        cursor: not-allowed;
      `;
    }
    return css`
      &:hover {
        color: ${({ theme, active, selectedTabFontColor }) => theme.colors[active ? selectedTabFontColor : 'body']};
      }
    `;
  }};

  ${({ active, theme, minimal, selectedTabBorderColor, selectedTabBackgroundColor, selectedTabFontColor }) => {
    if (active) {
      return css`
        color: ${theme.colors[active ? selectedTabFontColor : 'body']};
        background-color: ${themeGet(`colors.${selectedTabBackgroundColor}`, selectedTabBackgroundColor)};
        border-color: ${theme.borderColors.tabs};

        &:after {
          background: ${minimal ? 'transparent' : theme.colors[selectedTabBorderColor]};
        }
      `;
    }

    return undefined;
  }};
`;

@HotkeysTarget
class BorderedTab extends Component {
  static defaultProps = {
    selectedTabBackgroundColor: 'appBackground',
    selectedTabFontColor: 'body',
    tabIndent: 6,
    borderless: false
  };

  handleSelect = () => {
    const { onClick, id, disabled } = this.props;
    if (!disabled && typeof onClick === 'function') {
      onClick(id);
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey combo="enter" label="Select Tab" onKeyDown={this.handleSelect} />
      </Hotkeys>
    );
  }

  render() {
    const { selectedTabId, onClick, id, title, icon, large, minimal, className, disabled, disabledTooltip, ...rest } =
      this.props;
    const active = selectedTabId === id;
    const tabClassName = classNames(className, `tab-body-tracker-${id}`);

    // `icon` isn't supported in minimal mode
    const tabContent =
      !minimal && icon ? (
        <Flex alignItems="center">
          <Icon icon={icon} iconSize={18} mr={1} color={active ? 'primary' : 'muted'} />
          {title}
        </Flex>
      ) : (
        title
      );

    const possibleTitle = guessTextFromPossibleElement(title);
    const ariaProps = formAriaProps(
      this.props,
      { ariaLabel: rest?.ariaLabel || possibleTitle, ariaSelected: active },
      { enableTabIndexNotDisabled: true }
    );

    const fullContent = (
      <Tab
        role="tab"
        {...ariaProps}
        className={tabClassName}
        active={active}
        large={large}
        hasIcon={!!icon}
        disabled={disabled}
        onClick={this.handleSelect}
        {...rest}
      >
        {tabContent}
      </Tab>
    );

    if (disabledTooltip && disabled) {
      return (
        <Tooltip key={title} content={disabledTooltip}>
          {fullContent}
        </Tooltip>
      );
    }

    return fullContent;
  }
}

export default BorderedTab;
