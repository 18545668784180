/**
 * Note: Do not use this non-form component in your form. Use the core/form/components/Switch instead.
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { formAriaProps, guessTextFromLabel } from 'core/util/ariaUtils';

import { space, width } from 'styled-system';
import { Classes, Switch as BlueprintSwitch } from '@blueprintjs/core';

import { omitAriaProps } from '../form/components/util/omitFieldProps';

const Switch = styled(BlueprintSwitch)`
  ${space};
  ${width};

  &.${Classes.CONTROL} {
    margin: 0;
    width: max-content;
  }

  &.${Classes.SMALL}.${Classes.CONTROL} {
    padding-left: 30px;
    font-size: 12px;

    .${Classes.CONTROL_INDICATOR} {
      font-size: 14px;
      margin-left: -30px;
      margin-top: -2px;
    }
  }

  ${({ theme, bg }) => css`
    input:checked ~ .bp4-control-indicator {
      background: ${theme.colors[bg] || theme.colors.primary} !important;
    }
  `}
`;

const Component = observer((props) => {
  const { ariaLabel, onChange, className, small, onQuery, ...rest } = props;

  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  const ariaProps = formAriaProps(
    props,
    { ariaLabel: ariaLabel || guessTextFromLabel(rest?.label) },
    {
      passOnExistingAria: true
    }
  );
  ariaProps.role = 'switch';
  return (
    <Switch
      {...omitAriaProps(rest)}
      {...ariaProps}
      className={classNames(className, { [Classes.SMALL]: small })}
      onChange={handleChange}
    />
  );
});

Component.displayName = 'Switch';

export default Component;
