import styled from 'styled-components';
import { flex, height, themeGet, width } from 'styled-system';
import { lighten } from 'polished';

function getBracketColors(dataview) {
  const { queryBuckets } = dataview;
  const { activeBucketCount, activeBuckets } = queryBuckets;
  if (activeBucketCount) {
    const query = activeBuckets[0].firstQuery;
    const bracketOptions = query.get('bracketOptions');
    if (bracketOptions) {
      const { ranges } = bracketOptions;
      return [...ranges, { data: { value: bracketOptions.over } }].map(
        (range, idx) =>
          `.highcharts-point.highcharts-color-${idx},
        .highcharts-legend-item.highcharts-color-${idx} .highcharts-point,
        .highcharts-tooltip .highcharts-color-${idx}, .plot-line-${idx} {
          fill: ${range.data.value};
        }

        .highcharts-tooltip.highcharts-color-${idx},
        .highcharts-data-label-connector.highcharts-color-${idx},
        .plot-line-${idx} {
          stroke: ${range.data.value};
        }`
      );
    }
  }
  return '';
}

const HighchartsWrapper = styled.div`
  overflow: hidden;
  position: relative;
  ${height};
  ${width};
  ${flex};

  @keyframes dash {
    to {
      stroke-dashoffset: 1000;
    }
  }

  .dataview-time-selection-rect {
    stroke-dasharray: 10;
    animation: dash 10s linear infinite;
    animation-direction: reverse;
  }

  .highcharts-title {
    font-weight: bold;
  }

  .highcharts-container {
    position: absolute;
    overflow: hidden;
    ${height};
    ${width};
    text-align: left;
    line-height: normal;
    z-index: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 12px;
  }

  .highcharts-root {
    display: block;
  }

  .highcharts-root text {
    stroke-width: 0;
  }

  .highcharts-strong {
    font-weight: bold;
  }

  .highcharts-emphasized {
    font-style: italic;
  }

  .highcharts-anchor {
    cursor: pointer;
  }

  .highcharts-background {
    fill: transparent;
  }

  .highcharts-plot-border,
  .highcharts-plot-background {
    fill: none;
  }

  .highcharts-label-box {
    fill: none;
  }

  .highcharts-button-box {
    fill: inherit;
  }

  .highcharts-tracker-line {
    stroke-linejoin: round;
    stroke: rgba(192, 192, 192, 0.0001);
    stroke-width: 22;
    fill: none;
  }

  .highcharts-tracker-area {
    fill: rgba(192, 192, 192, 0.0001);
    stroke-width: 0;
  }

  /* Titles */
  .highcharts-title {
    fill: ${(props) => props.theme.colors.body};
    font-size: 16px;
  }

  .highcharts-subtitle {
    fill: ${(props) => props.theme.colors.muted};
  }

  /* Axes */
  .highcharts-axis-line {
    fill: none;
    stroke: ${(props) => props.theme.colors.chart.axisLine};
  }

  .highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0;
  }

  .highcharts-axis-title {
    fill: ${(props) => props.theme.colors.muted};
  }

  .highcharts-axis-labels {
    cursor: default;
    font-size: 12px;

    ${({ theme }) => `
      color: ${theme.colors.muted};
      fill: ${theme.colors.muted};
    `}
  }

  .highcharts-grid-line {
    fill: none;
    stroke: ${(props) => props.theme.colors.chart.gridLine};
  }

  .highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 1px;
  }

  .highcharts-tick {
    stroke: ${(props) => props.theme.colors.chart.axisTick};
  }

  .highcharts-yaxis .highcharts-tick {
    stroke-width: 0;
  }

  .highcharts-minor-grid-line {
    stroke: #f2f2f2;
  }

  .highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: ${(props) => props.theme.colors.muted};
  }

  .highcharts-crosshair-category {
    stroke: ${(props) => props.theme.colors.primary};
    stroke-opacity: 0.25;
  }

  /* // get rid of weird drop-shadow filter */
  .highcharts-tooltip-0 {
    filter: none;
  }

  /* Tooltip */
  .highcharts-tooltip {
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
    transition: stroke 150ms;
  }

  .highcharts-tooltip text {
    fill: ${(props) => props.theme.colors.body};
  }

  .highcharts-tooltip .highcharts-header {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
  }

  .highcharts-tooltip-box {
    stroke-width: 1px;
    fill: ${({ theme: { colors } }) => colors.chart.tooltipBackground};
    fill-opacity: 1;
    stroke: ${(props) => props.theme.colors.chart.gridLine};
  }

  .highcharts-tooltip-box .highcharts-label-box {
    fill: ${({ theme: { colors } }) => colors.chart.tooltipBackground};
    fill-opacity: 1;
  }

  .highcharts-selection-marker {
    fill: ${({ theme: { colors } }) => colors.primary};
    fill-opacity: 0.25;
  }

  .highcharts-graph {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .highcharts-legend-item.highcharts-scatter-series .highcharts-graph {
    stroke-width: 0;
  }
  .highcharts-legend-item.highcharts-scatter-series .highcharts-point {
    stroke-width: 6;
  }

  .highcharts-state-hover .highcharts-graph {
    stroke-width: 3;
  }

  .highcharts-series-dot .highcharts-graph {
    stroke-dasharray: 2, 6;
  }

  .highcharts-series-dot.highcharts-graph {
    stroke-dasharray: 0, 4;
  }

  .highcharts-series-dash .highcharts-graph {
    stroke-dasharray: 8, 6;
  }

  .highcharts-series-dashdot .highcharts-graph {
    stroke-dasharray: 8, 6, 2, 6;
  }

  .highcharts-series-1.change-detection-series {
    fill: purple;
    stroke: purple;
  }

  .change-detection-series .highcharts-point {
    fill: purple;
    stroke: purple;
    stroke-width: 1;
  }

  .highcharts-state-hover path {
    transition: stroke-width 50;
    /* quick in */
  }

  .highcharts-state-normal path {
    transition: stroke-width 250ms;
    /* slow out */
  }

  /* Legend hover affects points and series */
  g.highcharts-series,
  .highcharts-point,
  .highcharts-markers,
  .highcharts-data-labels {
    transition: opacity 250ms;
  }

  .highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
  .highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
  .highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
  .highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
    opacity: 0.2;
  }

  .highcharts-area {
    fill-opacity: 0.35;
    stroke-width: 0;
  }

  .highcharts-markers {
    stroke-width: 1px;
    stroke: #ffffff;
  }

  .highcharts-point {
    stroke-width: 1px;
  }

  .highcharts-dense-data .highcharts-point {
    stroke-width: 0;
  }

  .highcharts-data-label {
    font-size: 0.9em;
    font-weight: bold;
  }

  .highcharts-data-label-box {
    fill: ${themeGet('colors.appBackground')};
    fill-opacity: 0.7;
    stroke-width: 0;
    rx: 2px;
    ry: 2px;
  }

  .highcharts-data-label text,
  text.highcharts-data-label {
    fill: ${(props) => props.theme.colors.body};
  }

  .highcharts-data-label-connector {
    fill: ${(props) => props.theme.colors.muted};
  }

  .highcharts-halo {
    fill-opacity: 0.25;
    stroke-width: 0;
  }

  .highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
  .highcharts-markers .highcharts-point-select {
    stroke: rgb(112 241 255);
    stroke-width: 10px;
    z-index: 999;
    stroke-linejoin: round;
    offset-distance: 14;
    paint-order: stroke;
  }

  .highcharts-column-series rect.highcharts-point {
    stroke: none;
  }

  .highcharts-column-series.alerts-by-severity-column-chart rect.highcharts-point {
    stroke: ${(props) => props.theme.colors.appBackground} !important;
    stroke-width: 2px;
  }

  .highcharts-bar-series .highcharts-point {
    transition: fill-opacity 250ms;
  }

  .highcharts-bar-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
  }

  .highcharts-column-series .highcharts-point {
    transition: fill-opacity 250ms;
  }

  .highcharts-column-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
  }

  .highcharts-pie-series .highcharts-point {
    stroke-linejoin: round;
    stroke: none;
  }

  .highcharts-pie-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
  }

  .highcharts-funnel-series .highcharts-point {
    stroke-linejoin: round;
    stroke: none;
  }

  .highcharts-funnel-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
  }

  .highcharts-funnel-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
  }

  .highcharts-solidgauge-series .highcharts-point {
    stroke-width: 0;
  }

  /* Legend */
  .highcharts-legend-box {
    fill: none;
    stroke-width: 0;
  }

  .highcharts-legend-item > text {
    fill: ${(props) => props.theme.colors.body};
    font-size: 12px;
    cursor: pointer;
    stroke-width: 0;
  }

  .highcharts-legend-item:hover text {
    fill: ${(props) => lighten(0.2, props.theme.colors.muted)};
  }

  .highcharts-legend-item-hidden * {
    fill: #cccccc !important;
    stroke: #cccccc !important;
    transition: fill 250ms;
  }

  text.highcharts-legend-navigation {
    transform: translateX(80%);
  }

  circle.highcharts-legend-nav-active {
    fill: rgba(161, 187, 199, 0.4);
    cursor: pointer;
    cy: 12px;
  }

  circle.highcharts-legend-nav-inactive {
    fill: rgba(161, 187, 199, 0.4);
    cursor: not-allowed;
    cy: 12px;
  }

  path.highcharts-legend-nav-active {
    transform: translateY(12px);
    fill: ${(props) => (props.theme.name === 'dark' ? '#fff' : '#000')};
  }

  path.highcharts-legend-nav-inactive {
    transform: translateY(12px);
    fill: ${(props) => (props.theme.name === 'dark' ? '#fff' : '#000')};
    opacity: 0.2;
  }

  .highcharts-legend-title-box {
    fill: none;
    stroke-width: 0;
  }

  .highcharts-legend-title {
    fill: ${themeGet('colors.body')};
  }

  /* Bubble legend */
  .highcharts-bubble-legend-symbol {
    stroke-width: 2;
    fill-opacity: 0.5;
  }

  .highcharts-bubble-legend-connectors {
    stroke-width: 1;
  }

  .highcharts-bubble-legend-labels {
    fill: ${(props) => props.theme.colors.body};
  }

  .highcharts-stack-labels {
    fill: ${(props) => props.theme.colors.body};
    font-weight: ${(props) => props.theme.fontWeights.medium};
  }

  /* Loading */
  .highcharts-loading {
    position: absolute;
    background-color: #ffffff;
    opacity: 0.5;
    text-align: center;
    z-index: 10;
    transition: opacity 250ms;
  }

  .highcharts-loading-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition:
      opacity 250ms,
      height 250ms step-end;
  }

  .highcharts-series-inactive,
  .highcharts-point-inactive {
    opacity: 0.2 !important;
  }

  .highcharts-loading-inner {
    font-weight: bold;
    position: relative;
    top: 45%;
  }

  .highcharts-line-series {
    &.faded {
      opacity: 0.3;
    }

    &.critical-level {
      stroke: ${themeGet('colors.severity.critical')};
    }

    &.major2-level {
      stroke: ${themeGet('colors.severity.major2')};
    }

    &.major-level {
      stroke: ${themeGet('colors.severity.major')};
    }

    &.minor2-level {
      stroke: ${themeGet('colors.severity.minor2')};
    }

    &.minor-level {
      stroke: ${themeGet('colors.severity.minor')};
    }

    &.alarm-event {
      stroke: ${themeGet('colors.danger')};
    }

    &.cleared-event {
      stroke: ${themeGet('colors.success')};
    }

    &.baseline-level {
      stroke: ${themeGet('colors.severity.baseline')};
    }
  }

  /* Plot bands and polar pane backgrounds */
  .highcharts-plot-band,
  .highcharts-pane {
    fill: ${(props) => props.theme.colors.muted};
    fill-opacity: 0.05;

    &.commit-band,
    &.critical-level,
    &.major2-level,
    &.major-level,
    &.minor2-level,
    &.minor-level,
    &.from-level,
    &.selected-window,
    &.comparison-window,
    &.change-detection-band {
      fill-opacity: 0.5;
      stroke-dasharray: 7;
    }

    // FPA Window, and Comparison Window
    &.selected-window {
      fill: ${themeGet('colors.primary')};
      stroke: ${themeGet('colors.primary')};
      fill-opacity: 0.15;
    }

    &.comparison-window {
      fill: ${themeGet('colors.severity.critical')};
      stroke: ${themeGet('colors.severity.critical')};
      fill-opacity: 0.15;
    }

    &.change-detection-band {
      fill: ${themeGet('colors.severity.critical')};
      stroke-width: 0;
      fill-opacity: 0.1;
    }

    &.commit-band {
      fill: ${themeGet('colors.success')};
      stroke: ${themeGet('colors.success')};
    }

    &.critical-level {
      fill: ${themeGet('colors.severity.critical')};
      stroke: ${themeGet('colors.severity.critical')};
      stroke-dasharray: 5;
    }

    &.major2-level {
      fill: ${themeGet('colors.severity.major2')};
      stroke: ${themeGet('colors.severity.major2')};
      stroke-dasharray: 5;
    }

    &.major-level {
      fill: ${themeGet('colors.severity.major')};
      stroke: ${themeGet('colors.severity.major')};
      stroke-dasharray: 5;
    }

    &.minor2-level {
      fill: ${themeGet('colors.severity.minor2')};
      stroke: ${themeGet('colors.severity.minor2')};
      stroke-dasharray: 5;
    }

    &.minor-level {
      fill: ${themeGet('colors.severity.minor')};
      stroke: ${themeGet('colors.severity.minor')};
      stroke-dasharray: 5;
    }

    &.alarm-event {
      fill: ${themeGet('colors.danger')};
    }

    &.cleared-event {
      fill: ${themeGet('colors.success')};
    }
  }

  .highcharts-plot-band-label {
    font-weight: ${themeGet('fontWeights.normal')};
    fill: ${themeGet('colors.body')};

    &.change-detection-label {
      font-weight: ${themeGet('fontWeights.bold')};
      font-size: ${themeGet('fontSizes.normal')};
    }

    &.alarm-event {
      fill: ${themeGet('colors.danger')};
    }

    &.cleared-event {
      fill: ${themeGet('colors.success')};
    }
  }

  .highcharts-plot-line {
    fill: none;
    stroke: ${themeGet('colors.body')};
    stroke-width: 2px;

    &.faded {
      opacity: 0.3;
    }

    &.commit-line {
      stroke: ${themeGet('colors.success')};
    }

    &.thin-line {
      stroke-width: 1px;
    }

    &.trigger-line {
      stroke-width: 1px;
      stroke: ${themeGet('colors.danger')};
    }

    &.commit-level,
    &.tier1-line {
      stroke: ${themeGet('colors.primary')};
    }

    &.tier2-line {
      stroke: ${themeGet('colors.purple')};
    }

    &.capacity-level {
      stroke: ${themeGet('colors.danger')};
    }

    &.target-level,
    &.tier3-line {
      stroke: ${themeGet('colors.warning')};
      stroke-dasharray: 3;
    }

    &.critical-level {
      stroke: ${themeGet('colors.severity.critical')};
      stroke-dasharray: 3;
    }

    &.major2-level {
      stroke: ${themeGet('colors.severity.major2')};
      stroke-dasharray: 3;
    }

    &.major-level {
      stroke: ${themeGet('colors.severity.major')};
      stroke-dasharray: 3;
    }

    &.minor2-level {
      stroke: ${themeGet('colors.severity.minor2')};
      stroke-dasharray: 3;
    }

    &.minor-level {
      stroke: ${themeGet('colors.severity.minor')};
      stroke-dasharray: 3;
    }

    &.alarm-event {
      stroke: ${themeGet('colors.danger')};
    }

    &.cleared-event {
      stroke: ${themeGet('colors.success')};
    }

    &.baseline-level {
      stroke: ${themeGet('colors.severity.baseline')};
    }

    &.baseline-insight-level {
      stroke-dasharray: 3;
    }

    &.costgroup-line {
      stroke-width: 1px;
      stroke-dasharray: 3 2;
    }
  }

  .highcharts-plot-line-label {
    background-color: #fff;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    fill: ${themeGet('colors.body')};

    &.outlinedText {
      text-shadow: 1px solid blue;
      filter: ${({ theme }) =>
        // Helper class to contrast labels with cha rt
        `drop-shadow(1px 1px 1px ${theme.colors.appBackground})
                drop-shadow(-1px -1px 1px ${theme.colors.appBackground})
                drop-shadow(1px -1px 1px ${theme.colors.appBackground})
                drop-shadow(-1px 1px 1px ${theme.colors.appBackground}) `};
    }

    &.faded,
    .faded {
      opacity: 0.3;
    }

    &.capacity-level {
      fill: ${(props) => props.theme.colors.danger};
    }

    &.critical-level {
      fill: ${themeGet('colors.severity.critical')};
    }

    &.major2-level {
      fill: ${themeGet('colors.severity.major2')};
    }

    &.major-level {
      fill: ${themeGet('colors.severity.major')};
    }

    &.minor2-level {
      fill: ${themeGet('colors.severity.minor2')};
    }

    &.minor-level {
      fill: ${themeGet('colors.severity.minor')};
    }

    &.alarm-event {
      fill: ${themeGet('colors.danger')};
    }

    &.cleared-event {
      fill: ${themeGet('colors.success')};
    }

    &.target-level {
      fill: ${(props) => props.theme.colors.warning};
    }

    &.baseline-insight-level {
      fill: ${themeGet('colors.severity.baseline')};
    }
  }

  ${(props) => {
    let templateLiteral = '';

    props.colors.forEach((color, index) => {
      templateLiteral += `
          .highcharts-color-${index} {
            fill: ${color};
            stroke: ${color};
          }
        `;
    });

    return templateLiteral;
  }}

  .highcharts-color-50 {
    fill: ${(props) => props.primaryOverlayColor};
    stroke: ${(props) => props.primaryOverlayColor};
  }

  .highcharts-color-500 {
    fill: ${(props) => props.overlayColor};
    stroke: ${(props) => props.overlayColor};
    stroke-dasharray: 4;
  }

  .highcharts-color-100 {
    fill: url('#hatch-left');
    stroke: ${(props) => props.theme.colors.danger};
  }

  .bracketing {
    ${(props) =>
      props.theme.colors.bracketing.map(
        (color, index) => `
          &.highcharts-color-${index} {
            fill: ${color};
            stroke: ${color};
          }
          `
      )}

    &.highcharts-color--1 {
      fill: #5c7080;
      stroke: #5c7080;
    }
  }

  #hatch-left path {
    stroke: ${(props) => props.theme.colors.danger};
    stroke-width: 1px;
  }

  /* Highcharts More and modules */
  .highcharts-boxplot-box {
    fill: #ffffff;
  }

  .highcharts-boxplot-median {
    stroke-width: 2px;
  }

  .highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.5;
  }

  .highcharts-errorbar-series .highcharts-point {
    stroke: ${(props) => props.theme.colors.muted};
  }

  .highcharts-gauge-series .highcharts-data-label-box {
    stroke: ${(props) => props.theme.colors.muted};
    stroke-width: 1px;
  }

  .highcharts-gauge-series .highcharts-dial {
    fill: ${(props) => props.theme.colors.muted};
    stroke-width: 0;
  }

  .highcharts-polygon-series .highcharts-graph {
    fill: inherit;
    stroke-width: 0;
  }

  .highcharts-waterfall-series .highcharts-graph {
    stroke: ${(props) => props.theme.colors.body};
    stroke-dasharray: 1, 3;
  }

  .highcharts-sankey-series .highcharts-point {
    stroke-width: 0;
  }

  .highcharts-sankey-series .highcharts-link {
    transition:
      fill 250ms,
      fill-opacity 250ms;
    fill-opacity: 0.5;
  }

  .highcharts-sankey-series .highcharts-point-hover.highcharts-link {
    transition:
      fill 50ms,
      fill-opacity 50ms;
    fill-opacity: 1;
  }

  .highcharts-venn-series .highcharts-point {
    fill-opacity: 0.75;
    stroke: ${(props) => props.theme.colors.muted};
    transition:
      stroke 250ms,
      fill-opacity 250ms;
  }

  .highcharts-venn-series .highcharts-point-hover {
    fill-opacity: 1;
    stroke: ${(props) => props.theme.colors.muted};
  }

  /* Highstock */
  .highcharts-navigator-mask-outside {
    fill-opacity: 0;
  }

  .highcharts-navigator-mask-inside {
    fill: #6685c2;
    /* navigator.maskFill option */
    fill-opacity: 0.25;
    cursor: ew-resize;
  }

  .highcharts-navigator-outline {
    stroke: ${(props) => props.theme.colors.muted};
    fill: none;
  }

  .highcharts-navigator-handle {
    stroke: ${(props) => props.theme.colors.muted};
    fill: #f2f2f2;
    cursor: ew-resize;
  }

  .highcharts-navigator-series {
    fill: #335cad;
    stroke: #335cad;
  }

  .costgroup-line {
    .highcharts-graph {
      stroke-dasharray: 2 4;
    }
  }

  .highcharts-navigator-series .highcharts-graph {
    stroke-width: 1px;
  }

  .highcharts-navigator-series .highcharts-area {
    fill-opacity: 0.05;
  }

  .highcharts-navigator-xaxis .highcharts-axis-line {
    stroke-width: 0;
  }

  .highcharts-navigator-xaxis .highcharts-grid-line {
    stroke-width: 1px;
    stroke: ${(props) => props.theme.colors.muted};
  }

  .highcharts-navigator-xaxis.highcharts-axis-labels {
    fill: #999999;
  }

  .highcharts-navigator-yaxis .highcharts-grid-line {
    stroke-width: 0;
  }

  .highcharts-scrollbar-thumb {
    fill: ${(props) => props.theme.colors.muted};
    stroke: ${(props) => props.theme.colors.muted};
    stroke-width: 1px;
  }

  .highcharts-scrollbar-button {
    fill: ${(props) => props.theme.colors.muted};
    stroke: ${(props) => props.theme.colors.muted};
    stroke-width: 1px;
  }

  .highcharts-scrollbar-arrow {
    fill: ${(props) => props.theme.colors.muted};
  }

  .highcharts-scrollbar-rifles {
    stroke: ${(props) => props.theme.colors.muted};
    stroke-width: 1px;
  }

  .highcharts-scrollbar-track {
    fill: #f2f2f2;
    stroke: #f2f2f2;
    stroke-width: 1px;
  }

  .highcharts-button {
    fill: ${(props) => props.theme.colors.primaryBackground};
    stroke: ${(props) => props.theme.colors.muted};
    cursor: pointer;
    stroke-width: 1px;
    transition: fill 250ms;
  }

  .highcharts-button text {
    fill: ${(props) => props.theme.colors.body};
  }

  .highcharts-button-hover {
    transition: fill 0ms;
    fill: ${(props) => props.theme.colors.muted};
    stroke: ${(props) => props.theme.colors.muted};
  }

  .highcharts-button-hover text {
    fill: ${(props) => props.theme.colors.body};
  }

  .highcharts-button-pressed {
    font-weight: bold;
    fill: #e6ebf5;
    stroke: ${(props) => props.theme.colors.muted};
  }

  .highcharts-button-pressed text {
    fill: ${(props) => props.theme.colors.body};
    font-weight: bold;
  }

  .highcharts-button-disabled text {
    fill: ${(props) => props.theme.colors.body};
  }

  .highcharts-range-selector-buttons .highcharts-button {
    stroke-width: 0px;
  }

  .highcharts-range-label rect {
    fill: none;
  }

  .highcharts-range-label text {
    fill: ${(props) => props.theme.colors.muted};
  }

  .highcharts-range-input rect {
    fill: none;
  }

  .highcharts-range-input text {
    fill: ${(props) => props.theme.colors.body};
  }

  .highcharts-range-input {
    stroke-width: 1px;
    stroke: ${(props) => props.theme.colors.muted};
  }

  input.highcharts-range-selector {
    position: absolute;
    border: 0;
    width: 1px;
    /* Chrome needs a pixel to see it */
    height: 1px;
    padding: 0;
    text-align: center;
    left: -9em;
    /* #4798 */
  }

  .highcharts-crosshair-label text {
    fill: #ffffff;
    font-size: 12px;
  }

  .highcharts-crosshair-label .highcharts-label-box {
    fill: inherit;
  }

  .highcharts-candlestick-series .highcharts-point {
    stroke: ${(props) => props.theme.colors.muted};
    stroke-width: 1px;
  }

  .highcharts-candlestick-series .highcharts-point-up {
    fill: #ffffff;
  }

  .highcharts-ohlc-series .highcharts-point-hover {
    stroke-width: 3px;
  }

  .highcharts-flags-series .highcharts-point .highcharts-label-box {
    stroke: #999999;
    fill: #ffffff;
    transition: fill 250ms;
  }

  .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
    stroke: ${(props) => props.theme.colors.muted};
    fill: #ccd6eb;
  }

  .highcharts-flags-series .highcharts-point text {
    fill: ${(props) => props.theme.colors.muted};
    font-size: 12px;
    font-weight: bold;
  }

  /* Highmaps */
  .highcharts-map-series .highcharts-point {
    transition:
      fill 500ms,
      fill-opacity 500ms,
      stroke-width 250ms;
    stroke: ${(props) => props.theme.colors.muted};
  }

  .highcharts-map-series .highcharts-point-hover {
    transition:
      fill 0ms,
      fill-opacity 0ms;
    fill-opacity: 0.5;
    stroke-width: 2px;
  }

  .highcharts-mapline-series .highcharts-point {
    fill: none;
  }

  .highcharts-heatmap-series .highcharts-point {
    stroke-width: 0;
  }

  .highcharts-map-navigation {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
  }

  .highcharts-coloraxis {
    stroke-width: 0;
  }

  .highcharts-coloraxis-marker {
    fill: #999999;
  }

  .highcharts-null-point {
    fill: #f7f7f7;
  }

  /* 3d charts */
  .highcharts-3d-frame {
    fill: transparent;
  }

  /* Exporting module */
  .highcharts-contextbutton {
    fill: #ffffff;
    /* needed to capture hover */
    stroke: none;
    stroke-linecap: round;
  }

  .highcharts-contextbutton:hover {
    fill: ${(props) => props.theme.colors.muted};
    stroke: ${(props) => props.theme.colors.muted};
  }

  .highcharts-button-symbol {
    stroke: ${(props) => props.theme.colors.muted};
    stroke-width: 3px;
  }

  .highcharts-menu {
    border: 1px solid #999999;
    background: #ffffff;
    padding: 5px 0;
    box-shadow: 3px 3px 10px #888;
  }

  .highcharts-menu-item {
    padding: 0.5em 1em;
    background: none;
    color: ${(props) => props.theme.colors.body};
    cursor: pointer;
    transition:
      background 250ms,
      color 250ms;
  }

  .highcharts-menu-item:hover {
    background: #335cad;
    color: #ffffff;
  }

  /* Drilldown module */
  .highcharts-drilldown-point {
    cursor: pointer;
  }

  .highcharts-drilldown-data-label text,
  text.highcharts-drilldown-data-label,
  .highcharts-drilldown-axis-label {
    cursor: pointer;
    fill: #003399;
    font-weight: bold;
    text-decoration: underline;
  }

  /* No-data module */
  .highcharts-no-data text {
    font-weight: bold;
    font-size: 12px;
    fill: ${(props) => props.theme.colors.muted};
  }

  /* Drag-panes module */
  .highcharts-axis-resizer {
    cursor: ns-resize;
    stroke: black;
    stroke-width: 2px;
  }

  /* Bullet type series */
  .highcharts-bullet-target {
    stroke-width: 0;
  }

  /* Lineargauge type series */
  .highcharts-lineargauge-target {
    stroke-width: 1px;
    stroke: ${(props) => props.theme.colors.body};
  }

  .highcharts-lineargauge-target-line {
    stroke-width: 1px;
    stroke: ${(props) => props.theme.colors.body};
  }

  /* Annotations module */
  .highcharts-annotation-label-box {
    stroke-width: 1px;
    stroke: ${(props) => props.theme.colors.muted};
    fill: ${(props) => props.theme.colors.muted};
    fill-opacity: 0.75;
  }

  .highcharts-annotation-label text {
    fill: ${(props) => props.theme.colors.muted};
  }

  /* Gantt */
  .highcharts-treegrid-node-collapsed,
  .highcharts-treegrid-node-expanded {
    cursor: pointer;
  }

  .highcharts-point-connecting-path {
    fill: none;
  }

  .highcharts-grid-axis .highcharts-tick {
    stroke-width: 1px;
  }

  .highcharts-grid-axis .highcharts-axis-line {
    stroke-width: 1px;
  }

  /* Line Zones */

  .highcharts-graph.zone-warning,
  .highcharts-graph.zone-warning.zone-green {
    stroke: ${themeGet('colors.warning')};
  }
  .highcharts-area.zone-warning,
  .highcharts-area.zone-warning.zone-green {
    fill: ${themeGet('colors.warning')};
    fill-opacity: 0.25;
  }
  .highcharts-point.zone-warning,
  .highcharts-point.zone-warning.zone-green {
    fill: ${themeGet('colors.warning')};
    fill-opacity: 0.25;
  }

  .highcharts-graph.zone-danger {
    stroke: ${themeGet('colors.danger')};
  }
  .highcharts-area.zone-danger {
    fill: ${themeGet('colors.danger')};
    fill-opacity: 0.25;
  }
  .highcharts-point.zone-danger {
    fill: ${themeGet('colors.danger')};
    fill-opacity: 0.25;
  }

  .highcharts-graph.change-detection {
    stroke: ${themeGet('colors.severity.critical')};
  }
  .highcharts-area.change-detection {
    fill: ${themeGet('colors.severity.critical')};
    fill-opacity: 0.25;
  }

  .highcharts-area {
    &.selected-window {
      fill: ${themeGet('colors.primary')};
      fill-opacity: 0.2;
    }
    &.comparison-window {
      fill: ${themeGet('colors.severity.critical')};
      fill-opacity: 0.2;
    }
  }

  .highcharts-graph {
    &.selected-window {
      stroke: ${themeGet('colors.primary')};
    }
    &.comparison-window {
      stroke: ${themeGet('colors.severity.critical')};
    }
  }

  .highcharts-area.selected {
    fill: ${themeGet('colors.severity.critical')};
    fill-opacity: 0.25;
  }

  .highcharts-point.change-detection {
    fill: ${themeGet('colors.severity.critical')};
    fill-opacity: 0.25;
  }

  /* Area Zones */
  .highcharts-graph.zone-grey {
    stroke: #ccc;
  }

  .highcharts-area.zone-grey {
    fill: #ccc;
    fill-opacity: 1;
  }

  .highcharts-point.zone-grey {
    fill: #ccc;
    fill-opacity: 1;
  }

  .highcharts-graph.zone-faded,
  .highcharts-area.zone-faded,
  .highcharts-point.zone-grey {
    filter: saturate(0.55) opacity(0.55);
  }

  .highcharts-graph.zone-red {
    stroke: ${themeGet('colors.danger')};
    opacity: 0;
    fill: '#000';
    fill-opacity: 1;
  }

  .highcharts-area.zone-red {
    fill: ${themeGet('colors.danger')};
    fill-opacity: 0.75;
  }

  .highcharts-point.zone-red {
    fill: ${themeGet('colors.danger')};
    fill-opacity: 0.75;
  }

  .highcharts-graph.zone-green,
  .highcharts-graph.zone-green-clear {
    stroke: ${themeGet('colors.success')};
  }

  .highcharts-area.zone-green.zone-green-clear,
  .highcharts-area.zone-green-clear {
    fill-opacity: 0;
  }

  .highcharts-area.zone-green {
    fill: ${themeGet('colors.success')};
    fill-opacity: 1;
  }

  .highcharts-point.zone-green {
    fill: ${themeGet('colors.success')};
    fill-opacity: 1;
  }

  .highcharts-series:not(.highcharts-series-dot) {
    .highcharts-graph.zone-critical {
      stroke: ${themeGet('colors.severity.critical')};
    }

    &.forecast-arearange-series {
      stroke-opacity: 0;
    }

    .highcharts-area.zone-critical {
      fill: ${themeGet('colors.severity.critical')};
    }

    .highcharts-point.zone-critical {
      fill: ${themeGet('colors.severity.critical')};
    }

    .highcharts-graph.zone-major2 {
      stroke: ${themeGet('colors.severity.major2')};
    }

    .highcharts-area.zone-major2 {
      fill: ${themeGet('colors.severity.major2')};
    }

    .highcharts-point.zone-major2 {
      fill: ${themeGet('colors.severity.major2')};
    }

    .highcharts-graph.zone-major {
      stroke: ${themeGet('colors.severity.major')};
    }

    .highcharts-area.zone-major {
      fill: ${themeGet('colors.severity.major')};
    }

    .highcharts-point.zone-major {
      fill: ${themeGet('colors.severity.major')};
    }

    .highcharts-graph.zone-minor2 {
      stroke: ${themeGet('colors.severity.minor2')};
    }

    .highcharts-area.zone-minor2 {
      fill: ${themeGet('colors.severity.minor2')};
    }

    .highcharts-point.zone-minor2 {
      fill: ${themeGet('colors.severity.minor2')};
    }

    .highcharts-graph.zone-minor {
      stroke: ${themeGet('colors.severity.minor')};
    }

    .highcharts-area.zone-minor {
      fill: ${themeGet('colors.severity.minor')};
    }

    .highcharts-point.zone-minor {
      fill: ${themeGet('colors.severity.minor')};
    }
  }

  /* Bar */
  .highcharts-container.bar {
    .highcharts-xaxis-labels span {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis !important;
    }
  }

  /* Matrix */
  .highcharts-container.matrix {
    .highcharts-axis-title {
      font-weight: ${({ theme }) => theme.fontWeights.black};
    }

    .highcharts-xaxis-labels,
    .highcharts-yaxis-labels {
      cursor: pointer;
      fill: ${({ theme }) => theme.colors.muted};
    }

    .highcharts-data-label text {
      fill: ${({ theme }) => theme.colors.black};
    }
  }

  /* Pie */
  .highcharts-container.pie {
    .highcharts-point {
      cursor: pointer;
    }

    .highcharts-data-label-connector {
      fill: none !important;
    }
  }

  /* Sunburst */
  .highcharts-container.sunburst {
    .highcharts-axis-line {
      stroke-width: 0px;
    }

    /* General data label style */
    text.highcharts-data-label {
      fill: black;
      text-shadow:
        1px 1px white,
        1px -1px white,
        -1px -1px white,
        -1px 1px white;
      text-overflow: ellipsis;
    }

    /* For the darkest columns, invert the data label text color and shadow */
    text.highcharts-data-label-color-0,
    text.highcharts-data-label-color-1,
    text.highcharts-data-label-color-4,
    text.highcharts-data-label-color-5,
    text.highcharts-data-label-color-7 {
      fill: white;
      text-shadow:
        1px 1px black,
        1px -1px black,
        -1px -1px black,
        -1px 1px black;
    }

    .highcharts-sunburst-series .highcharts-point {
      stroke: white;
      stroke-width: 1px;
    }
  }

  ${(props) => props.dataview && getBracketColors(props.dataview)};

  .draggable-y {
    cursor: ns-resize;
  }

  .highcharts-axis.no-title {
    .highcharts-axis-title {
      display: none;
    }
  }

  &.no-markers {
    .highcharts-markers,
    .highcharts-crosshair {
      display: none;
    }
  }
`;

export default HighchartsWrapper;
